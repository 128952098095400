<div [formGroup]="form">
  <div class="field" (click)="this.handleStepper.emit()">
    <div class="title">{{ title }}</div>
    <mat-form-field appearance="outline" class="form-field">
      <mat-select
        (selectionChange)="onSelectionChange($event)"
        formControlName="{{ formName }}"
      >
        <mat-radio-group
          class="radio"
          aria-label="Select an option"
          formControlName="{{ formName }}"
        >
          @for (option of options; track $index) {
          <mat-option [value]="option.value">
            <mat-radio-button
              color="primary"
              [value]="option.value"
            ></mat-radio-button>
            {{ option.title }}</mat-option
          >
          }
        </mat-radio-group>
      </mat-select>
      <mat-error>{{ checkForErrorsIn(form.get(formName), title) }}</mat-error>
    </mat-form-field>
  </div>
</div>
