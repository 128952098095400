export function upperFirst(str) {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
}
export function removeUnderscores(word) {
  if (word.includes('_')) {
    return word.split('_').join(' ');
  } else {
    return word;
  }
}
export function capitalizeFirstLetterOfEachWord(inputString) {
  var words = inputString.split('_');

  // Capitalize the first letter of each word
  for (var i = 0; i < words.length; i++) {
    words[i] =
      words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
  }

  // Join the words back into a string without underscores
  var resultString = words.join(' ');

  return resultString;
}
export function formattedJson(jsonObj) {
  const formattedJson = JSON.stringify(JSON.parse(jsonObj.value), null, 2);

  return formattedJson;
}
export function removeSpaceAndCapitalizeWords(text: string) {
  let result = text.split(' ').join('').toLowerCase();
  return result;
}
export function convertToMinutes(milliseconds) {
  const minutes = milliseconds / (1000 * 60);
  return minutes;
}
export function generateName(value) {
  let result = value.toLowerCase().replace(/\s/g, ''); // Convert to lowercase and remove spaces
  return result;
}
