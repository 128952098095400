import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from '../../../services/products.service';
import { SidebarService } from '../../../services/sidebar.service';
import { TablesService } from '../../../services/tables.service';
import { CreateTable } from '../../../interfaces/table';

@Component({
  selector: 'app-tabs-details',
  templateUrl: './tabs-details.component.html',
  styleUrl: './tabs-details.component.scss',
})
export class TabsDetailsComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private sidebarService: SidebarService,
    public productsService: ProductsService,
    public tablesService: TablesService,
    private router: Router
  ) {}
  displayTables: boolean = false;
  ngOnInit(): void {
    this.sidebarService.updateDisabled(false);
    const urlArr = this.router.url.split('/');
    const url = urlArr[urlArr.length - 1];
    this.sidebarService.expandedMenu(url);
    const productName = this.route.snapshot.params['productName'];
    if (productName !== 'settings') {
      this.productsService.fetchGetProducts().subscribe((data: any) => {
        const productRes = data.find((product) => product.name === productName);
        this.productsService.setProduct(productRes);
        this.tablesService.fetchTablesByProduct(productRes.id).add(() => {
          const res = this.tablesService.getTablesHistory();
          this.tablesService.setHiddenTables(
            res.rows.filter((tables) => !tables.isVisible) || []
          );
          this.sidebarService.addTablesToSideMenu(
            res.rows.filter(
              (table: CreateTable) => table.numOfColumn > 0 && !table.isVisible
            ) || [],
            'Hidden Tables'
          );
          this.sidebarService.drawTextMenuByName(
            'Hidden Tables',
            url ? url : res.rows[0].name
          );
          this.displayTables = true;
        });
      });
    }
  }
}
