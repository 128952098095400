import { Option } from '../interfaces/dropdown';
import { Row } from '../interfaces/table';
import { ACTIONS_SELECTION } from '../mockData/dropdown';

export function addEmptyRow(rows: Row[], emptyRow: Row, index: number) {
  rows.splice(index, 0, emptyRow);
}

export function changeRowMode(list: any, id: number, mode: boolean) {
  list.update((prev: any) => {
    const temp = prev.rows.find((item) => item.id === id);
    temp.editMode = mode;
    return {
      ...prev,
    };
  });
}
export function createOption(data: any) {
  const options: Option[] = data.map((row) => {
    return {
      value: row.id,
      title: row.title,
    };
  });
  return options;
}
export function selectorType(type: string | boolean): Option[] {
  switch (type) {
    case 'text':
      return [{ title: 'Equal to', value: 'equalTo' }];
    case 'number':
      return ACTIONS_SELECTION;
    case 'date':
      return [{ title: 'Date', value: 'date' }];
    case 'decimal':
      return ACTIONS_SELECTION;
    case 'bigint':
      return [{ title: 'Date', value: 'date' }];

    default:
      return ACTIONS_SELECTION;
  }
}
