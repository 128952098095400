import { Injectable } from "@angular/core";
import { HttpHandlerService } from "../../../services/http-handler.service";

@Injectable({
  providedIn: "root",
})
export class ReportsService {
  constructor(private httpHandler: HttpHandlerService) {}
  fetchFilter(data, page?, limit?) {
    const url = page
      ? `design/tables/${data.table}/search?page=${page}&limit=${limit}`
      : `design/tables/${data.table}/search`;
    return this.httpHandler.post(url, data, false);
  }
  fetchExportReport(data) {
    return this.httpHandler.post(
      `design/tables/${data.fileName}/export`,
      data,
      false,
      "blob"
    );
  }
}
