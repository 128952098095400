<div class="container">
  <div class="tableContainer">
    @if (form && rows) {
    <app-table
      [rows]="this.usersService.getUsersTable().rows"
      [columns]="columns"
      [header]="header"
      [form]="form"
      [isAddRow]="isAddRow"
      [isDisabledButton]="isDisabledButton"
      [displayColumns]="displayColumns"
      [buttonDetails]="buttonDetails"
      (action)="addEmptyUserRow()"
      (handleActionByName)="handleActionByName($event)"
      [isEditMode]="isEditMode"
      (handleChangePage)="handleChangePage($event)"
      (handleRow)="handleRow($event)"
    ></app-table>
    }
  </div>
</div>
