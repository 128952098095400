<div class="container">
  <div class="header">
    <div class="name">{{ displayTitle(product.name) }}</div>
    <app-datepicker-range
      [fromDate]="fromDate"
      [toDate]="toDate"
      (onClick)="isClicked = true"
      (fromDateEmit)="handleFromDate($event)"
      (toDateEmit)="handleToDate($event)"
    ></app-datepicker-range>
  </div>
  <div class="boxes">
    @for (box of boxes; track $index) {
    <app-dashboard-box class="box" [box]="box"></app-dashboard-box>
    }
  </div>
  <div class="googleContainer">
    <app-analytics
      [fromDate]="fromDate"
      [toDate]="toDate"
      [isClicked]="isClicked"
      (onClick)="isClicked = false"
      [platformFilter]="platformFilter"
      (activeUsersCount)="handleActiveUsers($event)"
      (googleAnalyticsDetails)="handleGoogleAnalyticsDetails($event)"
    ></app-analytics>
  </div>
</div>
