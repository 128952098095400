import { Injectable, signal } from '@angular/core';
import { HttpHandlerService } from '../../../services/http-handler.service';
import { User, UsersTable } from '../../../interfaces/users';
import { columnsUsers } from '../../../mockData/users';
import { FormBuilder, FormGroup } from '@angular/forms';
import { createDynamicForm } from '../../../utils/table';
import { Option } from '../../../interfaces/dropdown';
import { Product } from '../../../interfaces/products';
import { AlertService } from '../../../services/alert.service';
import { ADDING_SUCCESS, DELETED_SUCCESS } from '../../../types/constant';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(
    private httpHandler: HttpHandlerService,
    private formBuilder: FormBuilder,
    private alertService: AlertService
  ) {}
  public usersTables = signal<UsersTable>(null);
  public userProducts = signal<Product>(null);
  private usersForm: FormGroup;

  fetchGetUsers(productId, options: Option[]) {
    return this.httpHandler
      .get(`design/products/${productId}/users`)
      .subscribe((users: User[]) => {
        this.setUsersTable(users, options);
      });
  }
  getUserProduct() {
    return this.userProducts();
  }

  fetchGetUserProduct(userId) {
    return this.httpHandler
      .get(`users/${userId}/products`)
      .subscribe((products: Product[]) => {
        this.userProducts.set(products[0]);
      });
  }

  getUsersTable() {
    return this.usersTables();
  }
  setUsersTable(users: User[], options: Option[]) {
    const columns = columnsUsers.map((col) => {
      if (col.name === 'role_id') {
        return {
          ...col,
          options,
        };
      }
      return { ...col };
    });
    const tempUsersTable: UsersTable = {
      header: 'users',
      rows: users,
      columns: columns,
    };
    this.usersTables.set(tempUsersTable);
  }
  getUsersForm() {
    return this.usersForm;
  }
  setUsersForm(columns, roles) {
    this.usersForm = this.formBuilder.group(createDynamicForm(columns, roles));
  }
  fetchDeleteUser(user, productId) {
    this.httpHandler
      .delete(`design/products/${productId}/users`, [user])
      .subscribe(() => {
        this.alertService.successSnackbar(DELETED_SUCCESS);
        this.usersTables.update((prev) => {
          const users = prev.rows.filter(
            (userLocal) => userLocal.id !== user.id
          );
          return {
            ...prev,
            rows: users,
          };
        });
      });
  }

  fetchAddUser(user: User, productId: number) {
    return this.httpHandler
      .post(`design/products/${productId}/users`, [user])
      .subscribe((userRes: User[]) => {
        this.alertService.successSnackbar(ADDING_SUCCESS);
        this.addUserList(userRes[0]);
      });
  }
  addUserList(user) {
    this.usersTables.update((prev: UsersTable) => {
      return {
        ...prev,
        rows: [...prev.rows, user],
      };
    });
  }
}
