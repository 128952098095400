import { Component, Input } from '@angular/core';
import { Option } from '../../../interfaces/dropdown';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrl: './radio-button.component.scss',
})
export class RadioButtonComponent {
  @Input() options: Option[];
  @Input() form: FormGroup;
  @Input() formName: string = '';
  @Input() title: string = '';
}
