/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { registerLicense } from '@syncfusion/ej2-base';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideHttpClient, withNoXsrfProtection } from '@angular/common/http';

import { AppModule } from './app/app.module';
import { DatepickerRangeComponent } from './app/components/widgets/datepicker-range/datepicker-range.component';

registerLicense(
  'Ngo9BigBOggjHTQxAR8/V1NAaF1cWWhKYVJ1WmFZfVpgdl9HYVZRR2YuP1ZhSXxXdkdiW39Xc3dXQmVYVE0='
  // 'ORg4AjUWIQA/Gnt2UVhhQlVFfV1dXGNWfFN0QXNcdVx4flBHcDwsT3RfQFljSH9Sd0JhUH5YeX1dRw=='
);
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
bootstrapApplication(DatepickerRangeComponent, {
  providers: [provideHttpClient(withNoXsrfProtection())],
})
  .then((ref) => {
    // Ensure Angular destroys itself on hot reloads.
    if (window['ngRef']) {
      window['ngRef'].destroy();
    }
    window['ngRef'] = ref;

    // Otherwise, log the boot error
  })
  .catch((err) => console.error(err));
