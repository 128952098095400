import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { checkForErrorsIn } from '../../../utils/validations';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss',
})
export class InputComponent implements OnInit {
  displayLength: string = '';
  @Input() form: FormGroup;
  @Input() id: string;
  @Input() formName: string = '';
  @Input() title: string = '';
  @Input() maxLength: number;
  @Input() isFocus: boolean;
  @Output() handleStepper = new EventEmitter();

  ngOnInit(): void {
    this.displayLength = `0/${this.maxLength}`;
  }

  checkForErrorsIn(formControl: AbstractControl, field: string): string {
    return checkForErrorsIn(formControl, field);
  }

  changeValue(text) {
    this.displayLength = `${text.length}/${this.maxLength}`;
  }
}
