import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { CustomBtnColor } from "../../interfaces/button";
import { Button } from "../../interfaces/components";
import { ActionRow, Column, DynamicColumns, Row } from "../../interfaces/table";
import { PAGINATION_SIZE } from "../../types/constant";
import { upperFirst } from "../../utils/strings";

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrl: "./table.component.scss",
})
export class TableComponent implements AfterViewInit, OnChanges, OnInit {
  constructor(private formBuilder: FormBuilder) {}

  public hoveredRowById: number = 0;
  public dataSource: MatTableDataSource<any>;
  public pagination: number = PAGINATION_SIZE;
  @Input() rows: Row[] = [];
  @Input() displayColumns: string[] = [];
  @Input() columns: Column[] | DynamicColumns[];
  @Input() header: string;
  @Input() subHeader: string;
  @Input() isActionTable: boolean = true;
  @Input() isHoverAction: boolean = true;
  @Input() isAddRow: boolean = false;
  @Input() buttonDetails: Button;
  @Input() isDisabledButton: boolean = false;
  @Input() data: Row;
  @Input() isEditMode: boolean;
  @Input() isPagination: boolean = false;
  @Input() totalCount: number = 1000;
  @Input() page: number = 0;
  @Input() form: FormGroup = this.formBuilder.group({});

  @Output() action = new EventEmitter();
  @Output() handleActionByName = new EventEmitter<ActionRow>();
  @Output() handleRowClick = new EventEmitter<Row>();
  @Output() handleChangePage = new EventEmitter<PageEvent>();
  @Output() handleRow = new EventEmitter<any>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  totalPage: string[];
  totalCountLocal;
  customBtnColor: CustomBtnColor = {
    color: "white",
    bg: "#494949",
    hover: "#928C8C",
    border: "1px solid #1c1c1c1e",
    disabled: "#D5D9DC",
  };
  ngOnChanges(changes): void {
    if (this.isPagination) {
      this.page === 1 && this.paginator?.firstPage();
      if (changes.totalCount) {
        this.totalCountLocal = changes.totalCount.currentValue;
      }
      this.dataSource = new MatTableDataSource<any>(this.rows);
      if (changes.isDisabledButton?.currentValue && !changes.isEditMode) {
        this.totalCountLocal = this.totalCount + 1;
      } else if (
        changes.isDisabledButton?.currentValue === false &&
        !changes.isEditMode
      ) {
        this.totalCountLocal = this.totalCountLocal - 1;
      } else if (changes.totalCount && !changes.totalCount?.previousValue) {
        this.totalCountLocal = this.totalCountLocal - 1;
      }
      if (
        changes.header?.previousValue &&
        changes.header.currentValue !== changes.header.previousValue
      ) {
        this.paginator.firstPage();
        this.totalCountLocal = this.totalCount;
      }
    } else {
      if (changes.isAddRow?.previousValue !== changes.isAddRow?.currentValue) {
        this.dataSource = new MatTableDataSource<any>(this.rows);
        this.dataSource.paginator = this.paginator;
      }
      if (changes.rows) {
        this.dataSource = new MatTableDataSource<any>(this.rows);
        this.dataSource.paginator = this.paginator;
      }
    }
  }
  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<any>(this.rows);
  }

  handleFormClick(actionName: string) {
    this.handleRow.emit({
      actionName,
    });
  }

  onRowAction(element, actionName: string) {
    this.handleRow.emit({
      row: element,
      actionName,
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.totalCountLocal = this.isPagination
      ? this.totalCount
      : this.pagination;
  }

  onClickIcon(event, row: Row, actionName: string, index: number) {
    this.handleActionByName.emit({ row: row, actionName, index });
    event.stopPropagation();
  }
  onRowClick(row: Row) {
    this.handleRowClick.emit(row);
  }
  onMouseOver(row: Row) {
    this.hoveredRowById = row.id;
  }
  onMouseOut() {
    this.hoveredRowById = 0;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  displayHeader(header) {
    return upperFirst(header);
  }
  getPath(baseUrl, colName, id) {
    const base = baseUrl;
    if (id) {
      return base + id + "/" + colName;
    }
    return base + this.rows.length + "/" + colName;
  }
}
