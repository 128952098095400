import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserUtils } from '@azure/msal-browser';
import { MasterDataComponent } from './components/pages/master-data/master-data.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { ProductsLayoutComponent } from './components/pages/products-layout/products-layout.component';
import { ProductsPageComponent } from './components/pages/products-page/products-page.component';
import { ProductsComponent } from './components/pages/products/products.component';
import { QueriesComponent } from './components/pages/queries/queries.component';
import { ReportsComponent } from './components/pages/reports/reports.component';
import { SettingsComponent } from './components/pages/settings/settings.component';
import { CreateColumnsComponent } from './components/pages/table-builder/create-columns/create-columns.component';
import { TableBuilderComponent } from './components/pages/table-builder/table-builder.component';
import { TableHistoryComponent } from './components/pages/table-builder/table-history/table-history.component';
import { TabsDetailsComponent } from './components/pages/tabs-details/tabs-details.component';
import { UsersComponent } from './components/pages/users/users.component';
import { HiddenTablesComponent } from './components/pages/hidden-tables/hidden-tables.component';
import { TableViewComponent } from './components/pages/hidden-tables/table-view/table-view.component';
import { AdminLayoutComponent } from './components/pages/admin-layout/admin-layout.component';
import { MasterDataLayoutComponent } from './components/pages/master-data-layout/master-data-layout.component';
import { RolesComponent } from './components/pages/roles/roles.component';
import {
  maslAdminGuard,
  maslRedirectGuard,
  maslSuperAdminGuard,
} from './masl.guard';
import { HomeComponent } from './components/home/home.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [maslRedirectGuard],
    pathMatch: 'full',
  },

  {
    path: 'products',
    children: [
      { path: '', component: ProductsComponent },
      { path: 'settings', component: SettingsComponent },
      {
        path: ':productName',
        children: [
          { path: '', component: SettingsComponent },
          {
            path: 'table_builder',
            component: TableBuilderComponent,
            children: [
              { path: '', component: TableHistoryComponent },
              { path: ':tableName', component: CreateColumnsComponent },
            ],
          },
          {
            path: 'hidden_tables',
            component: HiddenTablesComponent,
            children: [
              {
                path: ':tableName',
                component: TableViewComponent,
              },
            ],
          },
          { path: 'queries', component: QueriesComponent },
        ],
        component: TabsDetailsComponent,
      },
    ],
    canActivate: [maslSuperAdminGuard],
    component: ProductsLayoutComponent,
  },
  {
    path: 'products_page',
    children: [
      { path: '', component: ProductsPageComponent },
      {
        path: 'master_data',
        component: MasterDataLayoutComponent,
      },
      {
        path: 'master_data/:tableName',
        component: MasterDataComponent,
      },
      {
        path: 'report',
        component: MasterDataLayoutComponent,
      },
      {
        path: 'users',
        children: [
          { path: 'users', component: UsersComponent },
          { path: 'roles', component: RolesComponent },
        ],
        component: MasterDataLayoutComponent,
      },
      { path: 'report/:tableName', component: ReportsComponent },
    ],
    component: AdminLayoutComponent,
    canActivate: [maslAdminGuard],
  },

  {
    path: '**',
    component: NotFoundComponent,
    pathMatch: 'full',
  },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes or popups
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? 'enabledNonBlocking'
          : 'disabled', // Set to enabledBlocking to use Angular Universal
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
