import { Component, Input } from "@angular/core";
import { AbstractControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { checkForErrorsIn } from "../../../utils/validations";
import { FileDialogComponent } from "../../dialogs/file-dialog/file-dialog.component";
import { Meta } from "../../../interfaces/table";
import { HttpHandlerService } from "../../../services/http-handler.service";
import { FilesService } from "../../../services/files.service";

@Component({
  selector: "app-file",
  templateUrl: "./file.component.html",
  styleUrl: "./file.component.scss",
})
export class FileComponent {
  constructor(
    public dialog: MatDialog,
    public filesService: FilesService,
    public httpHandlerService: HttpHandlerService
  ) {}

  @Input() editMode: boolean;
  @Input() value: any;
  @Input() form: FormGroup;
  @Input() formName: string = "";
  @Input() meta: Meta;
  checkForErrorsIn(formControl: AbstractControl, field: string): string {
    return checkForErrorsIn(formControl, field);
  }

  displayValue() {
    if (typeof this.value === "object") {
      const fileName = this.value[0];
      return fileName.name;
    } else {
      return this.value || "";
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(FileDialogComponent, {
      data: {
        value: [{ name: this.form.get(this.formName).value }] || [
          {
            name: this.value,
          },
        ],
        title: `Uploading Files`,
        titleSubmit: "Upload",
        baseUrl: `${this.meta.baseUrl}`,
      },
      width: "23%",
    });
    dialogRef.afterClosed().subscribe((result) => {
      let isSizeFileCorrect = false;
      if (result?.isSave) {
        if (result.val.length > 0) {
          const formData = new FormData();
          isSizeFileCorrect = this.filesService.checkFileSize(
            result.val[0].file.size
          );
          if (isSizeFileCorrect) {
            formData.append("file", result.val[0].file);
            const url = `files/${result.val[0].name}`;
            this.filesService
              .uploadFile(url, formData, false)
              .subscribe((data) => {
                this.form.patchValue({
                  [this.formName]: result.val[0]?.name || "",
                });
              });
          }
        } else {
          this.form.patchValue({
            [this.formName]: "",
          });
          this.value = "";
        }
      }
      if (result && isSizeFileCorrect) {
        this.value = result?.val[0]?.name || "";
      }
    });
  }

  getFile(fileName) {
    const url = `files/${fileName}`;
    this.filesService.getFile(url);
  }
}
