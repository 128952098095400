import { Injectable } from "@angular/core";
import { HttpHandlerService } from "./http-handler.service";
import { AlertService } from "./alert.service";
import { ERROR__VALIDATION_UPLOAD } from "../types/constant";
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class FilesService {
  constructor(
    public httpHandlerService: HttpHandlerService,
    public alertService: AlertService,
    private http: HttpClient
  ) {}

  getFile(url: string) {
    this.downloadFile(`${environment.baseUriDesign}${url}`);
  }

  uploadFile(url: string, file: FormData, isRuntime: boolean) {
    return this.httpHandlerService.put(url, file, false);
  }

  private extractFilenameFromUrl(url: string): string {
    return url.split("/").pop();
  }
  private triggerDownload(blob: Blob, filename: string) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  downloadFile(url: string) {
    const headers = new HttpHeaders({
      Authorization: "Bearer " + localStorage.getItem("jwt"),
      Accept: "application/octet-stream",
    });
    this.http
      .get<Blob>(url, { headers: headers, responseType: "blob" as "json" })
      .subscribe(
        (blob) => {
          this.triggerDownload(blob, this.extractFilenameFromUrl(url));
        },
        (error) => {
          console.error("Download error:", error);
        }
      );
  }

  checkFileSize(fileSizeInBytes) {
    const maxFileSize = 5 * 1024 * 1024; //5 megabytes
    if (fileSizeInBytes <= maxFileSize) {
      return true;
    } else {
      this.alertService.errorPopup(ERROR__VALIDATION_UPLOAD);
      return false;
    }
  }
}
