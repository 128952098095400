import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../../../interfaces/dialog';
import { formattedJson } from '../../../utils/strings';
import { checkForErrorsIn, jsonValidator } from '../../../utils/validations';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-json-dialog',
  templateUrl: './edit-json-dialog.component.html',
  styleUrl: './edit-json-dialog.component.scss',
})
export class EditJsonDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<EditJsonDialogComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  jsonForm = this.formBuilder.group({
    name: [this.data.name, Validators.required],
    content: [this.data.content, Validators.compose([jsonValidator])],
  });

  checkForErrorsIn(formControl: AbstractControl, field: string): string {
    return checkForErrorsIn(formControl, field);
  }

  formattedJson(jsObj) {
    this.jsonForm.patchValue({
      content: formattedJson(jsObj),
    });
  }
}
