import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import {
  NgbCalendar,
  NgbDate,
  NgbInputDatepicker,
} from '@ng-bootstrap/ng-bootstrap';
import {
  DATE_BUTTONS,
  convertDateToNgbDate,
  convertNgbDateToDate,
  getLastDays,
  getTodayDate,
} from '../../../utils/date';
import moment from 'moment';
@Component({
  selector: 'app-datepicker-range',
  templateUrl: './datepicker-range.component.html',
  styleUrl: './datepicker-range.component.scss',
})
export class DatepickerRangeComponent implements OnInit {
  navigation = 'arrows';
  outsideDays = 'visible';
  buttonDatepicker = DATE_BUTTONS;
  calendar = inject(NgbCalendar);
  hoveredDate: NgbDate | null = null;
  @Input() fromDate = '2017-01-01';
  @Input() toDate = getTodayDate(new Date());
  @Output() fromDateEmit: EventEmitter<Date | null> =
    new EventEmitter<Date | null>();
  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() toDateEmit: EventEmitter<Date | null> =
    new EventEmitter<Date | null>();

  fromDateLocal: NgbDate = this.calendar.getToday();
  toDateLocal: NgbDate | null = this.calendar.getToday();
  ngOnInit(): void {
    convertDateToNgbDate(this.fromDate, this.fromDateLocal);
    convertDateToNgbDate(this.toDate, this.toDateLocal);
  }
  onSelect(days, datepicker: NgbInputDatepicker) {
    datepicker.navigateTo(this.calendar.getToday());
    const dateRange = getLastDays(days);
    this.fromDateLocal = dateRange.From;
    this.toDateLocal = dateRange.To;
    this.fromDateEmit.emit(convertNgbDateToDate(this.fromDateLocal));
    this.toDateEmit.emit(convertNgbDateToDate(this.toDateLocal));
    this.onClick.emit(true);
    this.buttonDatepicker.map((btn) => {
      if (btn.days === days) {
        btn.selected = !btn.selected;
      } else {
        btn.selected = false;
      }
    });
  }

  onDateSelection(date: NgbDate) {
    this.buttonDatepicker.map((btn) => {
      if (btn.title === 'Custom range') {
        btn.selected = true;
      } else {
        btn.selected = false;
      }
    });
    if (!this.fromDateLocal && !this.toDateLocal) {
      this.fromDateLocal = date;
    } else if (
      this.fromDateLocal &&
      !this.toDateLocal &&
      date.after(this.fromDateLocal)
    ) {
      this.toDateLocal = date;
      this.toDateEmit.emit(convertNgbDateToDate(this.toDateLocal));
    } else {
      this.toDateLocal = null;
      this.fromDateLocal = date;
      // this.toDateEmit.emit(convertNgbDateToDate(null));
      this.fromDateEmit.emit(convertNgbDateToDate(this.fromDateLocal));
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDateLocal &&
      !this.toDateLocal &&
      this.hoveredDate &&
      date.after(this.fromDateLocal) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return (
      this.toDateLocal &&
      date.after(this.fromDateLocal) &&
      date.before(this.toDateLocal)
    );
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDateLocal) ||
      (this.toDateLocal && date.equals(this.toDateLocal)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }
  displayDate() {
    const title = this.buttonDatepicker.find((btn) => btn.selected).title;
    let fromDateLocalTemp;
    let toDateLocalTemp;
    if (title === 'Custom range') {
      if (this.fromDateLocal) {
        fromDateLocalTemp = convertNgbDateToDate(this.fromDateLocal);
      }
      if (this.toDateLocal) {
        toDateLocalTemp = convertNgbDateToDate(this.toDateLocal);
      }
      return `${
        fromDateLocalTemp
          ? moment(fromDateLocalTemp).format('DD/MM/YY') +
            `${toDateLocalTemp ? '-' : ''}`
          : ''
      }${toDateLocalTemp ? moment(toDateLocalTemp).format('DD/MM/YY') : ''}`;
    }
    return title;
  }
}
