<div class="container-header">
  <div mat-dialog-title class="title">{{ data.title }}</div>
  <mat-icon class="icon-button" svgIcon="cancel" mat-dialog-close />
</div>
<div mat-dialog-content>
  <mat-form-field appearance="outline" class="text">
    <textarea matInput [(ngModel)]="data.value" cols="90" rows="8"></textarea>
  </mat-form-field>
</div>

<div mat-dialog-actions class="actions">
  <button
    class="submit btn"
    [mat-dialog-close]="{ val: data.value, isSave: true }"
    mat-button
    cdkFocusInitial
  >
    {{ data.titleSubmit || "Yes" }}
  </button>
  <button class="cancel btn" mat-button mat-dialog-close>
    {{ data.titleCancel }}
  </button>
</div>
