import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Option } from '../../../../interfaces/dropdown';
import { PRODUCT_DETAILS } from '../../../../types/constant';
import { SidebarService } from '../../../../services/sidebar.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrl: './product-details.component.scss',
})
export class ProductDetailsComponent {
  constructor(
    public sidebarService: SidebarService,
    public dialog: MatDialog
  ) {}

  @Input() productForm: FormGroup;
  @Input() header: string = '';
  @Input() options: Option[];
  @Output() openApiKeyDialog = new EventEmitter<any>();
  @Output() handleStepper = new EventEmitter<string>();

  id = PRODUCT_DETAILS;
  isFocus() {
    return this.id === this.sidebarService.getActiveStepper();
  }
  generateApi() {
    this.openApiKeyDialog.emit();
  }
}
