import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { checkForErrorsIn } from '../../../utils/validations';
import { Meta } from '../../../interfaces/table';

@Component({
  selector: 'app-boolean2',
  templateUrl: './boolean2.component.html',
  styleUrl: './boolean2.component.scss',
})
export class Boolean2Component implements OnInit {
  @Input() value: boolean;
  @Input() editMode: boolean;
  @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() form: FormGroup;
  @Input() formName: string = '';
  @Input() meta: Meta;
  checkForErrorsIn(formControl: AbstractControl, field: string): string {
    return checkForErrorsIn(formControl, field);
  }
  checked: boolean = false;

  ngOnInit(): void {
    this.checked = this.value;
  }

  displayValue(val) {
    return this.meta.options?.find((option) => option.value === val)?.title;
  }
}
