import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { checkForErrorsIn } from '../../../utils/validations';
import { Meta } from '../../../interfaces/table';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss',
})
export class SelectComponent implements OnInit, OnChanges {
  ngOnChanges(changes: any): void {
    if (!this.form.get(this.formName).value && changes.editMode?.currentValue) {
      this.form.patchValue({
        [this.formName]: '',
      });
    }
  }
  @Input() value: string;
  @Input() editMode: boolean;
  @Input() form: FormGroup;
  @Input() formName: string = '';
  @Input() meta: Meta;
  selected: string;

  displayValue: any;
  ngOnInit(): void {
    this.displayValue = this.meta.options.find(
      (op) => op.value === this.value
    )?.title;
  }
  valueChange(event) {
    this.form.patchValue({
      [this.formName]: event.value,
    });
  }
  checkForErrorsIn(formControl: AbstractControl, field: string): string {
    return checkForErrorsIn(formControl, field);
  }
  // checked(option) {
  //   return String(option) === String(this.form.get(this.formName).value);
  // }
}
