import { Injectable, signal } from '@angular/core';
import { Column, CreateColumn, Lookup } from '../interfaces/table';
import { HttpHandlerService } from './http-handler.service';
import { ProductsService } from './products.service';
import { Observable } from 'rxjs';
import { SUCCESS_ADD_COLUMNS, SUCCESS_UPDATE_COLUMNS } from '../types/constant';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root',
})
export class ColumnsService {
  private columnsList = signal<CreateColumn[] | null>(null);
  private columnsForDynamicTable = signal<Column[] | null>(null);
  private dataType = signal<any[] | null>(null);

  constructor(
    private httpHandler: HttpHandlerService,
    private productService: ProductsService,
    private alertService: AlertService
  ) {}
  getColumnsListByTableId(tableId: number) {
    return this.columnsList().filter((col) => col.tableId === tableId);
  }
  getColumnsList() {
    return this.columnsList();
  }
  setColumnsList(columns: CreateColumn[]) {
    this.columnsList.set(columns);
  }
  setColumnsForDynamicTable(columns: Column[]) {
    this.columnsForDynamicTable.set(columns);
  }
  getDataType() {
    return this.dataType();
  }
  getColumnsForDynamicTable() {
    return this.columnsForDynamicTable();
  }

  fetchAddColumn(columns: CreateColumn[], tableId: number, actions) {
    return this.httpHandler.post('design/columns', columns).subscribe(() => {
      this.httpHandler
        .post(`design/tables/${tableId}/publish`)
        .subscribe(() => {
          this.productService.fetchProducts();
          this.alertService.successPopup(SUCCESS_ADD_COLUMNS, actions());
        });
    });
  }

  fetchGetDataType() {
    return this.httpHandler.get('design/types').subscribe((data: any) => {
      this.dataType.set(data);
    });
  }
  fetchUpdateColumn(tableId: number, columns: CreateColumn[], actions) {
    return this.httpHandler.patch(`design/columns`, columns).subscribe(() => {
      this.httpHandler
        .post(`design/tables/${tableId}/publish`)
        .subscribe(() => {
          this.productService.fetchProducts();
          this.alertService.successPopup(SUCCESS_UPDATE_COLUMNS, actions);
        });
    });
  }
  fetchColumnsByTableId(tableId: number) {
    return this.httpHandler.get(`design/tables/${tableId}/columns`);
  }

  fetchGetLookupOptionsById(ids): Observable<Lookup[]> {
    return this.httpHandler.post(`design/columns/options`, ids);
  }
}
