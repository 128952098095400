import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Option } from '../../../../interfaces/dropdown';
import { CreateTable } from '../../../../interfaces/table';
import { TABLE_OPTIONS } from '../../../../mockData/dropdown';
import { ProductsService } from '../../../../services/products.service';
import { TablesService } from '../../../../services/tables.service';
import { removeSpaceAndCapitalizeWords } from '../../../../utils/strings';
import { hasNoSpaces } from '../../../../utils/validations';

const STATUS_TABLE: Option[] = [
  { value: true, title: 'Visible' },
  { value: false, title: 'Hidden' },
];
@Component({
  selector: 'app-create-table',
  templateUrl: './create-table.component.html',
  styleUrl: './create-table.component.scss',
})
export class CreateTableComponent {
  constructor(
    private formBuilder: FormBuilder,
    private tablesService: TablesService,
    private productsService: ProductsService,
    private router: Router
  ) {}
  public options = TABLE_OPTIONS;
  public statusTable = STATUS_TABLE;
  public columns;
  tableCreateForm = this.formBuilder.group({
    name: ['', Validators.compose([hasNoSpaces, Validators.required])],
    type: ['', []],
    isVisible: [true, Validators.required],
  });
  onSubmit() {
    const productId = this.productsService.productId();
    const productName = this.productsService.productName();
    const tableName = this.tableCreateForm.value.name;
    if (
      !(
        this.tableCreateForm.value.isVisible && !this.tableCreateForm.value.type
      )
    )
      this.productsService
        .fetchGetSchemaByProductId(productId)
        .subscribe((schemaRes: any) => {
          const readOnly = this.tableCreateForm.value.isVisible
            ? this.tableCreateForm.value.type === '1'
              ? false
              : true
            : false;
          const table: CreateTable = {
            isActive: true,
            readOnly: readOnly,
            productId: productId,
            schemaId: schemaRes[0].id,
            name: `${schemaRes[0].name}.${removeSpaceAndCapitalizeWords(
              tableName
            )}`,
            title: tableName,
            isVisible: this.tableCreateForm.value.isVisible,
          };
          this.tablesService
            .fetchAddTable(table)
            .subscribe((tableRes: CreateTable) => {
              this.tablesService.addTableHistory(tableRes);
              if (!this.tableCreateForm.value.isVisible) {
                this.tablesService.addHiddenTable(tableRes);
              }
              this.router.navigateByUrl(
                `products/${productName}/table_builder/${
                  schemaRes[0].name
                }.${removeSpaceAndCapitalizeWords(tableName)}`
              );
              this.tableCreateForm.reset();
            });
        });
  }
}
