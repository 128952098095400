<div class="container">
  <div class="containerMenu">
    <div class="menu">
      <mat-icon svgIcon="icl-logo" class="svg"></mat-icon>
      <app-menu></app-menu>
    </div>
  </div>
  <div>
    <app-user-setting></app-user-setting>
  </div>
</div>
