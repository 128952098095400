import { Injectable } from '@angular/core';
import { HttpHandlerService } from './http-handler.service';

@Injectable({
  providedIn: 'root',
})
export class SchemaService {
  constructor(private httpHandler: HttpHandlerService) {}

  fetchGetSchemaName(schemaId: number) {
    return this.httpHandler.get(`design/schemas/${schemaId}`);
  }
}
