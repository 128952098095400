<div class="content" id="{{ id }}">
  <div class="header">{{ header }}</div>
  <div class="container" [formGroup]="productForm">
    <app-input
      [isFocus]="isFocus()"
      [form]="productForm"
      title="Property ID"
      formName="googleAnalyticsPropId"
      (handleStepper)="handleStepper.emit(id)"
    ></app-input>
    <app-textarea
      title="JSON Key"
      formName="googleAnalyticsJsonKey"
      [form]="productForm"
      type="json"
      (handleStepper)="handleStepper.emit(id)"
    ></app-textarea>
  </div>
</div>
