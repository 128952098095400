import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PRODUCT_DETAILS } from '../../../types/constant';
import { DynamicForm } from '../../pages/settings/settings.component';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrl: './stepper.component.scss',
})
export class StepperComponent implements AfterViewInit {
  @Input() id: any = 1;
  @Input() header: string;
  @Input() component: any;
  @Input() form: FormGroup;
  @ViewChild('container', { read: ViewContainerRef })
  container!: ViewContainerRef;
  @Output() openApiKeyDialog = new EventEmitter<any>();
  @Output() handleStepper = new EventEmitter<string>();

  constructor() {}

  ngAfterViewInit(): void {
    this.container.clear();
    const dynamicComponent = this.container.createComponent(this.component);
    dynamicComponent.setInput('productForm', this.form);
    dynamicComponent.setInput('header', this.header);
    const dynamicInstance = dynamicComponent.instance as DynamicForm;
    if (this.header === PRODUCT_DETAILS) {
      dynamicInstance.openApiKeyDialog = this.openApiKeyDialog;
      dynamicComponent.setInput('options', [
        { value: 'active', title: 'Active' },
        { value: 'inactive', title: 'Non Active' },
      ]);
    }
    dynamicInstance.handleStepper = this.handleStepper;
  }
}
