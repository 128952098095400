@if (editMode) {
<div [formGroup]="form">
  <mat-form-field appearance="outline" class="container">
    <input [formControlName]="formName" matInput />
    <mat-error>{{ checkForErrorsIn(form.get(formName), formName) }}</mat-error>
  </mat-form-field>
</div>
}@else {
<div class="title">{{ value }}</div>
}
