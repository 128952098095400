@if (loginDisplay) {
<div class="container">
  @if (loadingService.isLoading()) {
  <div class="overlay">
    <mat-spinner></mat-spinner>
  </div>
  }
  <div class="sidebar">
    <app-sidebar> </app-sidebar>
  </div>
  <div class="topBar">
    <app-toolbar></app-toolbar>
  </div>
  <div class="scrollable-content">
    <router-outlet></router-outlet>
  </div>
</div>
}
