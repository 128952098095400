import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Button } from '../../../../interfaces/components';
import { Schema } from '../../../../interfaces/schema';
import {
  ActionRow,
  Column,
  DynamicColumns,
  Row,
} from '../../../../interfaces/table';
import { ProductsService } from '../../../../services/products.service';
import { SchemaService } from '../../../../services/schema.service';
import { SidebarService } from '../../../../services/sidebar.service';
import { TablesService } from '../../../../services/tables.service';
import {
  createColumnsToTableDesign,
  createDynamicForm,
  createEmptyRow,
  createTable,
} from '../../../../utils/table';
import { PAGINATION_SIZE } from '../../../../types/constant';
import { ColumnsService } from '../../../../services/columns.service';

@Component({
  selector: 'app-table-view',
  templateUrl: './table-view.component.html',
  styleUrl: './table-view.component.scss',
})
export class TableViewComponent implements OnInit {
  constructor(
    private sidebarService: SidebarService,
    private route: ActivatedRoute,
    public tablesService: TablesService,
    private formBuilder: FormBuilder,
    public productsService: ProductsService,
    private schemaService: SchemaService,
    private columnsService: ColumnsService
  ) {}
  rows: Row[] = [];
  columns: DynamicColumns[] = [];
  header: string;
  subHeader: string;
  displayColumns: string[] = [];
  buttonDetails: Button = {
    title: 'Add new row',
    icon: 'add',
  };
  emptyRow;
  isAddRow = false;
  isDisabledButton = false;
  isEditMode = false;
  rowAddingIndex = 0;
  hiddenTableId = 0;
  dynamicForm: FormGroup = null;
  columnsDynamic: Column[];
  indexEditRow: number = PAGINATION_SIZE;
  page = 1;
  limit = PAGINATION_SIZE;
  schemaName: string;
  tableName: string;
  table;
  ngOnInit(): void {
    this.route.params.subscribe(() => {
      this.isDisabledButton = false;
      this.isAddRow = false;
      this.isEditMode = false;
      this.tableName = this.route.snapshot.params['tableName'];
      this.sidebarService.expandedMenu('hidden_tables');
      this.productsService
        .fetchHiddenTablesByProductId()
        .subscribe((data: any) => {
          this.table = data.find((table) => table.name === this.tableName);
          this.hiddenTableId = this.table.id;
          const schemaId = this.table.schemaId;
          this.schemaService
            .fetchGetSchemaName(schemaId)
            .subscribe((schemaRes: Schema) => {
              this.schemaName = schemaRes.name;
              this.getData(this.page, this.limit);
            });
        });
    });
  }

  getData(page, limit) {
    this.tablesService
      .fetchGetDynamicTables(this.schemaName, this.tableName, page, limit)
      .subscribe((dataRes: any) => {
        this.tablesService
          .fetchGetColumnsByTableId(this.hiddenTableId)
          .subscribe((columnsRes: Column[]) => {
            createColumnsToTableDesign(
              columnsRes,
              this.columnsService,
              this.tableName
            ).subscribe(() => {
              this.columnsDynamic =
                this.columnsService.getColumnsForDynamicTable();
              if (typeof dataRes.data != 'string') {
                const hiddenTableMock = createTable(
                  this.table.title,
                  dataRes.data,
                  this.columnsDynamic
                );
                this.tablesService.setDynamicTable(hiddenTableMock);
                const { columns, header } =
                  this.tablesService.getDynamicTable();
                this.tablesService.setTotalCount(dataRes.totalCount);
                this.columns = columns;
                this.header = header;
                this.subHeader = '(Hidden)';
                this.emptyRow = createEmptyRow(columns);
                this.dynamicForm = this.formBuilder.group(
                  createDynamicForm(columns, this.emptyRow)
                );
                this.displayColumns = columns.map((item) => item['name']);
              }
            });
          });
      });
  }

  addEmptyRow() {
    this.rows = this.tablesService.getDynamicTable().rows;
    this.isAddRow = !this.isAddRow;
    this.rows.splice(this.indexEditRow, 0, this.emptyRow);
    this.rowAddingIndex = this.rows.length - 1;
    this.isDisabledButton = true;
  }
  handleActionByName(event: ActionRow) {
    if (!this.isAddRow && !this.isEditMode)
      if (event.actionName === 'EDIT_ROW') {
        this.isDisabledButton = true;
        this.tablesService.setRowForm(this.columnsDynamic, event.row);
        this.dynamicForm = this.tablesService.getRowForm();
        this.isEditMode = true;
        this.tablesService.changeRowMode(event.row.id, true);
      } else if (event.actionName === 'DELETE_ROW') {
        this.tablesService.fetchDeleteRow(event.row, this.hiddenTableId);
      }
  }
  handleRow(event: ActionRow) {
    const form = this.dynamicForm;
    switch (event.actionName) {
      case 'EDIT':
        this.tablesService.updateRow(form.value, this.hiddenTableId);
        break;
      case 'SAVE':
        this.tablesService
          .fetchAddRow(this.hiddenTableId, form.value)
          .add(() => {
            this.isDisabledButton = false;
            this.isAddRow = !this.isAddRow;
            this.getData(this.page, this.limit);
          });
        break;
      case 'CANCEL_ADD':
        this.resetEditMode();
        break;
      case 'CANCEL_EDIT':
        this.tablesService.changeRowMode(event.row.id, false);
        break;
      default:
        break;
    }
    this.tablesService.setRowForm(this.columnsDynamic, this.emptyRow);
    this.isEditMode = false;
    this.isDisabledButton = false;
    this.dynamicForm = this.tablesService.getRowForm();
  }
  resetEditMode() {
    this.rows = this.tablesService.getDynamicTable().rows;
    this.isDisabledButton = false;
    this.isAddRow = !this.isAddRow;
    this.rows.splice(this.rowAddingIndex, 1);
  }
  handleChangePage(pageRes) {
    this.isDisabledButton = false;
    this.isEditMode = false;
    this.isAddRow = false;
    this.indexEditRow = pageRes.pageSize * (pageRes.pageIndex + 1);
    this.getData(pageRes.pageIndex + 1, pageRes.pageSize);
    this.page = pageRes.pageIndex + 1;
  }
}
