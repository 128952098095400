<div class="container">
  @for (item of this.sidebarService.getMenu(); track $index) {
  <div class="parent">
    <div
      [style.pointerEvents]="!item.disabled ? 'auto' : 'none'"
      (click)="expendAction(item.id, item.children, item.name)"
      class="row"
      [ngStyle]="{
        backgroundColor: item.isExpanded ? '#f4f4f4' : 'white'
      }"
    >
      <div class="iconNameContainer">
        <mat-icon
          [svgIcon]="item.disabled ? item.icon + '-disable' : item.icon"
          [ngClass]="item.disabled ? 'disabled' : ''"
        />

        <div class="title" [class]="item.disabled ? 'disabled' : ''">
          {{ item.name }}
        </div>
      </div>
      @if ( item.children.length >0 ) {
      <div [class]="item.disabled ? 'disabled' : 'expend'">
        <mat-icon class="expend-icon"> expand_more </mat-icon>
      </div>
      }
    </div>
    @if (item.children.length >0 && item.isExpanded ) {
    <div class="childContainer">
      @for (child of item.children; track $index) {
      <div
        class="sub-title"
        (click)="
          drawTextMenu(
            item,
            child,
            item.action,
            this.sidebarService.getMenu()[0].url
          )
        "
        [ngClass]="{ selected: child.selected }"
      >
        {{ child.title }}
      </div>
      }
    </div>

    }
  </div>
  }
</div>
