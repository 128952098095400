import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Button } from "../../../interfaces/components";
import { ActionRow, Column, Row } from "../../../interfaces/table";
import { DynamicTablesService } from "../../../services/dynamic-tables.service";
import { ProductsService } from "../../../services/products.service";
import { SidebarService } from "../../../services/sidebar.service";
import { TablesService } from "../../../services/tables.service";
import {
  MASTER_DATA,
  MASTER_DATA_URL,
  PAGINATION_SIZE,
} from "../../../types/constant";
import { createDynamicForm, createEmptyRow } from "../../../utils/table";

@Component({
  selector: "app-master-data",
  templateUrl: "./master-data.component.html",
  styleUrl: "./master-data.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MasterDataComponent implements OnInit {
  constructor(
    private productService: ProductsService,
    private sidebarService: SidebarService,
    private dynamicTables: DynamicTablesService,
    public tablesService: TablesService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {}

  rows: any = [];
  columns: Column[] = [];
  header: string;
  dataChange: Row;
  displayColumns: string[] = [];
  buttonDetails: Button = {
    title: "Add new row ",
    icon: "add",
  };
  isAddRow = false;
  isEditMode = false;
  isDisabledButton = false;
  emptyRow;
  dynamicForm: FormGroup = null;
  dynamicTableId = 0;
  columnsDynamic: [];
  indexEditRow: number = PAGINATION_SIZE;
  rowAddingIndex = 0;
  page = 1;
  limit = PAGINATION_SIZE;
  tableName;
  table;
  ngOnInit(): void {
    this.sidebarService.expandedMenu(MASTER_DATA_URL);
    this.route.params.subscribe(() => {
      this.isDisabledButton = false;
      this.isAddRow = false;
      this.isEditMode = false;
      this.sidebarService.updateDisabled(false);
      const urlArr = this.router.url.split("/");
      this.tableName = urlArr[urlArr.length - 1];
      this.sidebarService.drawTextMenuByName(MASTER_DATA, this.tableName);
      this.table = this.productService
        .getManagementTables()
        ?.find((table) => table.name === this.tableName);
      this.dynamicTableId = this.table?.id;
      this.getData(this.page, this.limit);
    });
  }

  getData(page, limit) {
    this.dynamicTables
      .fetchGetDynamicTable(true, this.tableName, [], [], page, limit)
      .add(() => {
        const { columns } = this.tablesService.getDynamicTable();
        this.displayColumns = columns.map((item) => item["name"]);
        this.columns = columns;
        this.header = this.table.title;
        this.emptyRow = createEmptyRow(columns);
        this.dynamicForm = this.formBuilder.group(
          createDynamicForm(columns, this.emptyRow)
        );
      });
  }

  addEmptyRow() {
    this.rows = this.tablesService.getDynamicTable().rows;
    this.isAddRow = !this.isAddRow;
    this.rows.splice(this.indexEditRow, 0, this.emptyRow);
    this.rowAddingIndex = this.rows.length - 1;
    this.isDisabledButton = true;
  }

  handleActionByName(event: ActionRow) {
    if (!this.isAddRow && !this.isEditMode)
      if (event.actionName === "EDIT_ROW") {
        this.isDisabledButton = true;
        this.tablesService.setRowForm(this.columns, event.row);
        this.dynamicForm = this.tablesService.getRowForm();
        this.isEditMode = true;
        this.tablesService.changeRowMode(event.row.id, true);
      } else if (event.actionName === "DELETE_ROW") {
        this.tablesService.fetchDeleteRow(event.row, this.dynamicTableId);
      }
  }

  handleRow(event: ActionRow) {
    const form = this.dynamicForm;
    switch (event.actionName) {
      case "EDIT":
        this.tablesService.updateRow(form.value, this.dynamicTableId);
        break;
      case "SAVE":
        this.tablesService
          .fetchAddRow(this.tableName, form.value, false)
          .add(() => {
            this.isDisabledButton = false;
            this.isAddRow = !this.isAddRow;
            this.getData(this.page, this.limit);
          });
        break;
      case "CANCEL_ADD":
        this.resetEditMode();
        break;
      case "CANCEL_EDIT":
        this.tablesService.changeRowMode(event.row.id, false);
        break;
      default:
        break;
    }
    this.tablesService.setRowForm(this.columns, this.emptyRow);
    this.isEditMode = false;
    this.isDisabledButton = false;
    this.dynamicForm = this.tablesService.getRowForm();
  }
  resetEditMode() {
    this.rows = this.tablesService.getDynamicTable().rows;
    this.isDisabledButton = false;
    this.isAddRow = !this.isAddRow;
    this.rows.splice(this.rowAddingIndex, 1);
  }
  handleChangePage(pageRes) {
    this.isDisabledButton = false;
    this.isEditMode = false;
    this.isAddRow = false;
    this.indexEditRow = pageRes.pageSize * (pageRes.pageIndex + 1);
    this.getData(pageRes.pageIndex + 1, pageRes.pageSize);
    this.page = pageRes.pageIndex + 1;
  }
}
