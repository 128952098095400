<div class="table-builder">
  <div class="container">
    <form class="form" [formGroup]="tableCreateForm" (ngSubmit)="onSubmit()">
      <div class="header">Table details</div>
      <div class="inputs">
        <app-input
          [form]="tableCreateForm"
          title="Table name"
          formName="name"
        ></app-input>
        @if(tableCreateForm.value.isVisible){
        <app-dropdown
          [form]="tableCreateForm"
          [options]="options"
          title="Table type"
          formName="type"
        ></app-dropdown>
        }
        <div class="toggle">
          <app-button-toggle
            formName="isVisible"
            [form]="tableCreateForm"
            [options]="statusTable"
          ></app-button-toggle>
        </div>
      </div>
      <div class="action">
        <app-button
          type="submit"
          title="Create"
          [disabled]="!tableCreateForm.valid"
        ></app-button>
      </div>
    </form>
  </div>
</div>
