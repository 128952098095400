import { Component } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Button } from "../../../interfaces/components";
import { Schema } from "../../../interfaces/schema";
import {
  Column,
  DynamicDataTable,
  DynamicTable,
  FilterTable,
  Row,
} from "../../../interfaces/table";
import { ColumnsService } from "../../../services/columns.service";
import { ProductsService } from "../../../services/products.service";
import { SchemaService } from "../../../services/schema.service";
import { SidebarService } from "../../../services/sidebar.service";
import { TablesService } from "../../../services/tables.service";
import {
  PAGINATION_SIZE,
  REPORT,
  SUCCESS_DOWNLOAD,
} from "../../../types/constant";
import { createTable, getColType } from "../../../utils/table";
import { ReportsService } from "./reports.service";
import { AlertService } from "../../../services/alert.service";

@Component({
  selector: "app-reports",
  templateUrl: "./reports.component.html",
  styleUrl: "./reports.component.scss",
})
export class ReportsComponent {
  constructor(
    private productsService: ProductsService,
    private sidebarService: SidebarService,
    public tablesService: TablesService,
    private router: Router,
    private route: ActivatedRoute,
    private reportsService: ReportsService,
    private schemaService: SchemaService,
    private columnsService: ColumnsService,
    private alertService: AlertService
  ) {}
  rows: any = [];
  columns: Column[] = [];
  header: string;
  dataChange: Row;
  displayColumns: string[] = [];
  buttonDetails: Button = {
    title: "Export",
    icon: "",
  };
  isAddRow = false;
  isEditMode = false;
  isDisabledButton = false;
  dynamicForm: FormGroup = null;
  dynamicTableId = 0;
  columnsDynamic: Column[];
  indexEditRow: number = PAGINATION_SIZE;
  rowAddingIndex = 0;
  schemaName = "";
  tableName = "";
  rowsExport: Row[];
  columnsFilter: Column[];
  page = 1;
  limit = PAGINATION_SIZE;
  table;
  filterData;
  filteredColumns;
  isActionTable = true;

  ngOnInit(): void {
    this.sidebarService.expandedMenu("report");
    this.route.params.subscribe(() => {
      this.page = 1;
      this.sidebarService.updateDisabled(false);
      const urlArr = this.router.url.split("/");
      this.tableName = urlArr[urlArr.length - 1];
      this.sidebarService.drawTextMenuByName(REPORT, this.tableName);
      this.table = this.productsService
        .getReportTables()
        .find((table) => table.name === this.tableName);
      this.schemaService
        .fetchGetSchemaName(this.table.schemaId)
        .subscribe((data: Schema) => {
          this.schemaName = data.name;
          this.dynamicTableId = this.table.id;
          this.getData(this.page, this.limit);
        });
    });
  }

  getData(page, limit) {
    let localPage = 0;
    let localLimit = 0;
    this.tablesService
      .fetchGetDynamicTables(this.schemaName, this.tableName, page, limit)
      .subscribe((dataRes: DynamicDataTable) => {
        if (dataRes.data.length > 0) {
          localPage = page;
          localLimit = limit;
          this.isActionTable = true;
        } else {
          this.isActionTable = false;
        }
      })
      .add(() => {
        const tempData = {
          columnDisplay: ["*"],
          data: [],
          table: `${this.tableName}`,
        };
        this.filterData = tempData;
        this.reportsService
          .fetchFilter(tempData, localPage, localLimit)
          .subscribe((dataRes: any) => {
            this.tablesService
              .fetchGetColumnsByTableId(this.dynamicTableId)
              .subscribe((columnsRes: Column[]) => {
                this.columnsDynamic = columnsRes.map((col) => {
                  return {
                    name: col.name,
                    title: col.title,
                    type: getColType(col.type),
                    required: col.required,
                  };
                });
                const hiddenTableMock = createTable(
                  this.table.title,
                  dataRes?.data || dataRes,
                  this.columnsDynamic
                );
                this.tablesService.setDynamicTable(hiddenTableMock);
                const { columns, header } = hiddenTableMock;
                this.tablesService.setTotalCount(dataRes.totalCount || 0);
                this.columns = columns;
                this.displayColumns = columns.map((item) => item["name"]);
                this.columnsFilter = columns;
                this.header = header;
              });
          });
      });
  }

  saveChanges(data) {
    this.page = 1;
    this.filter(data);
  }

  filter(data: FilterTable, page = this.page, limit = this.limit) {
    const columnDisplayTemp =
      data.columnDisplay.length === 0
        ? ["*"]
        : data.columnDisplay[0] === "*"
        ? data.columnDisplay
        : data.columnDisplay.filter((col) => col !== "selectAll");
    const tempData = {
      columnDisplay: columnDisplayTemp,
      data: data.data,
      table: `${this.tableName}`,
    };
    this.filterData = tempData;
    this.reportsService
      .fetchFilter(tempData, page, limit)
      .subscribe((filterRows: DynamicDataTable) => {
        this.rowsExport =
          typeof filterRows.data === "string" ? [] : filterRows.data;
        this.columnsService.setColumnsForDynamicTable(this.columns);
        if (data.columnDisplay.length === 0 || data.columnDisplay[0] === "*") {
          this.filteredColumns = this.columnsDynamic;
        } else {
          this.filteredColumns = this.columns.filter((column) => {
            return data.columnDisplay.some((arr) => arr.includes(column.name));
          });
        }
        const tableRowColumn: DynamicTable = createTable(
          this.tableName,
          this.rowsExport,
          this.filteredColumns
        );
        this.tablesService.setDynamicTable(tableRowColumn);
        this.tablesService.setTotalCount(filterRows.totalCount);
        this.displayColumns = tableRowColumn.columns.map(
          (item) => item["name"]
        );
        this.columnsFilter = tableRowColumn.columns;
      });
  }
  handleChangePage(pageRes) {
    this.filter(this.filterData, pageRes.pageIndex + 1, pageRes.pageSize);
    this.page = pageRes.pageIndex + 1;
    this.isEditMode = false;
    this.isAddRow = false;
  }
  isResultFound() {
    return this.rowsExport?.length > 0;
  }

  exportTable() {
    this.reportsService
      .fetchFilter(this.filterData, 0, 0)
      .subscribe((filterRows: any) => {
        const fileName = `${this.tableName}`;
        console.log("filterRows", filterRows, "fileName", filterRows.length);
        if (filterRows.rows.length > 0) {
          const data = {
            data: filterRows.rows,
            fileName,
          };
          this.reportsService
            .fetchExportReport(data)
            .subscribe((dataRes: any) => {
              console.log("dataRes", dataRes, fileName, "data", data);

              const a = document.createElement("a");
              const blob = new Blob([dataRes], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              });

              const url = window.URL.createObjectURL(blob);
              a.href = url;
              a.download = `${fileName}.xlsx`;
              a.click();
              window.URL.revokeObjectURL(url);
              a.remove();
              this.alertService.successPopup(SUCCESS_DOWNLOAD);
            });
        }
      });
  }
}
