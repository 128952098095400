import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { checkForErrorsIn } from '../../../utils/validations';

@Component({
  selector: 'app-number',
  templateUrl: './number.component.html',
  styleUrl: './number.component.scss',
})
export class NumberComponent {
  @Input() value: string;
  @Input() editMode: boolean;
  @Input() form: FormGroup;
  @Input() formName: string = '';
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  displayValue: any;
  ngOnInit(): void {
    this.displayValue = this.value ? (+this.value).toLocaleString() : '';
  }
  checkForErrorsIn(formControl: AbstractControl, field: string): string {
    return checkForErrorsIn(formControl, field);
  }
}
