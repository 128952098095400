import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, tap } from "rxjs";
import { environment } from "../../environments/environment";
import { LoadingService } from "./loading.service";

@Injectable({
  providedIn: "root",
})
export class HttpHandlerService {
  private baseUriDesign = environment.baseUriDesign;
  private baseUriRuntime = environment.baseUriRuntime;
  private publicUrl = environment.publicUrl;
  constructor(
    private http: HttpClient,
    private loadingService: LoadingService
  ) {}

  private buildHeaders(isRuntime: boolean) {
    var headers = new HttpHeaders({
      responseType: "text",
      authorization: isRuntime ? "" : "Bearer " + localStorage.getItem("jwt"),
      Accept: "plain/text",
      "X-Second-JWT": localStorage.getItem("token"),
    });
    return headers;
  }

  get<T>(url, isRuntime?: boolean): Observable<T> {
    this.loadingService.show();
    var options = { headers: this.buildHeaders(isRuntime) };
    return this.http
      .get<T>(
        (isRuntime ? this.baseUriRuntime : this.baseUriDesign) + url,
        options
      )
      .pipe(tap(() => this.loadingService.hide()));
  }

  post<T>(
    url,
    data?,
    isRuntime?: boolean,
    responseType?: string
  ): Observable<T> {
    this.loadingService.show();
    return this.http
      .post<T>(
        (isRuntime ? this.baseUriRuntime : this.baseUriDesign) + url,
        data,
        {
          responseType: responseType ? (responseType as "json") : "json",
          headers: {
            responseType: "text",
            authorization: isRuntime
              ? ""
              : "Bearer " + localStorage.getItem("jwt"),
            Accept: "plain/text",
            "X-Second-JWT": localStorage.getItem("token"),
          },
        }
      )
      .pipe(tap(() => this.loadingService.hide()));
  }

  patch<T>(url, data, isRuntime?: boolean): Observable<T> {
    this.loadingService.show();

    return this.http
      .patch<T>(this.baseUriDesign + url, data, {
        headers: this.buildHeaders(isRuntime),
      })
      .pipe(tap(() => this.loadingService.hide()));
  }

  put<T>(url, data, isRuntime?: boolean): Observable<T> {
    this.loadingService.show();

    return this.http
      .put<T>(
        (isRuntime ? this.baseUriRuntime : this.baseUriDesign) + url,
        data,
        {
          headers: this.buildHeaders(isRuntime),
        }
      )
      .pipe(tap(() => this.loadingService.hide()));
  }

  delete<T>(url, data?, isRuntime?: boolean): Observable<T> {
    this.loadingService.show();

    return this.http
      .delete<T>(this.baseUriDesign + url, {
        body: data,
        headers: this.buildHeaders(isRuntime),
      })
      .pipe(tap(() => this.loadingService.hide()));
  }

  postPublic<T>(url, data?): Observable<T> {
    var headers = new HttpHeaders({
      responseType: "text",
      authorization:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IlhSdmtvOFA3QTNVYVdTblU3Yk05blQwTWpoQSIsImtpZCI6IlhSdmtvOFA3QTNVYVdTblU3Yk05blQwTWpoQSJ9.eyJhdWQiOiIzNjc4OTgyNi1hNmMyLTRiZWQtYjg2Mi1mMDdkOGRlNmViMzQiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC84MDI3NjJkMi0wMmM0LTQ2NzctOThiYS01NDA2MGEyMzQyMDQvIiwiaWF0IjoxNzEwOTQzNTA2LCJuYmYiOjE3MTA5NDM1MDYsImV4cCI6MTcxMDk0NzQwNiwiYWlvIjoiQVZRQXEvOFdBQUFBMkRveE4zZU9kSFNoOGJLb3lpS0FDOTYvM2JuU2lzUnl3VEVybDMyMHZtRm0wNExDYlhmTXZUWUJ6OTNUelhQOGIrb081QTFQV1VHZXFBUDQ4alNya2lZcDVHUlU2OVI2VUY5RXNqenY5VFk9IiwiYW1yIjpbInB3ZCIsIm1mYSJdLCJmYW1pbHlfbmFtZSI6IkRhdmlkeWFuIiwiZ2l2ZW5fbmFtZSI6IllhaXIiLCJpcGFkZHIiOiIxNDcuMjM2LjEyNC41NCIsIm5hbWUiOiJFWC1ZYWlyIERhdmlkeWFuIiwibm9uY2UiOiJkZDEyYjc4Ni00NjZjLTQ3NGQtYjZjYi1jMTQwMjQ5YTVmYTAiLCJvaWQiOiIwOGZmOWY1ZC03ZTBhLTRlNzUtYTVjNS1hMWU2YTFkNDUwYzQiLCJvbnByZW1fc2lkIjoiUy0xLTUtMjEtMjM3MzExNzUwNC0yNDQwOTY3NjUxLTMzNjI1MDY5MjEtMTcxODA3IiwicmgiOiIwLkFRd0EwbUluZ01RQ2QwYVl1bFFHQ2lOQ0JDYVllRGJDcHUxTHVHTHdmWTNtNnpTV0FBMC4iLCJzdWIiOiJVbXZIODVmWmVjUlBzQ2NXUkFNejBEUkhXM0JSclQtaXFhUXg2M3hDVU80IiwidGlkIjoiODAyNzYyZDItMDJjNC00Njc3LTk4YmEtNTQwNjBhMjM0MjA0IiwidW5pcXVlX25hbWUiOiJZYWlyLkRhdmlkeWFuQGljbC1ncm91cC5jb20iLCJ1cG4iOiJZYWlyLkRhdmlkeWFuQGljbC1ncm91cC5jb20iLCJ1dGkiOiJ6cUdpRTgzdHhrS19EV0l2cW9OdkFBIiwidmVyIjoiMS4wIn0.HBwIfXSKZkSe2dij8gRwR9ob2wmI_fmu9mHxvKW5YszW5-T6f8lwipwWSyNVP746L9NhjaLlN0k8HW0mXwuN6BrfX7CT4miRaaYozDSH-x1DJltKjjFX1VGGKEMpfb6rJIBYH-ZsxyFrl8T_ZjksYjLHyEN0WK2Ga8S-APFh7w7IOQgyVYO_ccFoQQp12a-QbfkqsMjPYUdlbmNmEr3WGpe_X9yqm9htiEbDRVpb1n8kWeTfMNG3SD6nOKnpc9Q-JVWff5ym21XSZ79PxwQIDyJBbupegW_tyft-DnriMKbornT9WC6P1zq9RmW4CkzkTf2CreXlP27Vtmf7FEZQcg",
      Accept: "plain/text",
    });
    this.loadingService.show();
    var options = { headers: headers };
    return this.http
      .post<T>(this.publicUrl + url, data, options)
      .pipe(tap(() => this.loadingService.hide()));
  }
}
