import { Component, OnInit } from '@angular/core';
import { ProductsService } from '../../../services/products.service';
import { SidebarService } from '../../../services/sidebar.service';
import { SIDEBAR_MENU_SUPER_ADMIN } from '../../../mockData/menu';
import { UserAuthService } from '../../../services/userAuth.service';

@Component({
  selector: 'app-products-layout',
  templateUrl: './products-layout.component.html',
  styleUrl: './products-layout.component.scss',
})
export class ProductsLayoutComponent implements OnInit {
  constructor(
    public productsService: ProductsService,
    private sidebarService: SidebarService,
    private userAuthService: UserAuthService
  ) {}
  ngOnInit(): void {
    this.userAuthService.setUserProductLStorage(null);
    this.sidebarService.sidebarMenu.set(SIDEBAR_MENU_SUPER_ADMIN);
    this.productsService.fetchProducts();
  }
}
