import { Component, effect } from '@angular/core';
import { Router } from '@angular/router';
import { CustomBtnColor } from '../../interfaces/button';
import { SidebarService } from '../../services/sidebar.service';
import { UserAuthService } from '../../services/userAuth.service';
import { SYSTEM_ROLES } from '../../types/constant';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.scss',
})
export class ToolbarComponent {
  currentUrl: string
  constructor(
    private sidebarService: SidebarService,
    public userAuthService: UserAuthService,
    private router: Router
    
  ) {
    effect(() => {
      this.productName = this.sidebarService.productName();
      this.sidebarService.setProductName(this.productName);
      this.breadcrumbs = this.sidebarService.getTitleToolbar();
      this.currentUrl = this.router.url;
    });
  }
  breadcrumbs = [];
  productName;
  superAdmin = SYSTEM_ROLES.superAdmin;
  customBtnColor: CustomBtnColor = {
    color: 'black',
    bg: 'white',
    hover: 'gray',
    border: '1px solid #1c1c1c1e',
    disabled: '#D5D9DC',
  };
  isCurrentPage(breadcrumb): boolean {
    return this.currentUrl === breadcrumb.link;
  }
  back() {
    this.router.navigate(['products']);
    this.userAuthService.setUserProductLStorage(null);
  }
}
