import { Component, Input, OnInit } from '@angular/core';
import * as mapsData from 'devextreme-dist/js/vectormap-data/world.js';
import { scaleLinear } from 'd3-scale';

@Component({
  selector: 'app-vector',
  styleUrls: ['vector.component.scss'],
  providers: [],
  templateUrl: 'vector.component.html',
})
export class VectorComponent implements OnInit {
  @Input() countriesData: Object;
  worldMap: any = mapsData.world;
  countries: Object;

  constructor() {
    this.customizeTooltip = this.customizeTooltip.bind(this);
    this.customizeLayers = this.customizeLayers.bind(this);
    this.click = this.click.bind(this);
  }
  ngOnInit() {
    this.handleCountryData(this.countriesData);
  }

  ngOnChanges(changes: Object) {
    this.handleCountryData(changes);
  }

  handleCountryData(data) {
    if (!data) return;
    let countries = data.countriesData?.currentValue;
    const popScale = scaleLinear()
      .domain([100, 3000])
      .range(['#AAAAAA', '#444444']);
    let finalCountryData = {};
    if (countries === null) return;
    Object.keys(countries).forEach(function (key) {
      finalCountryData[key] = {
        color: popScale(parseInt(countries[key]) * 100),
      };
    });

    this.countries = finalCountryData;
  }

  customizeTooltip(arg) {
    let name = arg.attribute('name');
    return {
      text: name,
      color: '#FFFFFF',
      fontColor: '#000',
    };
  }

  customizeLayers(elements) {
    if (!this.countries) return;
    elements.forEach((element) => {
      const countryName = element.attribute('name');
      let country =
        countryName in this.countries
          ? this.countries[element.attribute('name')]
          : false;
      if (country) {
        element.applySettings({
          color: country.color,
          hoveredColor: country.color,
          selectedColor: country.color,
        });
      } else {
        element.applySettings({
          color: '#e4e4e4',
          hoveredColor: '#e4e4e4',
          selectedColor: '#e4e4e4',
        });
      }
    });
  }

  click(e) {
    let target = e.target;
    if (target && this.countries[target.attribute('name')]) {
      target.selected(!target.selected());
    }
  }
}
