import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { checkForErrorsIn } from '../../../utils/validations';
import { Meta } from '../../../interfaces/table';
import { STATUS } from '../../../types/constant';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrl: './text.component.scss',
})
export class TextComponent {
  @Input() editMode: boolean;
  @Input() value: string;
  @Input() form: FormGroup;
  @Input() formName: string = '';
  @Input() meta: Meta;

  checkForErrorsIn(formControl: AbstractControl, field: string): string {
    return checkForErrorsIn(formControl, field);
  }
  isArray() {
    return Array.isArray(this.value);
  }
  displayColor() {
    return STATUS[this.value] === 'Active' ? '#4AA785' : '#FF6F6F';
  }
  displayValue() {
    return this.meta.style ? STATUS[this.value] : this.value;
  }
}
