import { Column } from '../interfaces/table';
import { User } from '../interfaces/users';

export const columnsUsers: Column[] = [
  {
    name: 'external_user_id',
    title: 'Email Address',
    type: 'text',
    required: true,
  },
  {
    name: 'role_id',
    title: 'Role Name',
    type: 'select',
    required: true,
  },

  {
    name: 'action',
    title: 'Action',
    type: 'action',
    required: false,
    quick_actions: [{ name: 'DELETE_QUERY', icon: 'trash' }],
  },
];
export const emptyUser: User = {
  id: 0,
  externalUserId: '',
  role: '',
  editMode: true,
};
