import { QueriesList, Query } from "../interfaces/queries";
import { Column } from "../interfaces/table";

export const rows: Query[] = [
  {
    id: 1,
    name: "select user",
    title: "select user",
    sql: "SELECT userName, City FROM User;SELECT * FROM USER WHERE PRODUCT_ID =*0*",
    description: "SELECT userName",
    editMode: false,
  },
  {
    id: 2,
    name: "select product",
    title: "select user",
    description: "SELECT productName",
    sql: "SELECT productName, City FROM products;SELECT * FROM PRODUCTS WHERE PRODUCT_ID =*0*",
    editMode: false,
  },
  {
    id: 3,
    name: "select service",
    title: "select user",
    description: "SELECT ServiceName",
    sql: "SELECT ServiceName, City FROM Services;SELECT * FROM SERVICE WHERE PRODUCT_ID =*0*",
    editMode: false,
  },
  {
    id: 4,
    name: "select settings",
    title: "select settings",
    sql: "SELECT SettingsName, City FROM Setting;SELECT * FROM SETTINGS WHERE PRODUCT_ID =*0*",
    description: "SELECT ServiceName",
    editMode: false,
  },
];

export const emptyQuery: Query = {
  id: 0,
  title: "",
  description: "",
  sql: "",
  editMode: true,
};

export const columnsQueries: Column[] = [
  {
    name: "title",
    title: "Title",
    type: "text",
    required: true,
  },
  {
    name: "description",
    title: "Description",
    type: "text",
    required: true,
  },
  {
    name: "sql",
    title: "Sql",
    type: "long text",
    required: true,
  },
  {
    name: "action",
    title: "Action",
    type: "action",
    required: false,
    quick_actions: [
      { name: "EDIT_QUERY", icon: "edit" },
      { name: "DELETE_QUERY", icon: "trash" },
      // { name: 'DISPLAY_PRODUCT_DETAILS', icon: 'save' },
    ],
  },
];
export const mockQueries: QueriesList = {
  header: "Queries",
  columns: columnsQueries,
};
