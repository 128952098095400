import { Injectable, signal } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import {
  CreateTable,
  DynamicTable,
  HistoryTable,
  Row,
} from "../interfaces/table";
import { columnsHistoryTable } from "../mockData/tables";
import { createDynamicForm } from "../utils/table";
import { HttpHandlerService } from "./http-handler.service";
import { ProductsService } from "./products.service";
import { AlertService } from "./alert.service";
import {
  ADDING_SUCCESS,
  DELETED_SUCCESS,
  UPDATED_SUCCESS,
} from "../types/constant";

@Injectable({
  providedIn: "root",
})
export class TablesService {
  private tablesHistory = signal<HistoryTable | null>(null);
  private currentTable = signal<CreateTable | null>(null);
  public hiddenTables = signal<CreateTable[] | null>([]);
  public allTables = signal<CreateTable[] | null>(null);
  public dynamicTable = signal<DynamicTable | null>(null);
  public managementTables = signal<CreateTable[] | null>(null);
  public totalCount = signal<number>(0);
  public rowForm: FormGroup;

  constructor(
    private httpHandler: HttpHandlerService,
    private productsService: ProductsService,
    private router: Router,
    private formBuilder: FormBuilder,
    private alertService: AlertService
  ) {}

  fetchAddTable(table: CreateTable) {
    return this.httpHandler.post("design/tables", table);
  }

  fetchTables(productId) {
    return this.httpHandler.get(`design/tables/${productId}`).subscribe();
  }
  getHiddenTable() {
    return this.allTables();
  }

  getIdTableByName(tableName) {
    return this.hiddenTables()?.find((table) => table.name === tableName).id;
  }
  getHiddenTables() {
    return this.hiddenTables();
  }
  setHiddenTables(hiddenTables) {
    return this.hiddenTables.set(hiddenTables);
  }
  addHiddenTable(table) {
    this.hiddenTables.update((prev) => {
      return [...prev, table];
    });
  }

  routeTo(parentUrl, tableName, baseUrl) {
    const productName = this.productsService.productName();
    if (productName && baseUrl === "products") {
      this.router.navigateByUrl(
        `/${baseUrl}/${productName}/${parentUrl}/${tableName}`
      );
    } else {
      this.router.navigateByUrl(`/${baseUrl}/${parentUrl}/${tableName}`);
    }
  }

  fetchGetDynamicTables(
    schemaName: string,
    tableName: string,
    page?: number,
    limit?: number
  ) {
    return this.httpHandler.get(`design/tables/${tableName}/data`, false);
  }
  fetchGetColumnsByTableId(tableId: number) {
    return this.httpHandler.get(`design/tables/${tableId}/columns`);
  }

  getManagementTables() {
    return this.managementTables();
  }
  fetchTablesByProduct(productId?: number) {
    const productIdLocal = productId
      ? productId
      : this.productsService.getProduct().id;
    return this.httpHandler
      .get(`design/products/${productIdLocal}/tables`)
      .subscribe((tablesRes: CreateTable[]) => {
        if (tablesRes.length > 0) {
          this.managementTables.set(
            tablesRes.filter((table) => !table.readOnly)
          );
          const tablesWithColumns = {
            header: `${this.productsService.productTitle()} Tables`,
            rows: tablesRes,
            columns: columnsHistoryTable,
          };
          this.setTablesHistory(tablesWithColumns);
        } else {
          this.setTablesHistory({
            header: `${this.productsService.productTitle()} Tables`,
            rows: [],
            columns: columnsHistoryTable,
          });
        }
      });
  }

  getTablesHistory() {
    return this.tablesHistory();
  }
  getTableIdByName(tableName) {
    return this.tablesHistory()?.rows.find(
      (table) => table?.name === tableName.toLowerCase()
    )?.id;
  }
  getTableTitle(tableId) {
    return this.tablesHistory()?.rows.find((table) => table.id === tableId)
      ?.title;
  }
  setTablesHistory(tables) {
    this.tablesHistory.set(tables);
  }
  addTableHistory(row) {
    this.tablesHistory.update((prev) => {
      return {
        ...prev,
        rows: [...prev.rows, row],
      };
    });
  }

  fetchDeleteTable(tableId) {
    this.httpHandler.delete(`design/tables/${tableId}`).subscribe((data) => {
      this.alertService.successSnackbar(DELETED_SUCCESS);
      this.tablesHistory.update((prev) => {
        const tables = prev.rows.filter((table) => table.id !== tableId);
        return {
          ...prev,
          rows: tables,
        };
      });
    });
  }

  getCurrentTable() {
    return this.currentTable();
  }
  setCurrentTable(table: CreateTable) {
    this.currentTable.set(table);
  }
  setDynamicTable(dynamicTable: DynamicTable) {
    this.dynamicTable.set(dynamicTable);
  }
  setTotalCount(totalCount: string) {
    this.totalCount.set(Number(totalCount));
  }
  getTotalCount() {
    return this.totalCount();
  }
  getDynamicTable() {
    return this.dynamicTable();
  }

  fetchAddRow(tableId, row: Row, isRuntime?) {
    delete row.id;
    const url = isRuntime ? `data/${tableId}` : `design/tables/${tableId}/data`;
    return this.httpHandler
      .post(url, [row], isRuntime)
      .subscribe((rowRes: Row) => {
        this.alertService.successSnackbar(ADDING_SUCCESS);
        this.addRowToTable(rowRes[0]);
      });
  }

  getRowForm() {
    return this.rowForm;
  }
  setRowForm(columns, rowData?) {
    this.rowForm = this.formBuilder.group(createDynamicForm(columns, rowData));
  }
  changeRowMode(id: number, mode: boolean) {
    this.dynamicTable.update((prev: DynamicTable) => {
      const temp = prev.rows.find((item) => item.id === id);
      temp.editMode = mode;
      return {
        ...prev,
      };
    });
  }
  updateRow(row: Row, tableId: number) {
    delete row.editMode;
    this.httpHandler
      .patch(`design/tables/${tableId}/data`, [row])
      .subscribe((rowRes: Row) => {
        this.alertService.successSnackbar(UPDATED_SUCCESS);
        this.dynamicTable.update((prev: DynamicTable) => {
          const newRows = prev.rows.map((item) => {
            if (item.id === row.id) {
              return {
                ...row,
              };
            }
            return {
              ...item,
            };
          });
          return {
            ...prev,
            rows: [...newRows],
          };
        });
      });
  }

  fetchDeleteRow(rowLocal, tableId: number) {
    this.httpHandler
      .delete(`design/tables/${tableId}/data`, [rowLocal])
      .subscribe(() => {
        this.alertService.successSnackbar(DELETED_SUCCESS);
        this.dynamicTable.update((prev) => {
          const rows = prev.rows.filter((row) => row.id !== rowLocal.id);
          return {
            ...prev,
            rows: rows,
          };
        });
        this.totalCount.update((prev) => prev - 1);
      });
  }

  addRowToTable(query) {
    this.dynamicTable.update((prev: DynamicTable) => {
      return {
        ...prev,
        rows: [...prev.rows, query],
      };
    });
    this.totalCount.update((prev) => prev + 1);
  }

  publishTable(tableId) {
    this.httpHandler.post(`design/tables/${tableId}/publish`).subscribe(() => {
      this.productsService.fetchProducts();
    });
  }
}
