// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  languages: ["en"],
  //  httpUrl:"http://localhost:3000/",
  baseUriDesign: "https://api.fs-cms-dev.appsc.icldig.icl-group.com/api/", //dev
  // baseUriDesign: "http://localhost:3000/api/", //dev
  // baseUriRuntime: "http://localhost:3001/api/", //dev
  baseUriRuntime:
    "https://txmpl8dpl4.execute-api.eu-west-1.amazonaws.com/prod/api/", //dev
  publicUrl: "https://api.fsportfolio.appsb.icldig.icl-group.com/",
  urlToStaticSite: "https://fsportfolio.appsb.icldig.icl-group.com/Uploads/",
  adalConfig: {
    tenant: "802762d2-02c4-4677-98ba-54060a234204",
    clientId: "3852734d-2646-4543-ab7e-3f8ddbadd575",
    expireOffsetSeconds: 320, //15 minutes (60-15 = 45 minutes auth)
    popUp: false,
    cacheLocation: "localStorage",
    resource: "3852734d-2646-4543-ab7e-3f8ddbadd575",
  },
  firebaseConfig: {
    apiKey: "AIzaSyABfJZZi0_ey9ERKcr9DTHmHRBso3ytXQ0",
    authDomain: "fs-portfolio-40e27.firebaseapp.com",
    projectId: "fs-portfolio-40e27",
    storageBucket: "fs-portfolio-40e27.appspot.com",
    messagingSenderId: "730478240900",
    appId: "1:730478240900:web:86276b81bf45f5c4570ed1",
    measurementId: "G-M06PYJ5S7Q",
  },
};
