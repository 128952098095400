import { Component, OnInit } from '@angular/core';
import { SidebarService } from '../../../services/sidebar.service';
import { TablesService } from '../../../services/tables.service';
import { ProductsService } from '../../../services/products.service';

@Component({
  selector: 'app-table-builder',
  templateUrl: './table-builder.component.html',
  styleUrl: './table-builder.component.scss',
})
export class TableBuilderComponent implements OnInit {
  constructor(
    public tablesService: TablesService,
    public sidebarService: SidebarService,
    public productsService: ProductsService
  ) {}

  ngOnInit(): void {
    this.productsService.fetchGetProducts().subscribe((data: any) => {
      const productName = this.productsService.productName();
      const productRes = data.find((product) => product.name === productName);
      this.tablesService.fetchTablesByProduct(productRes.id);
      this.sidebarService.expandedMenu('table_builder');
      this.sidebarService.updateDisabled(false);
    });
  }
}
