<div class="container">
  @if (productForm) {
  <form class="main" [formGroup]="productForm" (ngSubmit)="saveProduct()">
    @for (step of steppers; track $index) {
    <app-stepper
      [id]="step.id"
      [header]="step.header"
      [form]="productForm"
      [component]="step.component"
      (openApiKeyDialog)="generateKey()"
      (handleStepper)="handleStepper($event)"
    ></app-stepper>
    }
    <div class="action">
      <app-button
        type="submit"
        title="Save"
        [disabled]="!productForm.valid"
      ></app-button>
      @if(productForm.value.id){
      <div class="delete" (click)="openDialog()">Delete Product</div>
      }
    </div>
  </form>
  }
</div>
