<button
  [disabled]="disabled"
  [ngStyle]="{
    backgroundColor: disabled
      ? customBtnColor?.disabled
        ? customBtnColor?.disabled
        : '#9FA1A2'
      : customBtnColor?.bg,
    color: disabled ? 'white' : customBtnColor?.color,
    paddingInline: paddingInline || '2vw',
    border: !disabled && customBtnColor?.border,
    boxShadow: 'none',
    height: height,
    width: width
  }"
  (click)="onClick()"
  mat-raised-button
  [color]="!disabled && color"
  [type]="type === 'submit' ? 'submit' : 'button'"
>
  @if ( icon ) {
  <mat-icon>{{ icon }}</mat-icon>
  }
  {{ title }}
</button>
