<div class="container">
  @if (tableCreatedMode) {
  <app-back-button
    title="back"
    (action)="tableCreatedMode = false"
  ></app-back-button>
  }
  <div class="tableContainer">
    @if (tableCreatedMode) {
    <app-create-table></app-create-table>
    }@else { @if (this.tablesService.getTablesHistory()?.rows) {
    <app-table
      [rows]="this.tablesService.getTablesHistory().rows"
      [columns]="columns"
      [header]="header"
      [displayColumns]="displayColumns"
      [buttonDetails]="buttonDetails"
      [isDisabledButton]="isDisabledButton"
      (action)="AddProductDefinition()"
      (handleActionByName)="handleActionByName($event)"
      (handleRowClick)="handleRowClick($event)"
    ></app-table
    >} }
  </div>
</div>
