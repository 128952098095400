import { Injectable } from '@angular/core';
import { Observable, map, mergeMap, of } from 'rxjs';
import {
  Column,
  CreateTable,
  DynamicDataTable,
  DynamicTable,
  Row,
} from '../interfaces/table';
import { createColumnsToTableDesign, createTable } from '../utils/table';
import { ProductsService } from './products.service';
import { SchemaService } from './schema.service';
import { TablesService } from './tables.service';
import { ColumnsService } from './columns.service';

@Injectable({
  providedIn: 'root',
})
export class DynamicTablesService {
  constructor(
    private productService: ProductsService,
    private schemaService: SchemaService,
    private tablesService: TablesService,
    private columnsService: ColumnsService
  ) {}
  rows: DynamicDataTable;
  columns: Column[];

  getData(table: CreateTable, page: number, limit: number): Observable<any> {
    return this.schemaService.fetchGetSchemaName(table.schemaId).pipe(
      mergeMap((schema: any) => {
        return this.tablesService
          .fetchGetDynamicTables(schema.name, table.name, page, limit)
          .pipe(
            mergeMap((rows: DynamicDataTable) => {
              this.rows = rows;
              return this.tablesService.fetchGetColumnsByTableId(table.id).pipe(
                mergeMap((columnsRes: Column[]) => {
                  return createColumnsToTableDesign(
                    columnsRes,
                    this.columnsService,
                    table.name
                  ).pipe(
                    map(() => {
                      const columnsDynamic =
                        this.columnsService.getColumnsForDynamicTable();

                      const tableRowColumn = createTable(
                        table.name,
                        this.rows.data,
                        columnsDynamic
                      );
                      this.tablesService.setDynamicTable(tableRowColumn);
                      this.tablesService.setTotalCount(this.rows.totalCount);
                    })
                  );
                })
              );
            })
          );
      })
    );
  }

  fetchGetDynamicTable(
    isManagement: boolean = false,
    tableName: string,
    columnDisplay: string[] = [],
    filterRows: Row[] = [],
    page: number,
    limit: number
  ) {
    let table;
    if (isManagement) {
      table = this.productService
        .getManagementTables()
        .find((table) => table.name === tableName);
    }
    if (!isManagement) {
      table = this.productService
        .getReportTables()
        .find((table) => table.name === tableName);
    }

    let tempColumns: Column[];
    if (columnDisplay.length > 0) {
      return of(
        createColumnsToTableDesign(
          this.columns,
          this.columnsService,
          tableName
        ).subscribe(() => {
          const columnsDynamic =
            this.columnsService.getColumnsForDynamicTable();
          if (tempColumns.length > 0) {
            tempColumns = columnsDynamic.filter((col) =>
              columnDisplay.includes(col.name)
            );
          }
          tempColumns.unshift({
            name: 'id',
            title: 'ID',
            type: 'number',
          });
          const tableRowColumn: DynamicTable = createTable(
            tableName,
            filterRows,
            tempColumns
          );
          this.tablesService.setDynamicTable(tableRowColumn);
        })
      ).subscribe();
    } else {
      return this.getData(table, page, limit).subscribe((columnsRes) => {
        this.columns = columnsRes;
      });
    }
  }
}
