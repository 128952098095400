import { Component, OnInit } from '@angular/core';
import { ProductsService } from '../../../services/products.service';
import { SidebarService } from '../../../services/sidebar.service';
import { MASTER_DATA, REPORT, SYSTEM_ROLES } from '../../../types/constant';
import { SIDEBAR_MENU_ADMIN } from '../../../mockData/menu';
import { UserAuthService } from '../../../services/userAuth.service';
import { UsersService } from '../users/users.service';
import { CreateTable } from '../../../interfaces/table';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrl: './admin-layout.component.scss',
})
export class AdminLayoutComponent implements OnInit {
  constructor(
    public productsService: ProductsService,
    private sidebarService: SidebarService,
    public userAuthService: UserAuthService,
    public userService: UsersService
  ) {}

  managementTables = [];
  reportTables = [];
  display: boolean = false;
  ngOnInit(): void {
    this.sidebarService.sidebarMenu.set(SIDEBAR_MENU_ADMIN);
    const userId = this.userAuthService.user().id;
    if (this.userAuthService.user().roleId === SYSTEM_ROLES.superAdmin) {
      const productId: number = this.userAuthService.getUserProductLStorage();
      if (productId) {
        this.productsService.fetchGetProductByProductId(productId).add(() => {
          this.getData(productId);
        });
      }
    } else {
      this.userService.fetchGetUserProduct(userId).add(() => {
        const userProduct = this.userService.getUserProduct();
        this.getData(userProduct.id);
      });
    }
  }

  getData(productId) {
    this.productsService.fetchProductTablesByProductId(productId).add(() => {
      this.display = true;
      this.reportTables = this.productsService.getReportTables();
      this.managementTables = this.productsService.getManagementTables();
      this.sidebarService.addTablesToSideMenu(this.reportTables, REPORT);
      this.sidebarService.addTablesToSideMenu(
        this.managementTables.filter((table: CreateTable) => table.isVisible),
        MASTER_DATA
      );
    });
  }
}
