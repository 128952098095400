import { lightBlue, lightGreen, lightYellow } from '../../assets/colors';
import { Box } from '../interfaces/components';
import { Product, ProductList, ProductSummary } from '../interfaces/products';
import { Column } from '../interfaces/table';

export const columnsSuper: Column[] = [
  {
    name: 'title',
    title: 'Product Name',
    type: 'text',
  },
  {
    name: 'systemAccess',
    title: 'System users',
    type: 'text',
  },
  {
    name: 'createdDate',
    title: 'Creation Date',
    type: 'date',
  },
  {
    name: 'mt',
    title: 'MT',
    type: 'number',
  },
  {
    name: 'rt',
    title: 'RT',
    type: 'number',
  },
  {
    name: 'status',
    title: 'Status',
    type: 'status',
    style: true,
    options: [
      {
        value: false,
        title: 'Non Active',
      },
      { value: true, title: 'isActive' },
    ],
  },
  {
    name: 'action',
    title: 'Action',
    type: 'action',
    quick_actions: [
      { name: 'DISPLAY_PRODUCT_DETAILS', icon: 'eye1' },
      // { name: 'DISPLAY_PRODUCT_DETAILS', icon: 'save' },
    ],
  },
];

export const boxesDataAdmin: Box[] = [
  {
    name: 'avgUsageTime',
    title: 'AVG usage time ',
    value: 0,
    color: lightBlue,
    subText: 'M',
  },
  {
    name: 'totalLogins',
    title: 'Total logins ',
    value: 0,
    color: lightGreen,
  },
  {
    name: 'totalUsers',
    title: 'Total users',
    value: 0,
    color: lightYellow,
  },
];
