import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { UserAuthService } from "../../services/userAuth.service";

@Component({
  selector: "app-user-setting",
  templateUrl: "./user-setting.component.html",
  styleUrl: "./user-setting.component.scss",
})
export class UserSettingComponent implements OnInit {
  constructor(
    public authService: AuthService,
    public userAuthService: UserAuthService
  ) {}
  ngOnInit(): void {
    const user = this.userAuthService.getUser()?.externalUserId;
    const userSplit = user.split(".");
    this.userName = userSplit[0];
  }
  menuOpened: boolean = false;
  userName;
  logout() {
  this.authService.logout();
  }
}
