import { Content } from '../services/alert.service';
export const PRODUCT_DETAILS = 'Product Details';
export const USERS_ADMIN = 'Users (Admin)';
export const AWS_COGNITO = 'AWS Cognito';
export const GOOGLE_ANALYTICS = 'Google Analytics';
export const MASTER_DATA = 'Master Data';
export const MASTER_DATA_URL = 'master_data';
export const REPORT = 'Report';
export const ADMIN_ROLE = 2;
export const BOOLEAN_COLUMN = 7;
export const FILE_COLUMN = 4;
export const PAGINATION_SIZE = 5;
export const BYTES = 1024;
export const LOOKUP_ID = -119;

export const DATA_TYPE = {
  Integer: 'number',
  DECIMAL: 'decimal',
  VARCHAR: 'text',
  file: 'file',
  JSON: 'json',
  bigint: 'date',
  TEXT: 'long text',
  boolean: 'boolean',
  lookupTable: 'lookup Table',
};
export const STATUS = {
  active: 'Active',
  inactive: 'Non Active',
  deleted: 'Deleted',
};
export const ACTIONS = {
  route: 'ROUTE',
  ref: 'REF',
};

export const ROLES = {
  1: 'SuperAdmin',
  2: 'Admin',
  3: 'Viewer',
};
export const SYSTEM_ROLES = {
  superAdmin: 'SuperAdmin',
  admin: 'Admin',
  viewer: 'Viewer',
};
export const SUCCESS_ADD_PRODUCT: Content = {
  title: 'Adding a product',
  text: 'The product was created successfully',
};
export const SUCCESS_UPDATE_PRODUCT: Content = {
  title: 'Updating a product',
  text: 'The product was updated successfully',
};
export const SUCCESS_ADD_COLUMNS: Content = {
  title: 'Adding a columns',
  text: 'The columns was created successfully',
};
export const SUCCESS_UPDATE_COLUMNS: Content = {
  title: 'Updating a columns',
  text: 'The columns was updated successfully',
};
export const SUCCESS_DOWNLOAD: Content = {
  title: 'Download file',
  text: 'The file downloaded successfully',
};
export const SUCCESS_DELETED: Content = {
  title: 'Deleted Product',
  text: 'The product deleted successfully',
};
export const ERROR_VALIDATION: Content = {
  title: 'Error validation',
  text: 'Aws cognito user pool does not exist ,Please change User pool details',
};
export const ERROR_ADDING_USER: Content = {
  title: 'Error adding user',
  text: 'It is not possible to add a user when there are no roles, please go to add roles',
};
export const ERROR__VALIDATION_UPLOAD: Content = {
  title: 'Error validation',
  text: 'The file is larger than 5Mb',
};
export const DELETED_SUCCESS = 'Deleted successfully';
export const UPDATED_SUCCESS = 'Updated successfully';
export const ADDING_SUCCESS = 'Adding successfully';
