import { Configuration } from "@azure/msal-browser";
import { environment } from "../../environments/environment";

export const mslConfig: Configuration = {
  auth: {
    clientId: environment.adalConfig.clientId,
    authority: `https://login.microsoftonline.com/${environment.adalConfig.tenant}`,
    //localhost
    // redirectUri: 'http://localhost:4200',
    //dev
    redirectUri: "https://fs-cms-dev.appsc.icldig.icl-group.com/",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
  system: {
    navigateFrameWait: 0,
  },
};
