import { Menu } from '../interfaces/menu';
import {
  AWS_COGNITO,
  GOOGLE_ANALYTICS,
  MASTER_DATA,
  MASTER_DATA_URL,
  PRODUCT_DETAILS,
  REPORT,
  USERS_ADMIN,
} from '../types/constant';

export const SIDEBAR_MENU_SUPER_ADMIN: Menu[] = [
  {
    id: 1,
    name: 'All products',
    url: 'products',
    icon: 'table',
    isExpanded: true,
    disabled: false,
    children: [],
  },
  {
    id: 2,
    name: 'Settings',
    icon: 'settings',
    url: '',
    isExpanded: false,
    disabled: true,
    action: 'REF',
    children: [
      {
        id: 1,
        title: PRODUCT_DETAILS,
        name: PRODUCT_DETAILS,
        icon: 'home',
        selected: true,
      },
      {
        id: 2,
        title: USERS_ADMIN,
        name: USERS_ADMIN,
        icon: 'home',
        selected: false,
      },
      {
        id: 3,
        title: AWS_COGNITO,
        name: AWS_COGNITO,
        selected: false,
      },
      {
        id: 4,
        title: GOOGLE_ANALYTICS,
        name: GOOGLE_ANALYTICS,
        selected: false,
      },
    ],
  },
  {
    id: 3,
    name: 'Table Builder',
    icon: 'builder',
    url: 'table_builder',
    isExpanded: false,
    disabled: true,
    children: [],
  },
  {
    id: 4,
    name: 'Hidden Tables',
    icon: 'hidden-tables',
    url: 'hidden_tables',
    isExpanded: false,
    disabled: true,
    children: [],
    action: 'ROUTE',
  },
  {
    id: 5,
    name: 'Queries',
    icon: 'queries',
    url: 'queries',
    isExpanded: false,
    disabled: true,
    children: [],
  },
];
export const SIDEBAR_MENU_ADMIN: Menu[] = [
  {
    id: 5,
    name: 'Products Page',
    url: 'products_page',
    icon: 'table',
    isExpanded: true,
    disabled: false,
    children: [],
  },
  {
    id: 6,
    name: MASTER_DATA,
    icon: 'builder',
    url: MASTER_DATA_URL,
    isExpanded: false,
    disabled: false,
    children: [],
    action: 'ROUTE',
  },
  {
    id: 7,
    name: REPORT,
    icon: 'queries',
    url: 'report',
    isExpanded: false,
    disabled: false,
    children: [],
    action: 'ROUTE',
  },
  {
    id: 8,
    name: 'Users',
    icon: 'settings',
    url: 'users',
    isExpanded: false,
    disabled: false,
    children: [
      {
        id: 1,
        title: 'Users',
        name: 'users',
        url: 'users',
      },
      {
        id: 2,
        title: 'Roles',
        name: 'roles',
        url: 'roles',
      },
    ],
    action: 'ROUTE',
  },
];
