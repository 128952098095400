<div class="container">
  @if (createColumnForm) {
  <form class="form" [formGroup]="createColumnForm" (ngSubmit)="onSubmit()">
    <div class="create-columns">
      <div class="inputs-container">
        <div class="inputs">
          <app-input
            [form]="createColumnForm"
            title="Column name"
            formName="name"
          ></app-input>
          <app-dropdown
            [form]="createColumnForm"
            [options]="columnTypeOptions"
            title="Column type"
            formName="columnType"
            (onTrigger)="updateValueTrigger($event)"
            [valueTrigger]="valueTrigger"
          ></app-dropdown>
          @if (!isDisplayDropdown) {
          <div class="action">
            <app-button
              height="40px"
              [customBtnColor]="customBtnColor"
              paddingInline="1.5vw"
              type="submit"
              title="Add"
              [disabled]="!createColumnForm.valid"
            ></app-button>
          </div>
          }
        </div>
        @if (isDisplayDropdown) {
        <div class="inputs">
          @if (isDisplayDropdown === 'lookup Table' || isDisplayDropdown
          ==="Lookup column") {
          <app-dropdown
            [form]="createColumnForm"
            [options]="managementsTablesOptions"
            title="Lookup table"
            (onTrigger)="updateValueTrigger($event)"
            formName="lookupTable"
          ></app-dropdown>
          <app-dropdown
            [form]="createColumnForm"
            [options]="managementsColumnsOptions"
            title="Lookup column"
            formName="lookupColumn"
          ></app-dropdown>
          }@else if(isDisplayDropdown ==='boolean') {
          <app-input
            [form]="createColumnForm"
            title="True Title"
            formName="trueTitle"
            [maxLength]="inputLength"
          ></app-input>
          <app-input
            [form]="createColumnForm"
            title="False Title"
            formName="falseTitle"
            [maxLength]="inputLength"
          ></app-input>
          }
          <div class="action">
            <app-button
              [customBtnColor]="customBtnColor"
              height="40px"
              paddingInline="1.5vw"
              type="submit"
              title="Add"
              [disabled]="!createColumnForm.valid"
            ></app-button>
          </div>
        </div>
        }
      </div>
      <div class="required-checkbox">
        <mat-checkbox color="primary" formControlName="required"></mat-checkbox>
        <div class="header">Required</div>
      </div>
    </div>
  </form>
  }
  <div class="columns-table">
    <div class="header">{{ tableTitle }}</div>
    <div
      class="box-column"
      cdkDropListOrientation="horizontal"
      cdkDropList
      (cdkDropListDropped)="drop($event)"
    >
      <div class="row">
        <div class="name-col">id</div>
      </div>
      @for (col of columnsToAdd; track $index) { @if (isEditMode &&
      editableColumnDetails.index === $index ) {
      <div class="row">
        <input
          class="input"
          type="text"
          [(ngModel)]="editableColumnDetails.title"
        />
      </div>
      }@else {
      <div
        cdkDrag
        [ngClass]="{
          isHoverBack: col['name'] === hoveredColByName && !isEditMode
        }"
        class="row box"
        (mouseover)="onMouseOver(col)"
        (mouseout)="onMouseOut()"
      >
        <div class="custom-placeholder" *cdkDragPlaceholder></div>
        <div class="name-col">{{ col.title }}</div>
        <div
          [ngClass]="{
            isHover: col['name'] === hoveredColByName && !isEditMode
          }"
          class="action"
        >
          <mat-icon class="icon-button" svgIcon="edit" (click)="editCol(col)" />
          <mat-icon
            class="icon-button"
            svgIcon="trash"
            (click)="deleteCol(col)"
          />
        </div>
      </div>
      } }
    </div>
    @if (isEditMode) {
    <div class="actions">
      <app-button
        title="Save"
        color="accent"
        (click)="saveChangeColumn()"
      ></app-button>
      <app-button title="Cancel" (click)="cancelChangeColumn()"></app-button>
    </div>
    }
  </div>
  <div class="con-table">
    <div class="action-save">
      <app-button
        [disabled]="columnsToAdd.length === 0"
        title="Save table"
        (action)="saveTable()"
      ></app-button>
    </div>
  </div>
</div>
