<div [formGroup]="jsonForm">
  <div class="container">
    <div class="container-header">
      <div mat-dialog-title class="title">{{ data.title }}</div>
      <mat-icon
        class="icon-button"
        svgIcon="cancel"
        mat-dialog-close
      ></mat-icon>
    </div>
    <div class="fields" mat-dialog-content>
      <mat-form-field appearance="outline" class="text">
        <input matInput formControlName="name" placeholder="Json Name..." />
      </mat-form-field>

      <mat-form-field appearance="outline" class="textarea">
        <textarea
          class="font"
          placeholder="Place Json Here..."
          matInput
          formControlName="content"
          rows="8"
        ></textarea>

        <mat-error>{{
          checkForErrorsIn(jsonForm.get("content"), "content")
        }}</mat-error>
      </mat-form-field>
      <div mat-dialog-actions class="actions-done">
        <button
          [ngClass]="{
            disabled: !jsonForm.valid
          }"
          class="done"
          (click)="formattedJson(jsonForm.get('content'))"
          mat-button
          cdkFocusInitial
          [disabled]="!jsonForm.valid"
        >
          Format
        </button>

        <button
          [ngClass]="{
            disabled: !jsonForm.valid
          }"
          class="done"
          [mat-dialog-close]="{ val: jsonForm.value, isSave: true }"
          mat-button
          cdkFocusInitial
          [disabled]="!jsonForm.valid"
        >
          {{ data.titleSubmit || "Yes" }}
        </button>
      </div>
    </div>
  </div>
</div>
