import { Component, Input } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { checkForErrorsIn } from '../../../utils/validations';

@Component({
  selector: 'app-decimal',
  templateUrl: './decimal.component.html',
  styleUrl: './decimal.component.scss',
})
export class DecimalComponent {
  @Input() value: string;
  @Input() editMode: boolean;
  @Input() form: FormGroup;
  @Input() formName: string = '';

  checkForErrorsIn(formControl: AbstractControl, field: string): string {
    return checkForErrorsIn(formControl, field);
  }
}
