@switch (type) { @case ("text") {
<app-text
  [value]="value"
  [editMode]="editMode"
  [form]="form"
  [formName]="formName"
  [meta]="meta"
></app-text>
}@case ("status") {
<app-text
  [value]="value"
  [editMode]="editMode"
  [form]="form"
  [formName]="formName"
  [meta]="meta"
>
</app-text>
} @case ("boolean") {
<app-boolean
  [value]="value"
  [editMode]="editMode"
  [form]="form"
  [formName]="formName"
  [meta]="meta"
>
</app-boolean>
} @case ("boolean2") {
<app-boolean2
  [value]="value"
  [editMode]="editMode"
  [form]="form"
  [formName]="formName"
  [meta]="meta"
>
</app-boolean2>
} @case ("bigint") {
<app-date
  [value]="value"
  [editMode]="editMode"
  [form]="form"
  [formName]="formName"
>
</app-date>
} @case ("date") {
<app-date
  [value]="value"
  [editMode]="editMode"
  [form]="form"
  [formName]="formName"
>
</app-date>
} @case ("number") {
<app-number
  [value]="value"
  [editMode]="editMode"
  [form]="form"
  [formName]="formName"
>
</app-number>
} @case ("decimal") {
<app-decimal
  [value]="value"
  [editMode]="editMode"
  [form]="form"
  [formName]="formName"
>
</app-decimal>
} @case ("long text") {
<app-text-popup
  [value]="value"
  [editMode]="editMode"
  [form]="form"
  [formName]="formName"
>
</app-text-popup>
}@case ("json") {
<app-json
  [value]="value"
  [editMode]="editMode"
  [form]="form"
  [formName]="formName"
  [meta]="meta"
>
</app-json>
}@case ("file") {
<app-file
  [value]="value"
  [editMode]="editMode"
  [form]="form"
  [formName]="formName"
  [meta]="meta"
>
</app-file>
} @case ("select") {
<app-select
  [value]="value"
  [editMode]="editMode"
  [form]="form"
  [formName]="formName"
  [meta]="meta"
>
</app-select>
} @default { Default case. } }
