import { Column } from '../interfaces/table';

export const columnsHistoryTable: Column[] = [
  {
    name: 'title',
    title: 'Table Name',
    type: 'text',
  },
  {
    name: 'readOnly',
    title: 'Table Type',
    type: 'boolean2',
    options: [
      {
        value: false,
        title: 'management',
      },
      { value: true, title: 'report' },
    ],
  },
  {
    name: 'numOfColumn',
    title: 'Columns',
    type: 'number',
  },
  {
    name: 'isVisible',
    title: 'Status',
    type: 'boolean2',
    options: [
      {
        value: false,
        title: 'Hidden',
      },
      { value: true, title: 'Visible' },
    ],
  },
  {
    name: 'action',
    title: 'Action',
    type: 'action',
    quick_actions: [{ name: 'DELETE_TABLE', icon: 'trash' }],
  },
];
