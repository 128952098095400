<div
  class="container"
  [ngStyle]="{
    backgroundColor: box.color
  }"
>
  <div class="title">{{ box.title }}</div>
  <div class="text">
    <div class="value">{{ box.value.toLocaleString() }}</div>
    @if (box.subText && box.value) {
    <div class="sub-text">{{ box.subText }}</div>
    }
  </div>
</div>
