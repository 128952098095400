<div class="card-body p-0">
  <div class="vector-map">
    <dx-vector-map
      id="vector-map"
      [bounds]="[0, 0, 0, 0]"
      (onClick)="click($event)"
    >
      <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip">
        <dxo-font color="#fff"></dxo-font>
        <dxo-border [visible]="false"></dxo-border>
      </dxo-tooltip>
      <dxi-layer [dataSource]="worldMap" [customize]="customizeLayers">
      </dxi-layer>
      <dxo-size [height]="200" [width]="400"> </dxo-size>
    </dx-vector-map>
  </div>
</div>
