import { Component, Input } from '@angular/core';
import { Box } from '../../interfaces/components';

@Component({
  selector: 'app-dashboard-box',
  templateUrl: './dashboard-box.component.html',
  styleUrl: './dashboard-box.component.scss',
})
export class DashboardBoxComponent {
  @Input() box: Box;
}
