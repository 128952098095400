import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SidebarService } from '../../../services/sidebar.service';
import { RolesService } from './roles.service';
import { ActionRow, Column, Row } from '../../../interfaces/table';
import { Button } from '../../../interfaces/components';
import { PAGINATION_SIZE } from '../../../types/constant';
import { emptyRole } from '../../../mockData/roles';
import { changeRowMode } from '../../../utils/form';
import { UsersService } from '../users/users.service';
import { Product } from '../../../interfaces/products';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrl: './roles.component.scss',
})
export class RolesComponent implements OnInit {
  constructor(
    private sidebarService: SidebarService,
    public rolesService: RolesService,
    public userService: UsersService
  ) {}

  rows: Row[] = [];
  columns: Column[] = [];
  header: string;
  displayColumns: string[] = [];
  buttonDetails: Button = {
    title: 'Add New Role',
  };
  isAddRow = false;
  isEditMode = false;
  rowAddingIndex = 0;
  isDisabledButton = false;
  productId;
  form;
  userProduct: Product;
  indexEditRow: number = PAGINATION_SIZE;
  ngOnInit(): void {
    this.sidebarService.expandedMenu('users');
    this.sidebarService.drawTextMenu('Users', 'roles', true);
    this.userProduct = this.userService.getUserProduct();
    this.rolesService.fetchRoles(this.userProduct.id).add(() => {
      const { rows, columns, header } = this.rolesService.getRolesTable();
      this.rows = rows;
      this.columns = columns;
      this.header = header;
      this.displayColumns = columns.map((item) => item.name);
      this.rolesService.setRolesForm(columns, null);
      this.form = this.rolesService.getRolesForm();
    });
  }
  addEmptyRole() {
    this.rows = this.rolesService.getRolesTable().rows;
    this.rows.splice(this.indexEditRow - 1, 0, emptyRole);
    this.isAddRow = !this.isAddRow;
    this.rowAddingIndex =
      this.rows.length > this.indexEditRow
        ? this.indexEditRow - 1
        : this.rows.length - 1;
    this.isDisabledButton = true;
  }
  handleActionByName(event: ActionRow) {
    if (!this.isAddRow && !this.isEditMode)
      if (event.actionName === 'EDIT_QUERY') {
        this.isDisabledButton = true;
        this.rolesService.setRolesForm(this.columns, event.row);
        this.form = this.rolesService.getRolesForm();
        this.isEditMode = true;
        changeRowMode(this.rolesService.rolesTables, event.row.id, true);
      } else if (event.actionName === 'DELETE_QUERY') {
        this.rolesService.fetchDeleteRole(event.row, this.userProduct.id);
      }
  }
  handleRow(event: ActionRow) {
    const form = this.rolesService.getRolesForm();
    const data = { ...form.value, description: null };
    switch (event.actionName) {
      case 'EDIT':
        this.rolesService.updateRolesTable(data, this.userProduct.id);
        break;
      case 'SAVE':
        this.rolesService.fetchAddRole(data, this.userProduct.id);
        this.resetEditMode();
        break;
      case 'CANCEL_ADD':
        this.resetEditMode();
        break;
      case 'CANCEL_EDIT':
        changeRowMode(this.rolesService.rolesTables, event.row.id, false);
        break;
      default:
        break;
    }
    this.rolesService.setRolesForm(this.columns, null);
    this.isEditMode = false;
    this.isDisabledButton = false;
    this.form = this.rolesService.getRolesForm();
  }
  resetEditMode() {
    const rows = this.rolesService.getRolesTable().rows;
    this.isDisabledButton = false;
    this.isAddRow = !this.isAddRow;
    rows.splice(this.rowAddingIndex, 1);
  }
  handleChangePage(pageRes) {
    this.indexEditRow = pageRes.pageSize * (pageRes.pageIndex + 1);
    this.isEditMode = false;
    this.isAddRow = false;
  }
}
