<div [formGroup]="form">
  <div class="field">
    <div class="title">{{ title }}</div>
    <mat-form-field appearance="outline">
      <input
        [appAutofocus]="isFocus"
        matInput
        #val
        formControlName="{{ formName }}"
        class="container"
        [maxlength]="maxLength"
        (input)="maxLength && changeValue(val.value)"
        (focus)="this.handleStepper.emit()"
      />
      @if (maxLength) {
      <div matTextSuffix class="suffix">
        {{ displayLength }}
      </div>
      }
      <mat-error>{{ checkForErrorsIn(form.get(formName), title) }}</mat-error>
    </mat-form-field>
  </div>
</div>
