import { Component, Input } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Meta } from '../../../interfaces/table';
import { checkForErrorsIn } from '../../../utils/validations';
import { EditJsonDialogComponent } from '../../dialogs/edit-json-dialog/edit-json-dialog.component';

@Component({
  selector: 'app-json',
  templateUrl: './json.component.html',
  styleUrl: './json.component.scss',
})
export class JsonComponent {
  constructor(public dialog: MatDialog) {}

  @Input() editMode: boolean;
  @Input() value: any;
  @Input() form: FormGroup;
  @Input() formName: string = '';
  @Input() meta: Meta;

  checkForErrorsIn(formControl: AbstractControl, field: string): string {
    return checkForErrorsIn(formControl, field);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(EditJsonDialogComponent, {
      data: {
        value: this.form.value.json ||
          this.value || {
            name: '',
            content: '{}',
          },
        title: `Edit ${this.formName.split('_')[0]}`,
        titleSubmit: 'save',
        name: this.form.get(this.formName).value?.name || '',
        content: this.form.get(this.formName).value?.content || '{}',
      },
      width: '600px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.isSave) {
        this.form.patchValue({
          [this.formName]: result.val,
        });
        this.value = result.val;
      }
    });
  }
}
