import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http'; // Import
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';

import {
  CdkDrag,
  CdkDragPlaceholder,
  CdkDropList,
  DragDropModule,
} from '@angular/cdk/drag-drop';
import { JsonPipe, NgTemplateOutlet } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import {
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
} from '@azure/msal-angular'; // Import MsalInterceptor
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import {
  NgbDatepickerModule,
  NgbInputDatepicker,
  NgbModalModule,
} from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbModule } from '@syncfusion/ej2-angular-navigations';
import { DxPieChartModule, DxVectorMapModule } from 'devextreme-angular';
import { AzureAppConfig } from './azure.app.config';
import { BooleanComponent } from './components/cells/boolean/boolean.component';
import { CellsComponent } from './components/cells/cells.component';
import { DateComponent } from './components/cells/date/date.component';
import { DecimalComponent } from './components/cells/decimal/decimal.component';
import { FileComponent } from './components/cells/file/file.component';
import { JsonComponent } from './components/cells/json/json.component';
import { NumberComponent } from './components/cells/number/number.component';
import { SelectComponent } from './components/cells/select/select.component';
import { TextPopupComponent } from './components/cells/text-popup/text-popup.component';
import { TextComponent } from './components/cells/text/text.component';
import { DashboardBoxComponent } from './components/dashboard-box/dashboard-box.component';
import { EditJsonDialogComponent } from './components/dialogs/edit-json-dialog/edit-json-dialog.component';
import { EditTextDialogComponent } from './components/dialogs/edit-text-dialog/edit-text-dialog.component';
import { FileDialogComponent } from './components/dialogs/file-dialog/file-dialog.component';
import { MenuComponent } from './components/menu/menu.component';
import { AdminLayoutComponent } from './components/pages/admin-layout/admin-layout.component';
import { HiddenTablesComponent } from './components/pages/hidden-tables/hidden-tables.component';
import { TableViewComponent } from './components/pages/hidden-tables/table-view/table-view.component';
import { MasterDataLayoutComponent } from './components/pages/master-data-layout/master-data-layout.component';
import { MasterDataComponent } from './components/pages/master-data/master-data.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { ProductsLayoutComponent } from './components/pages/products-layout/products-layout.component';
import { AnalyticsComponent } from './components/pages/products-page/analytics/analytics.component';
import { VectorComponent } from './components/pages/products-page/analytics/vector/vector.component';
import { ProductsPageComponent } from './components/pages/products-page/products-page.component';
import { ProductsComponent } from './components/pages/products/products.component';
import { QueriesComponent } from './components/pages/queries/queries.component';
import { FilterResultComponent } from './components/pages/reports/filter-result/filter-result.component';
import { ReportsComponent } from './components/pages/reports/reports.component';
import { RolesComponent } from './components/pages/roles/roles.component';
import { AwsCognitoComponent } from './components/pages/settings/aws-cognito/aws-cognito.component';
import { GoogleAnalyticsComponent } from './components/pages/settings/google-analytics/google-analytics.component';
import { ProductDetailsComponent } from './components/pages/settings/product-details/product-details.component';
import { SettingsComponent } from './components/pages/settings/settings.component';
import { UsersAdminComponent } from './components/pages/settings/users-admin/users-admin.component';
import { CreateColumnsComponent } from './components/pages/table-builder/create-columns/create-columns.component';
import { CreateTableComponent } from './components/pages/table-builder/create-table/create-table.component';
import { TableBuilderComponent } from './components/pages/table-builder/table-builder.component';
import { TableHistoryComponent } from './components/pages/table-builder/table-history/table-history.component';
import { TabsDetailsComponent } from './components/pages/tabs-details/tabs-details.component';
import { UsersComponent } from './components/pages/users/users.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TableComponent } from './components/table/table.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { UserSettingComponent } from './components/user-setting/user-setting.component';
import { BackButtonComponent } from './components/widgets/back-button/back-button.component';
import { ButtonToggleComponent } from './components/widgets/button-toggle/button-toggle.component';
import { ButtonComponent } from './components/widgets/button/button.component';
import { ChipComponent } from './components/widgets/chip/chip.component';
import { DatepickerRangeComponent } from './components/widgets/datepicker-range/datepicker-range.component';
import { DatepickerComponent } from './components/widgets/datepicker/datepicker.component';
import { DialogComponent } from './components/widgets/dialog/dialog.component';
import { DropdownComponent } from './components/widgets/dropdown/dropdown.component';
import { InputComponent } from './components/widgets/input/input.component';
import { RadioButtonComponent } from './components/widgets/radio-button/radio-button.component';
import { StepperComponent } from './components/widgets/stepper/stepper.component';
import { TextareaComponent } from './components/widgets/textarea/textarea.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { MaterialModule } from './material/material.module';
import { Boolean2Component } from './components/cells/boolean2/boolean2.component';
import { errorInterceptorInterceptor } from './error-interceptor.interceptor';
import { ApiKeyDialogComponent } from './components/dialogs/api-key-dialog/api-key-dialog.component';
import { mslConfig } from './services/auth.config';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  imports: [
    DragDropModule,
    BrowserModule,
    MaterialModule,
    BreadcrumbModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    JsonPipe,
    NgbModalModule,
    NgbDatepickerModule,
    HttpClientModule,
    NgTemplateOutlet,
    ReactiveFormsModule,
    CdkDropList,
    CdkDragPlaceholder,
    CdkDrag,
    NgbInputDatepicker,
    DxVectorMapModule,
    DxPieChartModule,
    MsalModule.forRoot(
      new PublicClientApplication(mslConfig),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: [AzureAppConfig.clientId + '/.default'],
        },
      },
      {
        interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
        protectedResourceMap: new Map([
          ['https://graph.microsoft.com/v1.0/me', ['user.read']],
        ]),
      }
    ),
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    SidebarComponent,
    ToolbarComponent,
    MenuComponent,
    ProductsComponent,
    SettingsComponent,
    TableBuilderComponent,
    QueriesComponent,
    DashboardBoxComponent,
    UserSettingComponent,
    StepperComponent,
    ProductDetailsComponent,
    AwsCognitoComponent,
    GoogleAnalyticsComponent,
    ButtonComponent,
    UsersAdminComponent,
    ChipComponent,
    TableComponent,
    CellsComponent,
    BooleanComponent,
    DateComponent,
    NumberComponent,
    TextComponent,
    InputComponent,
    TextareaComponent,
    CreateColumnsComponent,
    DropdownComponent,
    DialogComponent,
    NotFoundComponent,
    ProductsLayoutComponent,
    TabsDetailsComponent,
    ProductsPageComponent,
    MasterDataComponent,
    ReportsComponent,
    UsersComponent,
    DatepickerComponent,
    RadioButtonComponent,
    TextPopupComponent,
    EditTextDialogComponent,
    ButtonToggleComponent,
    TableHistoryComponent,
    CreateTableComponent,
    HiddenTablesComponent,
    TableViewComponent,
    BackButtonComponent,
    JsonComponent,
    EditJsonDialogComponent,
    DecimalComponent,
    FileComponent,
    FileDialogComponent,
    AdminLayoutComponent,
    MasterDataLayoutComponent,
    RolesComponent,
    FilterResultComponent,
    DatepickerRangeComponent,
    SelectComponent,
    AnalyticsComponent,
    VectorComponent,
    AutofocusDirective,
    Boolean2Component,
    ApiKeyDialogComponent,
  ],

  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: MsalInterceptor,
    //   multi: true,
    // },
    // {
    //   provide: ErrorHandler,
    //   useClass: GlobalErrorHandler,
    // },

    provideHttpClient(withInterceptors([errorInterceptorInterceptor])),

    MsalGuard,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
