import { Component, OnInit } from '@angular/core';
import { Button } from '../../../interfaces/components';
import { ActionRow, Column, Row } from '../../../interfaces/table';
import { emptyQuery } from '../../../mockData/queries';
import { ProductsService } from '../../../services/products.service';
import { QueriesService } from '../../../services/queries.service';
import { PAGINATION_SIZE } from '../../../types/constant';
import { generateName } from '../../../utils/strings';

@Component({
  selector: 'app-queries',
  templateUrl: './queries.component.html',
  styleUrl: './queries.component.scss',
})
export class QueriesComponent implements OnInit {
  constructor(
    public queryService: QueriesService,
    private productsService: ProductsService
  ) {}

  rows: Row[] = null;
  columns: Column[] = [];
  header: string;
  displayColumns: string[] = [];
  buttonDetails: Button = {
    title: 'Add',
    icon: 'add',
  };
  isAddRow = false;
  isEditMode = false;
  rowAddingIndex = 0;
  isDisabledButton = false;
  productId;
  form;
  indexEditRow: number = PAGINATION_SIZE;

  ngOnInit(): void {
    const { columns, header } = this.queryService.getQueriesList();
    this.columns = columns;
    this.header = header;
    this.displayColumns = columns.map((item) => item.name);
    this.productId = this.productsService.productId();
    this.queryService.fetchQueries(this.productId).add(() => {
      this.rows = this.queryService.getQueriesList().rows;
    });
    this.queryService.setQueryForm(columns, null);
    this.form = this.queryService.getQueryForm();
  }

  addEmptyQuery() {
    this.rows = this.queryService.getQueriesList().rows;
    this.isAddRow = !this.isAddRow;
    this.rows.splice(this.indexEditRow - 1, 0, emptyQuery);
    this.rowAddingIndex =
      this.rows.length > this.indexEditRow
        ? this.indexEditRow - 1
        : this.rows.length - 1;
    this.isDisabledButton = true;
  }

  handleActionByName(event: ActionRow) {
    if (!this.isAddRow && !this.isEditMode)
      if (event.actionName === 'EDIT_QUERY') {
        this.isDisabledButton = true;
        this.queryService.setQueryForm(this.columns, event.row);
        this.form = this.queryService.getQueryForm();
        this.isEditMode = true;
        this.queryService.changeRowMode(event.row.id, true);
      } else if (event.actionName === 'DELETE_QUERY') {
        this.queryService.fetchDeleteQuery(event.row.id);
      }
  }
  handleRow(event: ActionRow) {
    const form = this.queryService.getQueryForm();
    const query = {
      ...form.value,
      productId: this.productId,
      name: `${generateName(form.value.title)}_${this.productId}`,
    };
    switch (event.actionName) {
      case 'EDIT':
        form.value.editMode = false;
        this.queryService.updateQueryList(query);
        break;
      case 'SAVE':
        this.queryService.fetchAddQuery(query);
        this.resetEditMode();
        break;
      case 'CANCEL_ADD':
        this.resetEditMode();
        break;
      case 'CANCEL_EDIT':
        this.queryService.changeRowMode(event.row.id, false);
        break;
      default:
        break;
    }
    this.queryService.setQueryForm(this.columns, null);
    this.isEditMode = false;
    this.isDisabledButton = false;
    this.form = this.queryService.getQueryForm();
  }
  resetEditMode() {
    const rows = this.queryService.getQueriesList().rows;
    this.isDisabledButton = false;
    this.isAddRow = !this.isAddRow;
    rows.splice(this.rowAddingIndex, 1);
  }
  handleChangePage(pageRes) {
    this.isEditMode = false;
    this.isAddRow = false;
    this.indexEditRow = pageRes.pageSize * (pageRes.pageIndex + 1);
  }
}
