import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { checkForErrorsIn } from '../../../utils/validations';
import { CustomBtnColor } from '../../../interfaces/button';
import { formattedJson } from '../../../utils/strings';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrl: './textarea.component.scss',
})
export class TextareaComponent {
  customBtnColor: CustomBtnColor = {
    color: 'black',
    bg: 'white',
    hover: 'gray',
    border: '1px solid #1c1c1c1e',
    disabled: '#D5D9DC',
  };
  @Input() form: FormGroup;
  @Input() formName: string = '';
  @Input() title: string = '';
  @Input() type: string = 'text';
  @Output() handleStepper = new EventEmitter();

  checkForErrorsIn(formControl: AbstractControl, field: string): string {
    return checkForErrorsIn(formControl, field);
  }
  formattedJson(jsObj) {
    this.form.patchValue({
      [this.formName]: formattedJson(jsObj),
    });
  }
}
