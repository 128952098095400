<mat-form-field appearance="outline" class="container">
  <input
    matInput
    [matDatepicker]="picker"
    (dateInput)="addEvent('input', $event)"
    (dateChange)="addEvent('change', $event)"
    [formControl]="displayDate"
  />
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
