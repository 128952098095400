import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  containsOnlyEnglishLettersAndNumbers,
  forbiddenCognito,
  jsonValidator,
} from '../../../utils/validations';
import { STEPPERS_SETTINGS } from './settings.component';
import { HttpHandlerService } from '../../../services/http-handler.service';
import { delay, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private productForm: FormGroup;
  public stepperSettings = STEPPERS_SETTINGS;

  constructor(
    private formBuilder: FormBuilder,
    private httpHandler: HttpHandlerService
  ) {}

  getProductForm() {
    return this.productForm;
  }

  setProductForm(product?) {
    this.productForm = this.formBuilder.group({
      ...(product?.id !== undefined ? { id: [product?.id || ''] } : {}),
      name: [
        product?.name || '',
        Validators.compose([
          Validators.required,
          containsOnlyEnglishLettersAndNumbers,
        ]),
      ],
      title: [
        product?.title || '',
        Validators.compose([
          Validators.required,
          containsOnlyEnglishLettersAndNumbers,
        ]),
      ],
      description: [product?.description || ''],
      googleAnalyticsPropId: [
        product?.googleAnalyticsPropId || '',
        Validators.required,
      ],
      googleAnalyticsJsonKey: [
        JSON.stringify(product?.googleAnalyticsJsonKey) || JSON.stringify({}),
        Validators.compose([jsonValidator]),
      ],
      isUsingCognito: [product?.isUsingCognito || false],
      cognitoRegion: [product?.cognitoRegion || ''],
      cognitoAppId: [product?.cognitoAppId || '', [], [forbiddenCognito()]],
      cognitoPool: [product?.cognitoPool || '', [], [forbiddenCognito()]],
      systemAccess: [product?.systemAccess || [], Validators.required],
      status: [product?.status || 'active'],
    });
  }

  resetProductForm() {
    this.setProductForm(null);
  }
  userPoolValidate(body) {
    return this.httpHandler.post(`users/validate`, body);
  }
  fetchGenerateApiKey() {
    return of({ data: '1234 5678 9101 1213 1415 999' }).pipe(delay(600));
    //TODO:API GENERATE
    // return this.httpHandler.get(`design/generate`).subscribe();
  }
}
