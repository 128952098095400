import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from '../../../services/products.service';
import { SidebarService } from '../../../services/sidebar.service';
import { DialogComponent } from '../../widgets/dialog/dialog.component';
import { SettingsService } from './settings.service';
import { Product } from '../../../interfaces/products';
import { Schema } from '../../../interfaces/schema';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { UsersAdminComponent } from './users-admin/users-admin.component';
import { AwsCognitoComponent } from './aws-cognito/aws-cognito.component';
import { GoogleAnalyticsComponent } from './google-analytics/google-analytics.component';
import {
  AWS_COGNITO,
  ERROR_VALIDATION,
  GOOGLE_ANALYTICS,
  PRODUCT_DETAILS,
  SUCCESS_ADD_PRODUCT,
  SUCCESS_UPDATE_PRODUCT,
  USERS_ADMIN,
} from '../../../types/constant';
import { FormGroup } from '@angular/forms';
import { removeSpaceAndCapitalizeWords } from '../../../utils/strings';
import { AlertService } from '../../../services/alert.service';
import { ApiKeyDialogComponent } from '../../dialogs/api-key-dialog/api-key-dialog.component';

export interface DynamicForm {
  id: number;
  header: string;
  component: any;
  openApiKeyDialog?: any;
  handleStepper?: any;
}
export const STEPPERS_SETTINGS: DynamicForm[] = [
  {
    id: 1,
    header: PRODUCT_DETAILS,
    component: ProductDetailsComponent,
  },
  {
    id: 2,
    header: USERS_ADMIN,
    component: UsersAdminComponent,
  },
  {
    id: 3,
    header: AWS_COGNITO,
    component: AwsCognitoComponent,
  },
  {
    id: 4,
    header: GOOGLE_ANALYTICS,
    component: GoogleAnalyticsComponent,
  },
];

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss',
})
export class SettingsComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private productService: ProductsService,
    private sidebarService: SidebarService,
    private settingsService: SettingsService,
    private router: Router,
    private alertService: AlertService
  ) {}
  public productForm: FormGroup;
  originalSystemAccess = [];
  navigateTo: string;

  ngOnInit(): void {
    this.sidebarService.expandedMenu('');
    const productName = this.route.snapshot.params['productName'];
    //if edit settings
    if (productName) {
      this.productService.fetchGetProducts().subscribe((data: any) => {
        const productRes = data.find((product) => product.name === productName);
        const product = this.productService.getProductById(productRes.id);
        this.originalSystemAccess = [...product.systemAccess];
        this.productService.setProduct(product);
        this.settingsService.setProductForm(product);
        this.sidebarService.updateDisabled(false);
        this.productForm = this.settingsService.getProductForm();
      });
    }
    //if new products settings
    else {
      this.settingsService.setProductForm();
      this.sidebarService.updateDisabled(true, 'Settings');
      this.productForm = this.settingsService.getProductForm();
    }
  }
  public steppers = this.settingsService.stepperSettings;

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Delete Data',
        content:
          'Are you sure you want to delete? After we delete, data cannot be recovered.',
      },
      width: '18%',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (this.productForm.value.id)
          this.productService.deleteProduct(this.productForm.value.id);
      }
    });
  }
  saveProduct() {
    const { googleAnalyticsJsonKey, name } = this.productForm.value;
    const product: Product = {
      ...this.productForm.value,
      googleAnalyticsJsonKey: JSON.parse(googleAnalyticsJsonKey),
      name: removeSpaceAndCapitalizeWords(name),
    };

    if (product.isUsingCognito) {
      const validation = {
        userPoolId: product.cognitoPool,
        clientId: product.cognitoAppId,
      };
      this.settingsService.userPoolValidate(validation).subscribe((res) => {
        if (res) {
          this.postData(product);
        } else {
          this.alertService.errorPopup(ERROR_VALIDATION);
        }
      });
    } else {
      this.postData(product);
    }
  }

  postData(product) {
    const userDeleted = this.originalSystemAccess.filter(
      (email) => !product.systemAccess.includes(email)
    );
    if (product.id) {
      //if update product
      const admins = product.systemAccess;
      delete product.systemAccess;
      this.productService.fetchUpdateProduct(product).subscribe(() => {
        this.navigateTo = `/products/${product.name}/table_builder`;
        this.productService
          .fetchUpdateAssociateAdmins(product.id, admins, userDeleted)
          .subscribe(() => {
            this.productService.fetchProducts();
            this.alertService.successPopup(
              SUCCESS_UPDATE_PRODUCT,
              this.navigate
            );
          });
      });
    } else {
      //if add new product
      delete product['status'];
      this.productService
        .fetchAddProduct(product)
        .subscribe((productRes: Product) => {
          //create schema
          const schema: Schema = {
            productId: productRes.id,
            name: productRes.name,
            title: productRes.name,
          };
          this.productService.fetchAddSchema(schema).subscribe(() => {
            this.openApiKeyDialog(productRes.apiKey);
            this.productService
              .fetchAssociateAdmins(productRes.id, product.systemAccess)
              .subscribe(() => {
                this.productService.fetchProducts();
                this.productService.setProduct(productRes);
                this.navigateTo = `/products/${productRes.name}/table_builder`;
                this.alertService.successPopup(
                  SUCCESS_ADD_PRODUCT,
                  this.navigate
                );
              });
          });
          //associate admins to product
        });
    }
  }

  navigate = () => {
    this.router.navigateByUrl(this.navigateTo);
  };

  generateKey() {
    this.settingsService.fetchGenerateApiKey().subscribe((res) => {
      this.openApiKeyDialog(res.data);
    });
  }

  openApiKeyDialog(apiKey): void {
    const dialogRef = this.dialog.open(ApiKeyDialogComponent, {
      data: {
        title: 'Api Key',
        value: apiKey,
        header: 'please pay attention!',
        titleSubmit: 'Done',
        content:
          'This key will not be saved so copy it and save it in the project, You can create a new key if it is lost.',
      },
      width: '23%',
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  handleStepper(tab) {
    this.sidebarService.drawTextMenu('Settings', tab);
  }
}
