<mat-grid-list class="list" cols="4" rowHeight="450px" [gutterSize]="'20px'">
  <mat-grid-tile class="tile" [colspan]="2">
    <!-- Users by device -->
    <div class="card">
      <div class="title">Users by device</div>
      <div class="con-canvas">
        <div class="chart">
          <canvas class="chart-canvas" id="chart-doughnut"> </canvas>
        </div>
        @if (platform.android || platform.ios || platform.web) {

        <div class="an-list">
          <div class="cont">
            <div class="dot-cont">
              <div class="dot an-green"></div>
            </div>
            <div class="platform">Android</div>
            <div class="number">
              {{ platform.android }}
            </div>
          </div>
          <div class="cont">
            <div class="dot-cont">
              <div class="dot an-blue"></div>
            </div>
            <div class="platform">IOS</div>
            <div class="number">
              {{ platform.ios }}
            </div>
          </div>
          <div class="cont">
            <div class="dot-cont">
              <div class="dot an-light-green"></div>
            </div>
            <div class="platform">Web</div>
            <div class="number">
              {{ platform.web }}
            </div>
          </div>
        </div>
        }
      </div>
    </div></mat-grid-tile
  >

  <mat-grid-tile [colspan]="1" class="tile">
    <!-- Users by device model -->
    <div class="card">
      <div class="title">Users by device model</div>
      @if (deviceModel) {
      <div class="table">
        <table mat-table [dataSource]="deviceModel | keyvalue">
          <!-- Position Column -->
          <ng-container matColumnDef="Model">
            <th mat-header-cell *matHeaderCellDef>Model</th>
            <td mat-cell *matCellDef="let element">{{ element.key }}</td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="Users">
            <th mat-header-cell *matHeaderCellDef>Users</th>
            <td mat-cell *matCellDef="let element">{{ element.value }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="['Model', 'Users']"></tr>
          <tr mat-row *matRowDef="let row; columns: ['Model', 'Users']"></tr>
        </table>
      </div>
      }
    </div></mat-grid-tile
  >
  <mat-grid-tile [colspan]="1" class="tile" [rowspan]="1">
    <!-- Users by event count -->

    <div class="card">
      <div class="title">Event count - by event name</div>
      @if (eventCount) {
      <div class="table">
        <table mat-table [dataSource]="eventCount | keyvalue">
          <ng-container matColumnDef="Event Name">
            <th mat-header-cell *matHeaderCellDef>Model</th>
            <td mat-cell *matCellDef="let element">{{ element.key }}</td>
          </ng-container>
          <ng-container matColumnDef="Event Count">
            <th mat-header-cell *matHeaderCellDef>Users</th>
            <td mat-cell *matCellDef="let element">{{ element.value }}</td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="['Event Name', 'Event Count']"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: ['Event Name', 'Event Count']"
          ></tr>
        </table>
      </div>
      }
    </div>
  </mat-grid-tile>

  <mat-grid-tile [colspan]="2" class="tile">
    <!-- Users by Enagagement -->
    <div class="card">
      <div class="container-details">
        <div class="title">Average engagement time</div>
        <div class="paragraph">
          The total amount of time (in seconds) your website or app was in the
          foreground of users' devices, plotted over weeks.
        </div>
      </div>
      <canvas class="chart-canvas1" id="chart-line"> </canvas>
    </div>
  </mat-grid-tile>
  <mat-grid-tile [colspan]="2" class="tile">
    <!-- Users by Retention-->
    <div class="card">
      <div class="container-details">
        <div class="title">User retention</div>
        <div class="paragraph">
          The User retention by cohort graph shows how well your site or app
          retains users by cohort. A cohort is a collection of users who are
          grouped by some criteria. In this case, the cohort is the day the user
          was acquired.
        </div>
      </div>
      <canvas class="chart-canvas1" id="chart-line-retention"> </canvas>
    </div>
  </mat-grid-tile>
  <mat-grid-tile class="tile" [colspan]="2">
    <!-- Users by Cohort-->
    <div class="card cohort">
      <div class="title">User activity by cohort</div>
      <canvas class="chart-canvas" id="chart-bar"> </canvas></div
  ></mat-grid-tile>
  <mat-grid-tile [colspan]="2" class="tile">
    <div class="card">
      <div class="title">Users by country</div>
      <div class="country">
        <app-vector [countriesData]="countries"></app-vector>
        <div
          class="an-progress"
          *ngFor="let country of top4countries; index as i; first as isFirst"
        >
          <div class="an-head">
            <div class="an-left">{{ country.name }}</div>
            <div class="an-right">{{ country.value }}</div>
          </div>
          <div id="progressbar">
            <div [style.width.%]="country.percent"></div>
          </div>
        </div>
      </div></div
  ></mat-grid-tile>
</mat-grid-list>
