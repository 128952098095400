import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Box, Button } from "../../../interfaces/components";
import { ProductList } from "../../../interfaces/products";
import { ActionRow, Column } from "../../../interfaces/table";
import { ProductsService } from "../../../services/products.service";
import { UserAuthService } from "../../../services/userAuth.service";
import { SidebarService } from "../../../services/sidebar.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { HttpHandlerService } from "../../../services/http-handler.service";

@Component({
  selector: "app-products",
  templateUrl: "./products.component.html",
  styleUrl: "./products.component.scss",
})
export class ProductsComponent implements OnInit {
  file: File;
  product: string = "your-product-name";
  qualifiedFile: string = "your-qualified-file-name";
  constructor(
    public productService: ProductsService,
    private router: Router,
    private userAuthService: UserAuthService,
    private sidebarService: SidebarService,
    private httpHandler: HttpHandlerService
  ) {}
  // rows: Product[] = [];
  columns: Column[] = [];
  header: string;
  displayColumns: string[] = [];
  boxes: Box[] = [];
  buttonDetails: Button = {
    title: "Add new product",
    icon: "add",
  };
  ngOnInit(): void {
    this.sidebarService.expandedMenu("products");
    this.productService.setProduct(null);
    this.boxes = this.productService.createBoxes();
    const productList: ProductList = this.productService.getProductList();
    this.header = productList.header;
    this.columns = productList.columns;
    this.displayColumns = productList.columns.map((item) => item.name);
  }

  AddProductDefinition() {
    this.router.navigateByUrl(`products/settings`);
  }
  handleActionByName(event: ActionRow) {
    if (event.actionName === "DISPLAY_PRODUCT_DETAILS") {
      this.router.navigate(["products_page"]);
      this.userAuthService.setUserProductLStorage(event.row.id);
    }
  }
  handleRowClick(row) {
    this.router.navigateByUrl(`products/${row.name}/table_builder`);
  }
  onFileSelected(event: any) {
    this.file = event.target.files[0];
  }

  uploadFile() {
    const formData = new FormData();
    formData.append("file", this.file);
    formData.append("product", this.product);
    formData.append("qualifiedFile", this.qualifiedFile);
    this.httpHandler.post(`files/upload`, formData).subscribe(
      (response) => {
        console.log("File uploaded successfully:", response);
      },
      (error) => {
        console.error("Error uploading file:", error);
      }
    );
  }
}
