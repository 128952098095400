import { Validators } from '@angular/forms';
import {
  Column,
  CreateColumn,
  DynamicTable,
  Lookup,
  Row,
} from '../interfaces/table';
import { COLUMN_TYPE_OPTIONS } from '../mockData/dropdown';
import { BOOLEAN_COLUMN, DATA_TYPE, FILE_COLUMN } from '../types/constant';
import { numberValidation } from './validations';
import { ColumnsService } from '../services/columns.service';
import { Observable, map } from 'rxjs';

const action = {
  name: 'action',
  title: 'Action',
  type: 'action',
  quick_actions: [
    { name: 'EDIT_ROW', icon: 'edit' },
    { name: 'DELETE_ROW', icon: 'trash' },
  ],
};

export function getColType(type) {
  return COLUMN_TYPE_OPTIONS.find((item) => +item.value === type)?.title;
}
export function dataTypeSelector(type) {
  return DATA_TYPE[type];
}

export function getDefaultForType(type): any {
  switch (type) {
    case 'date':
      return new Date().getTime();
    case 'bigint':
      return new Date().getTime();
    case 'text':
      return '';
    case 'boolean':
      return true;
    case 'select':
      return '';
    case 'decimal':
      return 0;
    case 'number':
      return null;
    case 'file':
      return '';
    case 'longtext':
      return '';
    case 'json':
      return {
        name: '',
        content: '{}',
      };
    default:
      break;
  }
}

export function createEmptyRow(columns) {
  const newObject: any = {};
  columns.forEach((column) => {
    const { name } = column;
    newObject[name] = getDefaultForType(column.type);
    newObject.editMode = true;
  });
  return newObject;
}

function createValidators(required: boolean, type: string | number) {
  const requiredValid = required ? [Validators.required] : [];
  switch (type) {
    case 'text':
      return requiredValid;
    case 'decimal':
      return [...requiredValid, Validators.compose([numberValidation])];
    case 'number':
      return [...requiredValid, Validators.compose([numberValidation])];
    default:
      return requiredValid;
  }
}

export function createDynamicForm(columns: Column[], rowData?) {
  const formGroupConfig: { [key: string]: any } = {};
  columns.forEach((column) => {
    const validators = createValidators(column.required, column.type);
    if (rowData) {
      if (rowData.id) {
        formGroupConfig['id'] = [rowData['id'] || ''];
      }
      formGroupConfig[column.name] = [rowData[column.name], validators];
    } else {
      formGroupConfig[column.name] = ['', validators];
    }
  });
  delete formGroupConfig['action'];
  return formGroupConfig;
}

export function createTable(
  header: string,
  rows: Row[],
  columns: CreateColumn[] | Column[]
): DynamicTable {
  if (rows?.length > 0) {
    rows.forEach((row) => {
      row.editMode = false;
    });
  }
  const columnsLocal: any = [...columns, action];
  const table: DynamicTable = {
    header: header,
    rows: rows,
    columns: columnsLocal,
  };

  return table;
}

export function createLookupOptions(
  columnsService: ColumnsService,
  idsLookup
): Observable<Lookup[]> {
  return columnsService.fetchGetLookupOptionsById(idsLookup);
}

export function createColumnsToTableDesign(
  columns: Column[],
  columnsService: ColumnsService,
  tableName: string
) {
  let idsLookup = [];
  if (columns?.length > 0) {
    idsLookup = columns
      .filter((col) => col.columnId)
      .map((colRes) => colRes.columnId);
  }
  const tableNameUrl = tableName.split('.');
  return createLookupOptions(columnsService, idsLookup).pipe(
    map((resLookups) => {
      const columnsRes = columns.map((col) => {
        return {
          name: col.name,
          title: col.title,
          type: col.columnId ? 'select' : getColType(col.type),
          options: col.columnId
            ? createOptionsLookup(resLookups, col)
            : col.type === BOOLEAN_COLUMN
            ? createBooleanOptions(col.trueTitle, col.falseTitle)
            : null,
          required: col.required,
          baseUrl:
            col.type === FILE_COLUMN &&
            `${tableNameUrl[0]}/${tableNameUrl[1]}/`,
        };
      });
      columnsService.setColumnsForDynamicTable(columnsRes);
    })
  );
}

function createOptionsLookup(lookupsArray, col) {
  const temp = lookupsArray?.find((look) => look.id === col.columnId).data;
  //remove duplicate values lookup
  const uniqueData = temp.filter(
    (obj, index, self) =>
      index ===
      self.findIndex((t) => t.value === obj.value && t.title === obj.title)
  );
  return uniqueData;
}

function createBooleanOptions(trueTitle, falseTitle) {
  return [
    { value: true, title: trueTitle },
    { value: false, title: falseTitle },
  ];
}

export function createDataTypeOptions(dataTypes) {
  const res = dataTypes
    .filter((data) => data.id < 9)
    .map((type) => {
      return {
        value: type.id,
        title: type.LookupId ? type.name : dataTypeSelector(type.name),
      };
    });
  return res;
}
