import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Option } from '../../../interfaces/dropdown';
import { MatSelectChange } from '@angular/material/select';
import { checkForErrorsIn } from '../../../utils/validations';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.scss',
})
export class DropdownComponent {
  @Input() form: FormGroup;
  @Input() formName: string = '';
  @Input() title: string = '';
  @Input() valueTrigger: any = [];
  @Input() options: Option[];
  public selected: string;
  @Output() onTrigger: EventEmitter<string> = new EventEmitter<string>();
  @Output() handleStepper = new EventEmitter();

  onSelectionChange(event: MatSelectChange) {
    if (this.valueTrigger.length > 0) {
      const type = this.valueTrigger.find((item) => item.value == event.value);
      if (type) {
        this.onTrigger.emit(type.title);
      } else {
        this.onTrigger.emit('other');
      }
    } else {
      this.onTrigger.emit(event.value);
    }
    this.form.patchValue({ [this.formName]: event.value });
    this.handleStepper && this.handleStepper.emit();
  }

  checkForErrorsIn(formControl: AbstractControl, field: string): string {
    return checkForErrorsIn(formControl, field);
  }
}
