<div class="container">
  <form class="row row-cols-sm-auto">
    <div class="dropdown">
      <input
        name="datepicker"
        class="form-control"
        ngbDatepicker
        #datepicker="ngbDatepicker"
        [autoClose]="'outside'"
        (dateSelect)="onDateSelection($event)"
        [displayMonths]="2"
        [dayTemplate]="t"
        [footerTemplate]="button"
        [navigation]="navigation"
        [outsideDays]="outsideDays"
        [startDate]="fromDateLocal!"
        tabindex="-1"
      />
      <div class="dropdown" (click)="datepicker.toggle()">
        <div class="title">{{ displayDate() }}</div>
        <mat-icon svgIcon="down-arrow" />
      </div>
    </div>
    <ng-template #t let-date let-focused="focused" class="temp">
      <span
        class="custom-day"
        [class.focused]="focused"
        [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null"
      >
        {{ date.day }}
      </span>
    </ng-template>
    <ng-template #button>
      <div class="btn-container">
        @for (btn of buttonDatepicker; track $index) {
        <div
          class="btn"
          (click)="onSelect(btn.days, datepicker)"
          [ngStyle]="{
            backgroundColor: btn.selected ? '#0C3058' : '#f5f5f5',
            color: btn.selected ? 'white' : 'black',
          }"
        >
          {{ btn.title }}
        </div>

        }
      </div>
    </ng-template>
  </form>
</div>
<!-- <pre>From: {{ fromDateLocal | json }} </pre>
<pre>To: {{ toDate | json }} </pre> -->
