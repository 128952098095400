import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Option } from '../../../../interfaces/dropdown';
import { Column, FilterTable } from '../../../../interfaces/table';
import { selectorType } from '../../../../utils/form';
import { CustomBtnColor } from '../../../../interfaces/button';
import { upperFirst } from '../../../../utils/strings';

@Component({
  selector: 'app-filter-result',
  templateUrl: './filter-result.component.html',
  styleUrl: './filter-result.component.scss',
})
export class FilterResultComponent implements OnChanges {
  @Input() columns: Column[];
  @Input() header: string;
  @Output() filterData: EventEmitter<FilterTable> =
    new EventEmitter<FilterTable>();
  customBtnColor: CustomBtnColor = {
    color: 'black',
    bg: 'white',
    hover: 'gray',
    border: '1px solid #1c1c1c1e',
    disabled: '#C7D7E0',
  };
  reportForm: FormGroup;
  options: Option[] = [];
  removeOptions = [];
  rowNumber = -1;
  columnDisplay = [];
  constructor(private formBuilder: FormBuilder) {}

  columnSelected(type, index: number) {
    const control = this.nestedForms.controls[index] as FormGroup;
    control.patchValue({
      actionOptions: selectorType(type),
    });
  }
  valueChange(event, index, col) {
    const control = this.nestedForms.controls[index] as FormGroup;
    control.patchValue({
      [col]: event.value,
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.options = this.columns
      .map((col) => ({ title: col.title, value: col.type, name: col.name }))
      .filter((col) => col.title !== 'id' && col.title !== 'Action');
    // Create the main form
    this.reportForm = this.formBuilder.group({
      columnDisplay: ['', Validators.required],
      nestedForms: this.formBuilder.array([], Validators.required),
    });
  }

  selectAll() {
    if (this.reportForm.controls['columnDisplay'].value.includes('selectAll')) {
      this.reportForm.patchValue({
        columnDisplay: ['selectAll', ...this.options.map((op) => op.name)],
      });
    } else {
      this.reportForm.patchValue({
        columnDisplay: [],
      });
    }
  }
  saveColumnDisplay() {
    const columnDisplay = this.reportForm.controls['columnDisplay'].value;
    this.columnDisplay = this.options.filter((col) =>
      columnDisplay.includes(col.name)
    );

    this.reportForm = this.formBuilder.group({
      columnDisplay: [columnDisplay, Validators.required],
      nestedForms: this.formBuilder.array([]),
    });
    const nestedFormGroup = this.formBuilder.group({
      column: ['', Validators.required],
      action: [''],
      text: [''],
      startDate: [''],
      endDate: [''],
      actionOptions: [],
      columnsOptions: [this.columnDisplay, Validators.required],
    });
    this.nestedForms.push(nestedFormGroup);
    const data: FilterTable = {
      columnDisplay: columnDisplay,
      data: [],
    };
    this.filterData.emit(data);
  }
  selectOption(title) {
    const columns = this.reportForm.controls['columnDisplay'].value.filter(
      (col) => col !== 'selectAll'
    );
    if (title !== 'selectAll') {
      this.reportForm.patchValue({
        columnDisplay: [...columns],
      });
    }
  }

  get nestedForms(): FormArray {
    return this.reportForm.get('nestedForms') as FormArray;
  }
  // Add a method to dynamically add a nested form
  addNestedForm() {
    this.rowNumber = this.rowNumber + 1;
    const removeOptions = this.nestedForms.value.map((row) => row.column);
    let options;
    if (removeOptions.length > 0) {
      options = this.columnDisplay.filter(
        (col) => !removeOptions.includes(col.name)
      );
    } else {
      options = this.columnDisplay;
    }
    const nestedFormGroup = this.formBuilder.group({
      column: ['', Validators.required],
      action: [''],
      text: [''],
      startDate: [''],
      endDate: [''],
      actionOptions: [],
      columnsOptions: [options, Validators.required],
    });
    this.nestedForms.push(nestedFormGroup);
  }
  removeNestedForm(index: number) {
    this.rowNumber = this.rowNumber - 1;
    this.nestedForms.removeAt(index);
  }
  reportFilter() {
    const dataToSend = this.reportForm.value.nestedForms
      .filter((fil) => fil.action)
      .map((data) => {
        return {
          columnName: data.column,
          action: data.action ? data.action : 'date',
          text: data.action
            ? data.text
            : [data.startDate.getTime(), data.endDate.getTime()],
        };
      });
    const data: FilterTable = {
      columnDisplay: this.reportForm.value.columnDisplay.filter(
        (col) => col !== 'selectAll'
      ),
      data: dataToSend,
    };
    this.filterData.emit(data);
  }
  displayHeader() {
    return `${upperFirst(this.header)} Report`;
  }
}
