<div [formGroup]="form">
  <div class="container">
    <mat-button-toggle-group
      appearance="standard"
      formControlName="{{ formName }}"
      class="toggle"
    >
      @for (option of options; track $index) {
      <mat-button-toggle
        [ngClass]="{
             selected:   form.get(formName).value === option.value,
          'not-selected':  form.get(formName).value !=option.value,
        }"
        class="button"
        [value]="option.value"
      >
        <div class="title">{{ option.title }}</div>
      </mat-button-toggle>
      }
    </mat-button-toggle-group>
  </div>
</div>
