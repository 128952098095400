<div [formGroup]="form">
  <div class="field">
    <div class="title">{{ title }}</div>
    <mat-form-field appearance="outline" class="container">
      @if (type==='text') {
      <textarea
        hidden
        matInput
        cols="40"
        rows="3"
        formControlName="{{ formName }}"
        (focus)="this.handleStepper.emit()"
      ></textarea>
      }@else {
      <textarea
        hidden
        matInput
        cols="40"
        rows="3"
        formControlName="{{ formName }}"
        (focus)="this.handleStepper.emit()"
      ></textarea>

      }
      <mat-error>{{ checkForErrorsIn(form.get(formName), title) }}</mat-error>
    </mat-form-field>
    @if (type==="json") {
    <app-button
      height="40px"
      [customBtnColor]="customBtnColor"
      paddingInline="1.5vw"
      (action)="formattedJson(form.get(formName))"
      title="Format Json"
    ></app-button>
    }
  </div>
</div>
