import { Component } from '@angular/core';
import { ProductsService } from '../../../services/products.service';

@Component({
  selector: 'app-hidden-tables',
  templateUrl: './hidden-tables.component.html',
  styleUrl: './hidden-tables.component.scss',
})
export class HiddenTablesComponent {
  constructor(public productsService: ProductsService) {}
}
