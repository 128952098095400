import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GOOGLE_ANALYTICS } from '../../../../types/constant';
import { SidebarService } from '../../../../services/sidebar.service';

@Component({
  selector: 'app-google-analytics',
  templateUrl: './google-analytics.component.html',
  styleUrl: './google-analytics.component.scss',
})
export class GoogleAnalyticsComponent {
  @Input() productForm: FormGroup;
  @Input() header: string = '';
  @Output() handleStepper = new EventEmitter<string>();

  id = GOOGLE_ANALYTICS;
  constructor(public sidebarService: SidebarService) {}

  isFocus() {
    return this.id === this.sidebarService.getActiveStepper();
  }
}
