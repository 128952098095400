import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[appAutofocus]',
})
export class AutofocusDirective implements OnChanges {
  constructor(private el: ElementRef) {}

  @Input() appAutofocus: boolean = true; // Default to true for backwards compatibility

  ngOnChanges(): void {
    if (this.appAutofocus) {
      this.el.nativeElement.focus();
    }
  }
}
