import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Meta } from '../../interfaces/table';

@Component({
  selector: 'app-cells',
  templateUrl: './cells.component.html',
  styleUrl: './cells.component.scss',
})
export class CellsComponent {
  @Input() editMode: boolean;
  @Input() type: any;
  @Input() value: any;
  @Input() form: FormGroup;
  @Input() formName: string = '';
  @Input() meta: Meta | null;
}
