import { Component, Input, Output, EventEmitter, Inject } from "@angular/core";
import Chart from "chart.js";
import { ActivatedRoute } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { ProductsService } from "../../../../services/products.service";
import { getTodayDate } from "../../../../utils/date";
import { convertToMinutes } from "../../../../utils/strings";

export interface GoogleAnalyticsDetails {
  totalUsers?: number;
  avgUsageTime?: number;
  totalLogins?: number;
}

@Component({
  selector: "app-analytics",
  templateUrl: "analytics.component.html",
  styleUrls: ["analytics.component.scss"],
})
export class AnalyticsComponent {
  private window: any;

  product: any;
  platform = { android: 0, ios: 0, web: 0, total: 0 };
  countries = null;
  top4countries = [];
  deviceModel = null;
  eventCount = null;
  reqStatus = false;
  @Input() fromDate = "2017-01-01";
  @Input() toDate = getTodayDate(new Date());
  @Input() platformFilter;
  @Input() isClicked = false;
  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() activeUsersCount = new EventEmitter<string>();
  @Output() googleAnalyticsDetails = new EventEmitter<GoogleAnalyticsDetails>();

  constructor(
    // private httpService: HttpService,
    private router: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private ProductsService: ProductsService
  ) {
    this.router.queryParamMap.subscribe((params) => {
      this.product = params;
    });
    this.window = this.document.defaultView;
  }

  handleLoader(status: boolean) {
    this.reqStatus = status;
  }

  ngOnChanges(changes) {
    if (changes.fromDate) {
      this.fromDate = changes.fromDate.currentValue;
    }
    if (changes.toDate) {
      this.toDate = changes.toDate.currentValue;
    }

    if (changes.toDate || this.isClicked) {
      this.handleLoader(false);
      this.getAnalyticsData();
      this.onClick.emit(false);
    }
  }

  getAnalyticsData() {
    if (this.reqStatus === true) return;
    const params = {
      projectId: "292571070",
      startDate: this.fromDate,
      endDate: this.toDate,
      projectName: "Laboratories",
    };
    this.ProductsService.getAnalytics(params).subscribe((data: any) => {
      this.handleUsersByDevice(data);
      this.handleUsersByCountry(data);
      this.handleUsersByDeviceModel(data);
      this.handleEngagementTime(data);
      this.handleRetention(data);
      this.handleEventCount(data);
      this.handleCohort(data);
      this.handleActiveUsers(data);
    });
  }
  handleActiveUsers(data) {
    this.activeUsersCount.emit(data.activeUsers);
  }

  handleCohort(data) {
    let cohort = data.cohort;

    let keys = Object.keys(cohort).sort();
    let values = [];
    for (let i = 0; i < keys.length; i++) {
      values.push(Math.round(parseInt(cohort[keys[i]])));
    }
    var chartBarStacked = document.getElementById("chart-bar");

    new Chart(chartBarStacked, {
      type: "horizontalBar",
      data: {
        labels: keys,
        datasets: [
          {
            axis: "y",
            label: "Users percentage",
            backgroundColor: "#0099FA",
            data: values,
          },
        ],
      },
      options: {
        legend: {
          display: false,
          position: "top",
        },
        indexAxis: "x",
        tooltips: {
          mode: "index",
          intersect: false,
        },
        responsive: true,
        scales: {
          xAxes: [
            {
              stacked: true,
              ticks: {
                callback: function (value, index, values) {
                  return `${value}%`;
                },
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                drawBorder: false,
              },
              ticks: {
                callback: function (value, index, values) {
                  return `${value * 1} Week`;
                },
              },
            },
          ],
        },
      },
    });
  }

  handleEventCount(data) {
    this.googleAnalyticsDetails.emit({
      totalLogins: data.events.session_start,
    });
    this.eventCount = data.events;
  }

  handleRetention(data) {
    let keys: number[] | string[] = Object.keys(data.retention);
    let values = [];
    for (let i = 0; i < keys.length; i++) {
      values.push(Math.round(parseInt(data.retention[keys[i]])));
    }

    keys = keys.map((date) =>
      date.replace(/(\d{4})(\d{2})(\d{2})/g, "$1-$2-$3")
    );

    keys = keys.map((date) => {
      const today = new Date();
      const pastDate = new Date(date);
      return DateDiff(pastDate, today);
    });

    var chartRetention = document.getElementById("chart-line-retention");

    new Chart(chartRetention, {
      type: "line",
      data: {
        labels: keys,
        datasets: [
          {
            label: "User Retention",
            data: values,
            fill: false,
            borderColor: "#0099FA",
          },
        ],
      },
      options: {
        scales: {
          xAxes: [
            {
              gridLines: {
                color: "rgba(0, 0, 0, 0)",
                display: false,
              },
              ticks: {},
              scaleLabel: {
                display: true,
                labelString: "Days",
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                drawBorder: false,
              },
              ticks: {
                callback: function (value, index, values) {
                  return value + "%";
                },
              },
              scaleLabel: {
                display: true,
                labelString: "Cohort Percentage",
              },
            },
          ],
        },
        legend: {
          display: false,
          position: "top",
        },
      },
    });

    function DateDiff(d1, d2) {
      var t2 = d2.getTime();
      var t1 = d1.getTime();

      return Math.floor((t2 - t1) / (24 * 3600 * 1000));
    }
  }

  handleEngagementTime(data) {
    let keys = Object.keys(data.userEngagementDuration).sort().reverse();
    let values = [];
    for (let i = 0; i < keys.length; i++) {
      values.push(data.userEngagementDuration[keys[i]]);
    }
    let arraySum = values.reduce(
      (acc, currentValue) => acc + parseInt(currentValue),
      0
    );

    var chartSales = document.getElementById("chart-line");
    this.googleAnalyticsDetails.emit({
      avgUsageTime: convertToMinutes(arraySum),
    });

    new Chart(chartSales, {
      type: "line",
      data: {
        labels: keys,
        datasets: [
          {
            label: "Engagement Time",
            data: values,
            fill: false,
            borderColor: "#0099FA",
          },
        ],
      },
      options: {
        scales: {
          xAxes: [
            {
              gridLines: {
                color: "rgba(0, 0, 0, 0)",
                display: false,
              },
              ticks: {},
              scaleLabel: {
                display: true,
                labelString: "Weeks",
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                drawBorder: false,
              },
              position: "right",
              scaleLabel: {
                display: true,
                labelString: "Seconds",
              },
            },
          ],
        },
        legend: {
          display: false,
          position: "top",
        },
      },
    });
  }

  handleUsersByDeviceModel(data) {
    if (data.deviceModel) {
      let p = data.deviceModel;
      let newObj = {};
      for (var key in p) {
        if (p.hasOwnProperty(key)) {
          if (key.includes("iPhone")) {
            newObj[
              this.window?.iosDeviceList?.deviceByIdentifier(key)[0].Generation
            ] = p[key];
          } else {
            newObj[key] = p[key];
          }
        }
      }
      this.deviceModel = newObj;
    }
  }

  handleUsersByCountry(data) {
    this.top4countries = [];
    this.countries = data.country;

    const highestValues: number[] = Object.values(this.countries)
      .sort(function (a: any, b: any) {
        return b - a;
      })
      .splice(0, 4)
      .map(Number);

    for (let i = 0; i < highestValues.length; i++) {
      const el = highestValues[i];
      Object.keys(this.countries).forEach((key) => {
        if (this.top4countries.length > 3) {
          return;
        } else {
          if (el === parseInt(this.countries[key]))
            this.top4countries.push({
              name: key,
              value: this.countries[key],
              percent: Math.round(
                (parseInt(this.countries[key]) / highestValues[0]) * 100
              ),
            });
        }
      });
    }
  }

  handleUsersByDevice(data) {
    this.platform.android = parseInt(data.platform.Android) || 0;
    (this.platform.ios = parseInt(data.platform.iOS) || 0),
      (this.platform.web = parseInt(data.platform.web) || 0),
      (this.platform.total =
        this.platform.android + this.platform.ios + this.platform.web);
    this.googleAnalyticsDetails.emit({
      totalUsers: this.platform.total,
    });
    var chartDoughnut = document.getElementById("chart-doughnut");
    const centerPlugin = (chart) => {
      if (chart.config.options.showUsers) {
        var width = chart.chart.width,
          height = chart.chart.height,
          ctx = chart.chart.ctx;
        ctx.restore();
        var fontSize = (height / 114).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "middle";
        var text = String(this.platform.total),
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2.2;

        ctx.fillText(text, textX, textY);

        (text = "Users"),
          (textX = Math.round((width - ctx.measureText(text).width) / 2)),
          (textY = height / 1.6);
        ctx.fillText(text, textX, textY);
        ctx.save();
      }
    };
    new Chart(chartDoughnut, {
      type: "doughnut",
      data: {
        labels: ["Android", "IOS", "Web"],
        datasets: [
          {
            data: [data.platform.Android, data.platform.iOS, data.platform.web],
            backgroundColor: ["#219452", "#0099FA", "#C4E400"],
            label: "Dataset 1",
          },
        ],
      },
      options: {
        showUsers: true,
        cutoutPercentage: 70,
        responsive: true,
        legend: {
          display: false,
          position: "top",
        },
        animation: {
          animateScale: true,
          animateRotate: true,
        },
      },
      plugins: [{ beforeDraw: centerPlugin }],
    });
  }
}
