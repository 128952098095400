

<mat-toolbar class="tool-bar">
  <div *ngIf="breadcrumbs.length > 0" class="bread">
    <ng-container *ngFor="let breadcrumb of breadcrumbs; let i = index">
      <a mat-button *ngIf="!isCurrentPage(breadcrumb); else lastCrumb" [routerLink]="breadcrumb.link">
        {{ breadcrumb.text }}
      </a>
      <ng-template #lastCrumb>
        <span class="current-page">{{ breadcrumb.text }}</span>
      </ng-template>
      <span *ngIf="i < breadcrumbs.length - 1"> / </span>
    </ng-container>
  </div>
  <div *ngIf="userAuthService.user()?.roleId === superAdmin && this.userAuthService.getUserProductLStorage()">
    <app-button height="40px" [customBtnColor]="customBtnColor" paddingInline="1.5vw" (action)="back()"
      title="back to super admin"></app-button>
  </div>
</mat-toolbar>