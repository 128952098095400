import {
  HttpErrorResponse,
  HttpHandlerFn,
  HttpInterceptorFn,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import Swal from 'sweetalert2';
import { LoadingService } from './services/loading.service';
import { Router } from '@angular/router';

export const errorInterceptorInterceptor: HttpInterceptorFn = (
  req,
  next: HttpHandlerFn
) => {
  const loadingService: LoadingService = inject(LoadingService);
  const router: Router = inject(Router);

  return next(req).pipe(
    catchError((error) => {
      handleError(error, router);

      loadingService.hide();
      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-start',
        showConfirmButton: false,
        width: '25%',
        timer: 5000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
      Toast.fire({
        icon: 'error',
        title:
          (error?.error.message || error.message) + ' status: ' + error.status,
      });
      return throwError(() => error);
    })
  );
};
function handleError(error: HttpErrorResponse, router: Router) {
  let errorMessage = 'Unknown error!';
  if (error.error instanceof ErrorEvent) {
    // Client-side errors
    errorMessage = `Error: ${error.error.message}`;
  } else {
    // Server-side errors
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;

    switch (error.status) {
      case 401: {
        // Add log out function here
        router.navigate(['/']);

        break;
      }
      case 400: {
        break;
      }
      default: {
        //   this.adalLoginService.logOut();
        //   this.adalLoginService.login();
        break;
      }
    }
  }
  console.error(errorMessage);
  return throwError(errorMessage);
}
