import { Injectable, signal } from "@angular/core";
import { mergeMap, of } from "rxjs";
import { UserAuth } from "../interfaces/users";
import { jwtDecode } from "jwt-decode";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { ROLES } from "../types/constant";

@Injectable({
  providedIn: "root",
})
export class UserAuthService {
  constructor(private http: HttpClient) {}
  public user = signal<UserAuth>(null);

  fetchGetUser() {
    const baseUriDesign = environment.baseUriDesign;
    const url = "authorization";
    return this.http
      .get(`${baseUriDesign}${url} `, {
        headers: {
          responseType: "text",
          authorization: "Bearer " + localStorage.getItem("jwt"),
          Accept: "plain/text",
        },
      })
      .pipe(
        mergeMap((data: any) => {
          const userDetails: UserAuth = jwtDecode(data.accessToken);
          this.setTokenLStorage(data.accessToken);
          return of(
            this.user.set({
              id: userDetails.id,
              externalUserId: userDetails.email,
              roleId: ROLES[userDetails.roleId],
            })
          );
        })
      );
  }
  getUser() {
    return this.user();
  }
  getUserProductLStorage() {
    return Number(localStorage.getItem("productId"));
  }
  setUserProductLStorage(productId) {
    return localStorage.setItem("productId", productId);
  }
  setTokenLStorage(token) {
    return localStorage.setItem("token", token);
  }
}
