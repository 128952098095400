import { Role } from '../interfaces/roles';
import { Column } from '../interfaces/table';

export const columnsRoles: Column[] = [
  {
    name: 'title',
    title: 'Role Name',
    type: 'text',
  },
  {
    name: 'action',
    title: 'Action',
    type: 'action',
    required: false,
    quick_actions: [
      { name: 'EDIT_QUERY', icon: 'edit' },
      { name: 'DELETE_QUERY', icon: 'trash' },
    ],
  },
];
export const emptyRole: Role = {
  id: 0,
  title: '',
  productId: '',
  editMode: true,
};
