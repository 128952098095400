@if (editMode) {
<div [formGroup]="form">
  <mat-form-field appearance="outline" class="container">
    <input matInput placeholder="text" [formControlName]="formName" />
    <mat-error>{{ checkForErrorsIn(form.get(formName), formName) }}</mat-error>
  </mat-form-field>
</div>

}@else {
<div class="title">
  @if (isArray()) {
  <div class="container-arr">
    @for (item of value; track $index) {
    <div>{{ item }}</div>
    }
  </div>
  }@else {

  <div
    class="container-bool"
    [ngStyle]="{
      color: meta.style ? displayColor() : 'black'
    }"
  >
    @if (meta.style) {
    <li></li>
    }
    <div class="title">
      {{ displayValue() }}
    </div>
  </div>

  }
</div>
}
