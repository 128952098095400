import {
  AbstractControl,
  AsyncValidatorFn,
  FormControl,
  FormGroup,
  ValidationErrors,
} from '@angular/forms';
import { Observable, map, of } from 'rxjs';

export const checkForErrorsIn = (
  formControl: AbstractControl,
  field: string
): string => {
  // jsonValidator(formControl);
  if (formControl.hasError('required')) {
    return `${field} is required`;
  } //else  //implicit else
  if (formControl.hasError('jsonInvalid')) {
    return `${field} is jsonInvalid`;
  }
  if (formControl.hasError('numberInvalid')) {
    return `number Invalid`;
  }
  if (formControl.hasError('spacesInvalid')) {
    return `one word please`;
  }
  if (formControl.hasError('wordInvalid')) {
    return `just letter and numbers please`;
  }
  if (formControl.hasError('forbiddenCognito')) {
    return `forbidden Cognito`;
  }
  return '';
};

// why not just return true or false?
export function jsonValidator(control: FormGroup): ValidationErrors | null {
  try {
    JSON.parse(control.value);
  } catch (e) {
    return { jsonInvalid: true };
  }
  return null;
}
export function numberValidation(control: FormGroup): ValidationErrors | null {
  const numericRegex = /^[0-9]+(\.[0-9]+)?$/;
  if (numericRegex.test(control.value)) {
    return null;
  }

  return { numberInvalid: true };
}
export function hasNoSpaces(control: FormGroup): ValidationErrors | null {
  if (!/\s/.test(control.value)) {
    return null;
  }
  return { spacesInvalid: true };
}
export function containsOnlyEnglishLettersAndNumbers(input) {
  // Regular expression to match any character englishLettersAndNumber
  const englishLettersAndNumbersRegex = /^[a-zA-Z0-9\s]+$/;

  if (englishLettersAndNumbersRegex.test(input.value)) {
    return null;
  }
  return { wordInvalid: true };
}

export function mockValidation(val): Observable<boolean> {
  let data;
  if (val.parent) {
    const { controls } = val.parent;
    data = {
      cognitoPool: controls.cognitoPool.value,
      cognitoRegion: controls.cognitoRegion.value,
      cognitoAppId: controls.cognitoAppId.value,
    };
  }

  return of(data?.cognitoPool === 'a' && data?.cognitoAppId === 'a');
}

export function forbiddenCognito(): AsyncValidatorFn {
  return (control: FormControl): Observable<ValidationErrors | null> =>
    mockValidation(control).pipe(
      map((res) => (res ? { forbiddenCognito: true } : null))
    );
}

export function containsOnlyEnglishLetters(input) {
  // Regular expression to match any character englishLettersAndNumber
  const englishLettersAndNumbersRegex =
    /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;

  if (englishLettersAndNumbersRegex.test(input)) {
    return true;
  }
  return false;
}
