import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { COLUMN_TYPE_OPTIONS } from '../../../../mockData/dropdown';
import { ColumnsService } from '../../../../services/columns.service';
import { TablesService } from '../../../../services/tables.service';
import {
  removeSpaceAndCapitalizeWords,
  upperFirst,
} from '../../../../utils/strings';
import { CreateColumn } from '../../../../interfaces/table';
import { CustomBtnColor } from '../../../../interfaces/button';
import { ProductsService } from '../../../../services/products.service';
import { createDataTypeOptions } from '../../../../utils/table';
import { createOption } from '../../../../utils/form';
import { SidebarService } from '../../../../services/sidebar.service';
import { AlertService } from '../../../../services/alert.service';
import {
  LOOKUP_ID,
  SUCCESS_ADD_COLUMNS,
  SUCCESS_UPDATE_COLUMNS,
} from '../../../../types/constant';

type ColumnDetails = {
  index: number;
  name: string;
  title: string;
  id: number;
};
@Component({
  selector: 'app-create-columns',
  templateUrl: './create-columns.component.html',
  styleUrl: './create-columns.component.scss',
})
export class CreateColumnsComponent implements OnInit {
  createColumnForm: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    public tablesService: TablesService,
    public columnsService: ColumnsService,
    private route: ActivatedRoute,
    private router: Router,
    private productsService: ProductsService,
    private sidebarService: SidebarService,
    private alertService: AlertService
  ) {}

  public columnTypeOptions = [];
  public managementsTablesOptions = [];
  public managementsColumnsOptions = [];
  public isEditMode = false;
  public tableId;
  public columns = [];
  public tableName;
  public hoveredColByName: string = '';
  public inputLength = 10;
  public url: string;
  public tableTitle: string;
  public editableColumnDetails: ColumnDetails = {
    index: 0,
    name: '',
    id: 0,
    title: '',
  };
  public valueTrigger: any = [
    {
      title: 'lookup Table',
      value: '-119',
    },
    {
      title: 'boolean',
      value: COLUMN_TYPE_OPTIONS.find((type) => type.title === 'boolean').value,
    },
  ];
  public isDisplayDropdown: string;
  public columnsToAdd: CreateColumn[] = [];
  customBtnColor: CustomBtnColor = {
    color: 'black',
    bg: 'white',
    hover: 'gray',
    border: '1px solid #1c1c1c1e',
    disabled: '#D5D9DC',
  };

  ngOnInit(): void {
    const productName = this.productsService.productName();
    this.url = `products/${productName}/table_builder`;
    this.columnsService.fetchGetDataType().add(() => {
      this.createColumnForm = this.formBuilder.group({
        name: ['',[Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
        columnType: ['', Validators.required],
        required: [true],
        lookupTable: [''],
        lookupColumn: [''],
        trueTitle: [''],
        falseTitle: [''],
      });
      const name = this.route.snapshot.params['tableName'];
      this.tableName = name;
      this.tableId = this.tablesService.getTableIdByName(name);
      this.tableTitle = this.tablesService.getTableTitle(this.tableId);
      if (this.tableId) {
        const hiddenTables = this.tablesService.hiddenTables();
        this.columnTypeOptions = createDataTypeOptions(
          this.columnsService.getDataType()
        );

        if (!hiddenTables.some((table) => table.id === this.tableId)) {
          this.columnTypeOptions = this.columnTypeOptions.filter(
            (option) => option.title != 'json'
          );
        }
        this.columnTypeOptions.push({
          title: 'lookup Table',
          value: '-119',
        });
      }

      if (this.tableId) {
        this.columnsService
          .fetchColumnsByTableId(this.tableId)
          .subscribe((data: CreateColumn[]) => {
            this.columnsService.setColumnsList(data);
            this.columnsToAdd = data;
          });
      }

      // Subscribe to changes in isDisplayDropdown=""
      this.createColumnForm
        .get('columnType')
        .valueChanges.subscribe((value) => {
          this.updateLookupTypeValidators();
        });
    });
  }
  //test

  onMouseOver(col) {
    this.hoveredColByName = col.name;
  }
  onMouseOut() {
    this.hoveredColByName = '';
  }
  backToTables() {
    this.router.navigateByUrl(this.url);
  }

  onSubmit() {
    const {
      name,
      columnType,
      lookupTable,
      lookupColumn,
      trueTitle,
      falseTitle,
      required,
    } = this.createColumnForm.value;
    const tableName = this.route.snapshot.params['tableName'];
    this.tableId = this.tablesService.getTableIdByName(tableName);
    const column = {
      indexId: this.columnsToAdd.length,
      tableId: this.tableId,
      columnId: Number(lookupColumn),
      columnValue: Number(lookupColumn),
      ...(trueTitle != '' && { trueTitle }), // Only include trueTitle if it's not null
      ...(falseTitle != '' && { falseTitle }), // Only include trueTitle if it's not nul
      name: `${removeSpaceAndCapitalizeWords(name)}_name`,
      title: name,
      required,
      type: Number(columnType),
      index: this.columnsToAdd.length,
    };
    this.columnsToAdd.push(column);
    this.createColumnForm = this.formBuilder.group({
      name: ['', Validators.required],
      columnType: ['', Validators.required],
      required: [true],
      lookupTable: [''],
      lookupColumn: [''],
      trueTitle: [''],
      falseTitle: [''],
    });
    if (Number(lookupColumn) === 0) {
      delete column.columnId;
      delete column.columnValue;
    }
    this.isDisplayDropdown = '';
  }
  editCol(col) {
    this.editableColumnDetails = {
      id: col.id,
      index: col.index,
      name: col.name,
      title: col.title,
    };
    this.isEditMode = true;
  }
  deleteCol(col) {
    this.columnsToAdd = this.columnsToAdd.filter(
      (column) => column.name != col.name
    );
    // Update the index field to maintain ascending order
    this.columnsToAdd.forEach((item, index) => {
      item.index = index;
    });
  }

  updateValueTrigger(event: string) {
    this.reset();
    switch (event) {
      case 'lookup Table':
        const options = this.tablesService.getManagementTables();
        this.managementsTablesOptions = createOption(options);
        this.managementsColumnsOptions = [];
        this.isDisplayDropdown = event;
        break;
      case 'boolean':
        this.isDisplayDropdown = event;
        break;
      case 'other':
        this.updateLookupTypeValidators();
        this.isDisplayDropdown = '';
        break;

      default:
        this.columnsService
          .fetchColumnsByTableId(Number(event))
          .subscribe((data: CreateColumn[]) => {
            this.managementsColumnsOptions = createOption(data);
            this.isDisplayDropdown = 'Lookup column';
          });
        break;
    }
  }
  reset() {
    this.createColumnForm.patchValue({ lookupTable: '' });
    this.createColumnForm.patchValue({ lookupColumn: '' });
    this.createColumnForm.patchValue({ trueTitle: '' });
    this.createColumnForm.patchValue({ falseTitle: '' });
  }
  updateLookupTypeValidators() {
    const columnType = this.createColumnForm.get('columnType');
    const lookupTable = this.createColumnForm.get('lookupTable');
    const lookupColumn = this.createColumnForm.get('lookupColumn');
    // Clear previous validators
    lookupTable.clearValidators();
    lookupColumn.clearValidators();
    // Add required validator if isDisplayDropdown="" is true
    if (this.isDisplayDropdown === '') {
      lookupTable.clearValidators();
      lookupColumn.clearValidators();
      columnType.setValidators([Validators.required]);
    } else if (this.isDisplayDropdown === 'lookup Table') {
      lookupTable.setValidators([Validators.required]);
      lookupColumn.setValidators([Validators.required]);
    }
    // Update the control
    lookupColumn.updateValueAndValidity();
    lookupTable.updateValueAndValidity();
  }
  saveChangeColumn() {
    this.columnsToAdd = this.columnsToAdd.map((col) => {
      const { name, id, index, title } = this.editableColumnDetails;
      if (col.id) {
        if (col.id === id) {
          return {
            ...col,
            name: removeSpaceAndCapitalizeWords(name),
            title: title,
          };
        }
        return { ...col };
      }
      if (col.index === index) {
        return {
          ...col,
          name: removeSpaceAndCapitalizeWords(name),
          title: title,
        };
      }
      return { ...col };
    });
    this.isEditMode = false;
  }
  cancelChangeColumn() {
    this.isEditMode = false;
  }

  drop(event: CdkDragDrop<any>) {
    moveItemInArray(this.columnsToAdd, event.previousIndex, event.currentIndex);
    this.columnsToAdd.forEach((item, index) => {
      item.index = index;
    });
  }
  saveTable() {
    if (this.columnsToAdd.some((col) => col.id)) {
      this.afterSaveColumns(
        this.columnsService.fetchUpdateColumn(
          this.tableId,
          this.columnsToAdd,
          this.navigate
        )
      );
    } else {
      this.afterSaveColumns(
        this.columnsService.fetchAddColumn(
          this.columnsToAdd,
          this.tableId,
          this.navigate
        )
      );
    }
  }
  displayTableName() {
    return upperFirst(`${this.tableName} Table`);
  }

  afterSaveColumns(service) {
    service.add(() => {
      this.tablesService.fetchTablesByProduct().add(() => {
        this.updateHiddenTableSideBar();
      });
    });
  }

  updateHiddenTableSideBar() {
    const tables = this.tablesService.getTablesHistory();
    this.sidebarService.addTablesToSideMenu(
      tables.rows.filter(
        (table: any) => table.numOfColumn > 0 && !table.isVisible
      ),
      'Hidden Tables'
    );
  }
  navigate = () => {
    this.router.navigateByUrl(this.url);
  };
}
