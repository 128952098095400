<div class="content" id="{{ id }}">
  <div class="checked-aws">
    <mat-checkbox
      class="example-margin"
      color="primary"
      [(ngModel)]="checked"
      (change)="onCheckboxChange($event)"
    ></mat-checkbox>
    <div class="header">{{ header }}</div>
  </div>
  @if (checked) {
  <div class="container" [formGroup]="productForm">
    <app-input
      [isFocus]="isFocus()"
      [form]="productForm"
      title="Cognito User Pool ID"
      formName="cognitoPool"
      (handleStepper)="handleStepper.emit(id)"
    ></app-input>
    <app-dropdown
      [form]="productForm"
      [options]="options"
      title="Region"
      formName="cognitoRegion"
      (handleStepper)="handleStepper.emit(id)"
    ></app-dropdown>

    <app-input
      [form]="productForm"
      title="App Client ID"
      formName="cognitoAppId"
      (handleStepper)="handleStepper.emit(id)"
    ></app-input>
  </div>
  }
</div>
