@if (editMode ) {
<div class="field" [formGroup]="form">
  <mat-form-field appearance="outline" class="form-field">
    <mat-select
      (selectionChange)="valueChange($event)"
      formControlName="{{ formName }}"
    >
      <mat-radio-group formControlName="{{ formName }}">
        @for (option of meta.options; track $index) {
        <mat-option [value]="option.value">
          <mat-radio-button
            color="primary"
            [value]="option.value"
          ></mat-radio-button>
          {{ option.title }}</mat-option
        >
        }
      </mat-radio-group>
    </mat-select>
  </mat-form-field>
</div>
}@else {
<div class="title">{{ displayValue }}</div>
}
