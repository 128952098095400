<div class="container">
  <div class="step completed">
    <div class="v-stepper">
      <div class="circle">{{ id }}</div>
      <div
        class="line"
        [ngStyle]="{
          borderLeft: id === 4 && 'none',
          height: id === 3 && '208%'
        }"
      ></div>
    </div>
    <div class="content">
      <ng-container #container></ng-container>
    </div>
  </div>
</div>
