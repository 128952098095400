import { IconType } from '../interfaces/icon';
const pathBase = '../../assets/icons/';
const svgFile = '.svg';

export const ICONS: IconType[] = [
  { name: 'edit', path: `${pathBase}edit${svgFile}` },
  { name: 'trash', path: `${pathBase}trash${svgFile}` },
  { name: 'eye1', path: `${pathBase}eye1${svgFile}` },
  { name: 'icl-logo', path: `${pathBase}icl-logo${svgFile}` },
  { name: 'table', path: `${pathBase}table${svgFile}` },
  { name: 'settings', path: `${pathBase}settings${svgFile}` },
  { name: 'settings-disable', path: `${pathBase}settings-disable${svgFile}` },
  { name: 'builder', path: `${pathBase}builder${svgFile}` },
  { name: 'builder-disable', path: `${pathBase}builder-disable${svgFile}` },
  { name: 'queries', path: `${pathBase}queries${svgFile}` },
  { name: 'queries-disable', path: `${pathBase}queries-disable${svgFile}` },
  { name: 'dots', path: `${pathBase}dots${svgFile}` },
  { name: 'search', path: `${pathBase}search${svgFile}` },
  { name: 'cancel', path: `${pathBase}cancel${svgFile}` },
  { name: 'remove', path: `${pathBase}remove${svgFile}` },
  { name: 'calendar', path: `${pathBase}calendar${svgFile}` },
  { name: 'pdf', path: `${pathBase}pdf${svgFile}` },
  { name: 'minus', path: `${pathBase}minus${svgFile}` },
  { name: 'plus', path: `${pathBase}plus${svgFile}` },
  { name: 'hidden-tables', path: `${pathBase}hidden-tables${svgFile}` },
  { name: 'logout', path: `${pathBase}logout${svgFile}` },
  { name: 'down-arrow', path: `${pathBase}down-arrow${svgFile}` },
  {
    name: 'hidden-tables-disable',
    path: `${pathBase}hidden-tables-disable${svgFile}`,
  },
  {
    name: 'back',
    path: `${pathBase}back${svgFile}`,
  },
  {
    name: 'img',
    path: `${pathBase}image${svgFile}`,
  },
  {
    name: 'svg',
    path: `${pathBase}image${svgFile}`,
  },
  {
    name: 'png',
    path: `${pathBase}image${svgFile}`,
  },
  {
    name: 'pptx',
    path: `${pathBase}document${svgFile}`,
  },
  {
    name: 'xls',
    path: `${pathBase}document${svgFile}`,
  },
  {
    name: 'xlsx',
    path: `${pathBase}document${svgFile}`,
  },
  {
    name: 'docx',
    path: `${pathBase}document${svgFile}`,
  },
];
