<div class="container">
  <div class="boxes">
    @for (box of boxes; track $index) {
    <app-dashboard-box class="box" [box]="box"></app-dashboard-box>
    }
  </div>
  <div class="tableContainer">
    <app-table [rows]="this.productService.getProductList().rows" [columns]="columns" [header]="header"
      [displayColumns]="displayColumns" [buttonDetails]="buttonDetails" (action)="AddProductDefinition()"
      (handleActionByName)="handleActionByName($event)" (handleRowClick)="handleRowClick($event)"></app-table>
  </div>
</div>