import { Injectable, signal } from '@angular/core';
import { Menu } from '../interfaces/menu';
import {
  capitalizeFirstLetterOfEachWord,
  removeSpaceAndCapitalizeWords,
} from '../utils/strings';
import { PRODUCT_DETAILS } from '../types/constant';

const ALL_PRODUCTS = 'All products';
@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  public sidebarMenu = signal(null);
  public titleToolbar = signal([]);
  public productName = signal('');
  public activeStepper = signal<string>(PRODUCT_DETAILS);
  constructor() {}
  setActiveStepper(active: string) {
    this.activeStepper.set(active);
  }
  getActiveStepper(): string {
    return this.activeStepper();
  }
  getMenu() {
    return this.sidebarMenu();
  }
  getTitleToolbar() {
    return this.titleToolbar();
  }
  removeLastSegment(url) {
    var segments = url.split('/');
    segments.pop(); // Remove the last segment
    return segments.join('/');
  }

  setTitleToolbar(breadcrumb: any) {
    let urls = breadcrumb.split('/');
    const arr = [];
    var originalUrl = breadcrumb;
    for (let i = urls.length - 1; i > 0; i--) {
      if (urls[i] !== '') {
        arr.push({
          link: originalUrl,
          text: capitalizeFirstLetterOfEachWord(urls[i]),
        });
        originalUrl = this.removeLastSegment(originalUrl);
      }
    }
    this.titleToolbar.set(arr.reverse());
  }
  setProductName(name) {
    if (!isNaN(this.titleToolbar()[1])) {
      this.titleToolbar()[1] = name;
    }
  }

  updateDisabled(isDisabled: boolean, withoutRoute: string = ALL_PRODUCTS) {
    this.sidebarMenu.update((prev) =>
      prev.map((item) => {
        if (item.name === withoutRoute || item.name === ALL_PRODUCTS) {
          return {
            ...item,
            disabled: false,
          };
        }
        return {
          ...item,
          disabled: isDisabled,
        };
      })
    );
  }

  expandedMenu(url) {
    this.sidebarMenu.update((prev) =>
      prev.map((item) => {
        if (item.url === url) {
          return {
            ...item,
            isExpanded: true,
          };
        }
        return {
          ...item,
          isExpanded: false,
        };
      })
    );
  }
  addTablesToSideMenu(tablesList: any, tabName: string) {
    this.sidebarMenu.update((prev) =>
      prev.map((item) => {
        if (item.name === tabName) {
          const list: Menu[] =
            tablesList?.map((table, index) => {
              return {
                id: table.id,
                name: table.name,
                title: table.title,
              };
            }) || [];
          return {
            ...item,
            children: list,
          };
        }
        return { ...item };
      })
    );
  }

  drawTextMenu(parentName, childeTitle, isRemoveSpace: boolean = false) {
    this.sidebarMenu.update((prev) =>
      prev.map((item) => {
        if (item.name === parentName) {
          item.children.forEach((child) => {
            const title = isRemoveSpace
              ? removeSpaceAndCapitalizeWords(child.title)
              : child.title;
            if (title === childeTitle) {
              child.selected = true;
            } else {
              child.selected = false;
            }
          });
        }
        return {
          ...item,
        };
      })
    );
  }

  drawTextMenuByName(parentName, childeTitle) {
    this.sidebarMenu.update((prev) =>
      prev.map((item) => {
        if (item.name === parentName) {
          item.children.forEach((child) => {
            const name = removeSpaceAndCapitalizeWords(child.name);
            if (name === childeTitle) {
              child.selected = true;
            } else {
              child.selected = false;
            }
          });
        }
        return {
          ...item,
        };
      })
    );
  }
}
