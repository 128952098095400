import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { checkForErrorsIn } from '../../../utils/validations';
import { AbstractControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrl: './date.component.scss',
})
export class DateComponent implements OnInit {
  @Input() editMode: boolean;
  @Input() value: number;
  @Input() form: FormGroup;
  @Input() formName: string = '';
  dateString: any;
  ngOnInit(): void {
    const date = this.value ? new Date(+this.value) : '';
    this.dateString = date ? date.toLocaleDateString('en-GB') : '';
  }
  valueChange(val) {
    this.form.patchValue({
      [this.formName]: val,
    });
  }
  checkForErrorsIn(formControl: AbstractControl, field: string): string {
    return checkForErrorsIn(formControl, field);
  }
}
