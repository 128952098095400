<div class="container">
  <div class="container-header">
    <div mat-dialog-title class="title">{{ data.title }}</div>
    <mat-icon class="icon-button" svgIcon="cancel" mat-dialog-close />
  </div>
  <div mat-dialog-content class="container-content">
    @if(files.length === 0 || !files[0].name ){

    <div class="content" (click)="fileInput.click()">
      <div class="text">Click to browse your files</div>

      <input type="file"
        accept="image/png, image/jpeg , image/jpg,.jpg .pdf,application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .doc, .docx, .xls, .xlsx "
        #fileInput style="display: none" (change)="onFileSelected($event)" />
    </div>
    } @if(files.length >0 && files[0].name){

    <div class="content-all-file">
      <div class="row">
        <div class="icon-title">
          <!-- <mat-icon [svgIcon]="files[0].type" class="icon" /> -->
          <div class="title">
            <div class="text">{{ files[0].name }}</div>
            <!-- <div class="size">{{ files[0].size.toFixed(2) }}Kb</div> -->
          </div>
        </div>
        <mat-icon class="cancel" svgIcon="trash" (click)="removeFile()" />
      </div>
      <mat-divider></mat-divider>
    </div>
    }
  </div>

  <div mat-dialog-actions class="actions">
    <button class="done" [mat-dialog-close]="{ val: files, isSave: true }" mat-button>
      {{ files.length === 0 || !files[0].name ? "save" : data.titleSubmit }}
    </button>
  </div>
</div>