import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Button } from '../../../../interfaces/components';
import { ActionRow, Column, Row } from '../../../../interfaces/table';
import { ProductsService } from '../../../../services/products.service';
import { TablesService } from '../../../../services/tables.service';
import { DialogComponent } from '../../../widgets/dialog/dialog.component';

@Component({
  selector: 'app-table-history',
  templateUrl: './table-history.component.html',
  styleUrl: './table-history.component.scss',
})
export class TableHistoryComponent implements OnInit {
  constructor(
    public tablesService: TablesService,
    public productsService: ProductsService,
    public dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    const { columns, header } = this.tablesService.getTablesHistory();
    this.columns = columns;
    this.header = header;
    this.displayColumns = columns.map((item) => item.name);
  }
  rows: Row[] = [];
  columns: Column[] = [];
  header: string;
  displayColumns: string[] = [];
  isDisabledButton = false;
  tableCreatedMode = false;
  buttonDetails: Button = {
    title: 'Add new table',
    icon: 'add',
  };

  openDialog(id): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Delete Table',
        content:
          'Are you sure you want to delete? After we delete, data cannot be recovered.',
      },
      width: '18%',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.tablesService.fetchDeleteTable(id);
      }
    });
  }
  handleActionByName(event: ActionRow) {
    if (event.actionName === 'DELETE_TABLE') {
      this.openDialog(event.row.id);
    }
  }
  handleRowClick(row) {
    const productName = this.productsService.productName();
    this.router.navigateByUrl(
      `products/${productName}/table_builder/${row.name}`
    );
  }
  AddProductDefinition() {
    this.tableCreatedMode = true;
  }
}
