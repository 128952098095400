<form class="main" [formGroup]="reportForm" (ngSubmit)="reportFilter()">
  <div class="create-filter">
    <div class="table-name">{{ displayHeader() }}</div>
    <div class="column-display">
      <div class="field display">
        <div class="title">Column Display</div>
        <mat-form-field appearance="outline" class="form-field">
          <mat-select formControlName="columnDisplay" multiple>
            <mat-option [value]="'selectAll'" (click)="selectAll()"
              >select all</mat-option
            >
            @for (option of options; track option) {
            <mat-option
              [value]="option.name"
              (click)="selectOption(option.title)"
              >{{ option.title }}</mat-option
            >
            }
          </mat-select>
        </mat-form-field>
      </div>
      <app-button
        height="40px"
        [customBtnColor]="customBtnColor"
        paddingInline="35px"
        (action)="saveColumnDisplay()"
        title="Save"
        [disabled]="!(reportForm.controls['columnDisplay'].value.length > 0)"
      ></app-button>
    </div>
    <div formArrayName="nestedForms">
      @for (item of nestedForms.controls ; track item ; let idx = $index) {
      <div class="container" [formGroupName]="idx">
        <div
          class="inputs"
          [ngStyle]="{
            border: idx === nestedForms.length - 1 ? '1px solid #cbcdce' : 0
          }"
        >
          <div class="field">
            <div class="title">Column</div>
            <mat-form-field appearance="outline" class="form-field">
              <mat-select
                formControlName="column"
                (selectionChange)="valueChange($event, idx, 'column')"
                [disabled]="idx - 1 != rowNumber"
              >
                <mat-radio-group formControlName="column">
                  @for (option of item.value['columnsOptions']; track $index) {
                  <mat-option
                    [value]="option.name"
                    (click)="columnSelected(option.value, idx)"
                  >
                    <mat-radio-button
                      color="primary"
                      [value]="option.name"
                    ></mat-radio-button>
                    {{ option.title }}</mat-option
                  >
                  }
                </mat-radio-group>
              </mat-select>
            </mat-form-field>
          </div>
          @if (item.value['actionOptions']
          &&item.value['actionOptions'][0]?.value === 'date') {
          <div class="container-datepicker">
            <mat-form-field appearance="outline">
              <mat-date-range-input [rangePicker]="picker">
                <input
                  matStartDate
                  formControlName="startDate"
                  placeholder="Start date"
                />
                <input
                  matEndDate
                  formControlName="endDate"
                  placeholder="End date"
                />
              </mat-date-range-input>
              <mat-datepicker-toggle matIconSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
              </mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>

          }@else {

          <div class="field">
            <div class="title">Action</div>
            <mat-form-field appearance="outline" class="form-field">
              <mat-select
                formControlName="action"
                (selectionChange)="valueChange($event, idx, 'action')"
              >
                <mat-radio-group formControlName="action">
                  @for (act of item.value['actionOptions']; track $index) {
                  <mat-option [value]="act.value">
                    <mat-radio-button
                      color="primary"
                      [value]="act.value"
                    ></mat-radio-button>
                    {{ act.title }}</mat-option
                  >
                  }
                </mat-radio-group>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="field">
            <div class="title">Add text/number</div>
            <mat-form-field appearance="outline">
              <input matInput formControlName="text" class="container" />
            </mat-form-field>
          </div>

          }
        </div>
        @if (idx === nestedForms.length - 1 ) {

        <div class="action-forms">
          @if (idx < columnDisplay.length -1 ){

          <div class="btn" (click)="addNestedForm()">
            <mat-icon svgIcon="plus" />
          </div>
          } @if (idx > 0 ){
          <div class="btn remove" (click)="removeNestedForm(idx)">
            <mat-icon svgIcon="minus" />
          </div>

          }
        </div>
        }
      </div>
      }
    </div>

    <div class="action">
      <app-button
        type="submit"
        title="Filter"
        width="90px"
        [disabled]="!reportForm.valid"
      ></app-button>
    </div>
  </div>
</form>
