import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { MaterialModule } from '../../../material/material.module';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrl: './chip.component.scss',
})
export class ChipComponent {
  @Input() title: any = '';
  @Output() removeEmail = new EventEmitter<string>();
}
