import { Component, OnInit } from '@angular/core';
import { Box } from '../../../interfaces/components';
import { boxesDataAdmin } from '../../../mockData/products';
import { ProductsService } from '../../../services/products.service';
import { Product } from '../../../interfaces/products';
import { UsersService } from '../users/users.service';
import { GoogleAnalyticsDetails } from './analytics/analytics.component';
import { getTodayDate } from '../../../utils/date';
import { capitalizeFirstLetterOfEachWord } from '../../../utils/strings';

@Component({
  selector: 'app-products-page',
  templateUrl: './products-page.component.html',
  styleUrl: './products-page.component.scss',
})
export class ProductsPageComponent implements OnInit {
  constructor(
    private productService: ProductsService,
    private usersService: UsersService
  ) {}
  boxes: Box[] = [];
  product: Product;
  fromDate: string = '2017-01-01';
  toDate: string = this.getISOSDate(new Date());
  platformFilter: string = 'all';
  activeUsersCount = '0';
  isClicked = false;

  handleActiveUsers(data: string) {
    this.activeUsersCount = data;
  }
  handleGoogleAnalyticsDetails(data: GoogleAnalyticsDetails) {
    const key = Object.keys(data)[0];
    this.boxes.find((box) => box.name === Object.keys(data)[0]).value =
      data[key];
  }
  displayTitle(title: string) {
    return capitalizeFirstLetterOfEachWord(title);
  }
  getISOSDate(yourDate: Date) {
    let y = yourDate.toISOString().split('T')[0];
    const offset = yourDate.getTimezoneOffset();
    yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
    return y;
  }
  ngOnInit(): void {
    this.fromDate = this.getISOSDate(
      new Date(new Date().setMonth(new Date().getMonth() - 1))
    );
    this.toDate = this.getISOSDate(new Date());
    this.boxes = boxesDataAdmin;
    const userProduct = this.usersService.getUserProduct();
    this.productService.setProduct(userProduct);
    this.product = this.productService.getProduct();
  }
  handleFromDate(date) {
    this.fromDate = getTodayDate(date);
  }
  handleToDate(date) {
    this.toDate = getTodayDate(date);
  }
}
