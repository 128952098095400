import { Component, OnInit } from '@angular/core';
import { ProductsService } from '../../../services/products.service';
import { Router } from '@angular/router';
import { MASTER_DATA_URL, REPORT } from '../../../types/constant';

@Component({
  selector: 'app-master-data-layout',
  templateUrl: './master-data-layout.component.html',
  styleUrl: './master-data-layout.component.scss',
})
export class MasterDataLayoutComponent implements OnInit {
  constructor(
    public productsService: ProductsService,
    private router: Router
  ) {}
  ngOnInit(): void {
    const urlArr = this.router.url.split('/');
    const tabName = urlArr[urlArr.length - 1];
    if (tabName === 'report') {
      const reportTables = this.productsService.getReportTables();
      if (reportTables.length > 0) {
        this.router.navigateByUrl(
          `/products_page/report/${reportTables[0].name}`
        );
      }
    } else if (tabName === 'master_data') {
      const managementTables = this.productsService.getManagementTables();
      if (managementTables.length > 0) {
        const managementTablesLocal = managementTables.filter(
          (table) => table.isVisible
        );
        if (managementTablesLocal.length > 0) {
          this.router.navigateByUrl(
            `/products_page/master_data/${managementTablesLocal[0].name}`
          );
        }
      }
    } else if (tabName === 'users') {
      this.router.navigateByUrl(`/products_page/users/users`);
    }
  }
}
