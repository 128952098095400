<div class="container">
  <div class="container1">
    <div class="container-name">
      <div class="icon">{{ userName[0] }}</div>
      <div class="name">{{ userName }}</div>
    </div>
    <mat-icon
      class="menu-icon"
      svgIcon="dots"
      (click)="menuOpened = !menuOpened"
    />
  </div>
  @if (menuOpened) {
  <div class="menu" (click)="logout()">
    <mat-icon class="menu-icon" svgIcon="logout" />
    <div>Log out</div>
  </div>
  }
</div>
