import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private msalAuthService: MsalService) {}
  private scopes: string[] = ['user.read', 'openid', 'profile'];
  public getTokenFromLocalStorage() {
    return localStorage.getItem('jwt');
  }
  public setTokenLocalStorage(key, value) {
    localStorage.setItem(key, value);
  }

  public logout() {
    this.msalAuthService.logoutRedirect({
      postLogoutRedirectUri: "https://fs-cms-dev.appsc.icldig.icl-group.com/"
    });
    
    this.clearTokenLocalStorage('jwt');
  }
  private clearTokenLocalStorage(key: string) {
    localStorage.removeItem(key);
  }
  async checkAndRefreshToken(): Promise<void> {
    const accounts = this.msalAuthService.instance.getAllAccounts();
    if (accounts.length > 0) {
      const account = accounts[0];
      try {
        const response = await this.msalAuthService.instance.acquireTokenSilent({
          account: account,
          scopes: this.scopes
        });
        this.setTokenLocalStorage('jwt', response.idToken);
      } catch (error) {
        console.error('Silent token acquisition failed, falling back to interactive login', error);
        this.clearTokenLocalStorage('jwt');
        this.msalAuthService.loginRedirect({
          scopes: this.scopes 
        });
      }
    } else {
      this.clearTokenLocalStorage('jwt');
      this.msalAuthService.loginRedirect({
        scopes: this.scopes 
      });
    }
  }

}
