import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

export type Content = {
  title: string;
  text: string;
};

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor() {}

  successPopup(content: Content, action?: any) {
    Swal.fire({
      title: content.title,
      text: content.text,
      icon: 'success',
    }).then((res) => {
      if (res.isConfirmed) {
        if (action) {
          action();
        }
      }
    });
  }
  errorPopup(content: Content, action?: any) {
    Swal.fire({
      title: content.title,
      text: content.text,
      icon: 'error',
    }).then((res) => {
      if (res.isConfirmed) {
        if (action) {
          action();
        }
      }
    });
  }

  successSnackbar(content: string) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-start',
      showConfirmButton: false,
      width: '25%',
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    });
    Toast.fire({
      icon: 'success',
      title: content,
    });
  }
}
