<div class="container-header">
  <div mat-dialog-title class="title">{{ data.title }}</div>
  <mat-icon class="icon-button" svgIcon="cancel" mat-dialog-close />
</div>
<div mat-dialog-content class="content">{{ data.content }}</div>
<div mat-dialog-actions class="actions">
  <button
    class="submit btn"
    [mat-dialog-close]="true"
    mat-button
    cdkFocusInitial
  >
    {{ data.submitName || "Yes" }}
  </button>
  <button class="cancel btn" mat-button mat-dialog-close>No</button>
</div>
