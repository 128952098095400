import { Component, Inject, OnInit } from "@angular/core";
import { DialogData } from "../../../interfaces/dialog";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { BYTES } from "../../../types/constant";

@Component({
  selector: "app-file-dialog",
  templateUrl: "./file-dialog.component.html",
  styleUrl: "./file-dialog.component.scss",
})
export class FileDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<FileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}
  files: any[] = [];
  ngOnInit(): void {
    this.files = this.data.value || [{ name: "" }];
  }
  onFileSelected(event: any): void {
    // Handle the selected file here
    const selectedFile = event.target.files[0];
    const typeSplit = selectedFile.name.split(".");
    console.log("selectedFile", selectedFile);
    console.log(
      selectedFile.name,
      selectedFile.size / BYTES,
      typeSplit[typeSplit.length - 1],
      "selectedFile with size and type"
    );

    this.files = [
      {
        name: `${this.data.baseUrl}.${typeSplit[typeSplit.length - 1]}`,
        size: selectedFile.size / BYTES,
        type: typeSplit[typeSplit.length - 1],
        file: selectedFile,
      },
    ];
  }
  removeFile(index: number = 0) {
    const indexToDeleted = this.files.findIndex(
      (item, indexItem) => indexItem === index
    );
    if (indexToDeleted != -1) {
      this.files.splice(indexToDeleted, 1);
    }
  }
  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }
  // onDrop(event: DragEvent): void {
  //   event.preventDefault();

  //   const droppedFiles: FileList = event.dataTransfer?.files;

  //   Array.from(droppedFiles).forEach((file) => {
  //     this.files.push({
  //       name: file.name,
  //       size: file.size / 1024,
  //       type: file.name.split('.')[1],
  //       file: event,
  //     });
  //   });
  // }
}
