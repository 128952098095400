<div class="container-header">
  <div mat-dialog-title class="title">{{ data.title }}</div>
  <mat-icon class="icon-button" svgIcon="cancel" mat-dialog-close />
</div>
<div mat-dialog-content class="content-container">
  <div class="content">
    {{ data.value }}
  </div>
  <div>
    <div class="header">
      {{ data.header }}
    </div>
    <div class="content">
      {{ data.content }}
    </div>
  </div>
</div>

<div mat-dialog-actions class="actions">
  <button
    class="done"
    [mat-dialog-close]="{ val: data.value, isSave: true }"
    mat-button
    cdkFocusInitial
  >
    {{ data.titleSubmit }}
  </button>
</div>
