import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { region } from '../../../../mockData/dropdown';
import { AWS_COGNITO } from '../../../../types/constant';
import { SidebarService } from '../../../../services/sidebar.service';

@Component({
  selector: 'app-aws-cognito',
  templateUrl: './aws-cognito.component.html',
  styleUrl: './aws-cognito.component.scss',
})
export class AwsCognitoComponent implements OnInit {
  constructor(public sidebarService: SidebarService) {}

  @Input() productForm: FormGroup;
  @Input() header: string = '';
  @Output() handleStepper = new EventEmitter<string>();

  options;
  id = AWS_COGNITO;

  checked: boolean = false;
  ngOnInit(): void {
    this.options = region;
    this.checked = this.productForm.get('isUsingCognito').value;
  }

  onCheckboxChange(event: any) {
    this.productForm.patchValue({ isUsingCognito: this.checked });
    this.checked = event.checked;
    this.handleStepper && this.handleStepper.emit(this.id);
  }
  isFocus() {
    return this.id === this.sidebarService.getActiveStepper();
  }
}
