import { Injectable, signal } from '@angular/core';
import { HttpHandlerService } from '../../../services/http-handler.service';
import { Role, RolesTable } from '../../../interfaces/roles';
import { columnsRoles } from '../../../mockData/roles';
import { FormBuilder, FormGroup } from '@angular/forms';
import { createDynamicForm } from '../../../utils/table';
import { AlertService } from '../../../services/alert.service';
import {
  ADDING_SUCCESS,
  DELETED_SUCCESS,
  UPDATED_SUCCESS,
} from '../../../types/constant';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  constructor(
    private httpHandler: HttpHandlerService,
    private formBuilder: FormBuilder,
    private alertService: AlertService
  ) {}
  public = signal<Role[]>(null);
  public rolesTables = signal<RolesTable>(null);
  private rolesForm: FormGroup;

  fetchRoles(productId) {
    return this.httpHandler
      .get(`design/products/${productId}/roles`)
      .subscribe((data: Role[]) => {
        this.setRolesTable(data);
      });
  }
  getRolesTable() {
    return this.rolesTables();
  }
  setRolesTable(roles: Role[]) {
    const tempRolesTable: RolesTable = {
      header: 'Roles',
      rows: roles,
      columns: columnsRoles,
    };
    this.rolesTables.set(tempRolesTable);
  }
  getRolesForm() {
    return this.rolesForm;
  }
  setRolesForm(columns, roles) {
    this.rolesForm = this.formBuilder.group(createDynamicForm(columns, roles));
  }

  fetchDeleteRole(role, productId) {
    this.httpHandler
      .delete(`design/products/${productId}/roles/${role.id}`)
      .subscribe(() => {
        this.alertService.successSnackbar(DELETED_SUCCESS);
        this.rolesTables.update((prev) => {
          const roles = prev.rows.filter((roleRes) => roleRes.id !== role.id);
          return {
            ...prev,
            rows: roles,
          };
        });
      });
  }

  fetchAddRole(role: Role, productId: number) {
    return this.httpHandler
      .post(`design/products/${productId}/roles`, [role])
      .subscribe((roleRes: Role[]) => {
        this.alertService.successSnackbar(ADDING_SUCCESS);
        this.addRoleList(roleRes[0]);
      });
  }
  addRoleList(role) {
    this.rolesTables.update((prev: RolesTable) => {
      return {
        ...prev,
        rows: [...prev.rows, role],
      };
    });
  }

  updateRolesTable(role: Role, productId: number) {
    delete role.editMode;
    this.httpHandler
      .patch(`design/products/${productId}/roles/${role.id}`, role)
      .subscribe((roleRes: Role) => {
        this.alertService.successSnackbar(UPDATED_SUCCESS);
        this.rolesTables.update((prev: RolesTable) => {
          const newRows = prev.rows.map((item) => {
            if (item.id === roleRes[0].id) {
              return {
                ...roleRes[0],
              };
            }
            return {
              ...item,
            };
          });
          return {
            ...prev,
            rows: [...newRows],
          };
        });
      });
  }
}
