import { Injectable, computed, signal } from "@angular/core";
import { Router } from "@angular/router";
import { Product, ProductList, ProductSummary } from "../interfaces/products";
import { HttpHandlerService } from "./http-handler.service";
import { SidebarService } from "./sidebar.service";
import { columnsSuper } from "../mockData/products";
import { Schema } from "../interfaces/schema";
import { lightBlue, lightGreen, lightRed } from "../../assets/colors";
import { ADMIN_ROLE, SUCCESS_DELETED } from "../types/constant";
import { UsersService } from "../components/pages/users/users.service";
import { CreateTable } from "../interfaces/table";
import { AlertService } from "./alert.service";

const ACTIVE = "active";
@Injectable({
  providedIn: "root",
})
export class ProductsService {
  constructor(
    private sidebarService: SidebarService,
    private httpHandler: HttpHandlerService,
    private router: Router,
    private usersService: UsersService,
    private alertService: AlertService
  ) {}
  public product = signal<Product | null>(null);
  private productList = signal<ProductList | null>(null);
  private managementTables = signal<CreateTable[] | null>(null);
  private reportTables = signal<CreateTable[] | null>(null);
  public totalProducts = computed(() => this.productList().rows.length);
  public totalActive = computed(
    () =>
      this.productList().rows.filter((product) => product.status === ACTIVE)
        .length
  );
  public totalNotActive = computed(
    () =>
      this.productList().rows.filter((product) => product.status != ACTIVE)
        .length
  );
  public productId = computed(() => this.product().id);
  public productName = computed(() => this.product()?.name);
  public productTitle = computed(() => this.product().title);

  getProduct() {
    return this.product();
  }

  setProductsList(products: ProductList) {
    this.productList.set(products);
  }

  setProduct(product: Product) {
    this.product.set(product);
    if (!product) {
      this.sidebarService.updateDisabled(true);
      // this.sidebarService.resetMenu();
    }
  }
  getProductList() {
    return this.productList();
  }

  getProductById(id: number) {
    return this.productList()?.rows.find((product) => product.id === id);
  }
  fetchGetProductByProductId(productId: number) {
    return this.httpHandler
      .get(`design/products/${productId}`)
      .subscribe((res: Product) => {
        this.usersService.userProducts.set(res);
      });
  }
  fetchGetSchemaByProductId(productId: number) {
    return this.httpHandler.get(`design/products/${productId}/schemas`);
  }
  fetchHiddenTablesByProductId() {
    const productId = this.productId();
    return this.httpHandler.get(`design/products/${productId}/tables`);
  }
  fetchProductsRole(productId) {
    return this.httpHandler.get(`design/products/${productId}/roles`);
  }
  fetchProductTablesByProductId(productId) {
    return this.httpHandler
      .get(`design/products/${productId}/tables`)
      .subscribe((tables: CreateTable[]) => {
        const table = tables.filter((item) => item.numOfColumn > 0);
        if (table) {
          this.managementTables.set(table.filter((item) => !item.readOnly));
          this.reportTables.set(table.filter((item) => item.readOnly));
        }
      });
  }
  public getAnalytics(params) {
    return this.httpHandler.post("analytics", params);
    // return this.httpHandler.postPublic("analytics", params);
  }
  public getFiltredProductReports(params) {
    return this.httpHandler.post("analytics/byPlatform", params);
  }
  getReportTables() {
    return this.reportTables();
  }
  getManagementTables() {
    return this.managementTables();
  }
  fetchGetProducts() {
    return this.httpHandler.get("design/products");
  }
  fetchProducts() {
    return this.httpHandler
      .get("summary")
      .subscribe((products: ProductSummary[]) => {
        const productWithAdmins = products.map((product) => {
          return {
            ...product,
            systemAccess: product.systemAccess.map(
              (productUserMap: any) => productUserMap.systemUser.externalUserId
            ),
          };
        });
        const productsList = {
          header: "Products",
          rows: productWithAdmins,
          columns: columnsSuper,
        };
        this.setProductsList(productsList);
      });
  }

  fetchAssociateAdmins(productId, admins) {
    const data = admins.map((admin) => {
      return {
        productId: productId,
        roleId: ADMIN_ROLE,
        access: true,
        externalUserId: admin,
      };
    });
    return this.httpHandler.post(`users/register/active-directory`, data);
  }
  fetchUpdateAssociateAdmins(productId, admins, userDeleted) {
    let data = admins.map((admin) => {
      return {
        productId: productId,
        access: true,
        roleId: ADMIN_ROLE,
        externalUserId: admin,
      };
    });
    if (userDeleted.length > 0) {
      const dataDeleted = userDeleted.map((admin) => {
        return {
          productId: productId,
          access: false,
          roleId: ADMIN_ROLE,
          externalUserId: admin,
        };
      });
      data = [...data, ...dataDeleted];
    }
    return this.httpHandler.post(`users/register/active-directory`, data);
  }

  fetchAddProduct(product: Product) {
    return this.httpHandler.post("design/products", product);
  }
  fetchAddSchema(schema: Schema) {
    return this.httpHandler.post("design/schemas", schema);
  }
  addProductToProductsList(productRes) {
    this.productList.update((prev: ProductList) => {
      return {
        ...prev,
        rows: [...this.productList().rows, productRes],
      };
    });
  }
  fetchUpdateProduct(product: Product) {
    return this.httpHandler.patch(`design/products/${product.id}`, product);
  }

  updateProduct(product: ProductSummary) {
    this.productList.update((prev: ProductList) => {
      const newRows = prev.rows.map((item) => {
        if (item.id === product.id) {
          return {
            ...product,
          };
        }
        return {
          ...item,
        };
      });
      return {
        ...prev,
        rows: [...newRows],
      };
    });
  }

  deleteProduct(productId) {
    this.httpHandler
      .delete(`design/products/${productId}`)
      .subscribe((data) => {
        this.fetchProducts().add(() => {
          this.setProduct(null);
          this.alertService.successPopup(
            SUCCESS_DELETED,
            this.router.navigateByUrl(`/products`)
          );
        });
      });
  }

  updateRow(updatedObject: ProductSummary[]) {
    this.productList.update((prev) => {
      return {
        ...prev,
        rows: updatedObject,
      };
    });
  }
  changeRowMode(id: number, mode: boolean) {
    this.productList.update((prev: ProductList) => {
      const temp = prev.rows.find((item) => item.id === id);
      temp.editMode = mode;
      return {
        ...prev,
      };
    });
  }

  createBoxes() {
    const boxes = [
      {
        title: "Total product",
        value: this.totalProducts(),
        color: lightBlue,
      },
      {
        title: "Active product",
        value: this.totalActive(),
        color: lightGreen,
      },
      {
        title: "Not active product",
        value: this.totalNotActive(),
        color: lightRed,
      },
    ];
    return boxes;
  }
}
