import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DataRange } from '../interfaces/date';

export const DATE_BUTTONS = [
  {
    title: 'Last 7 days',
    selected: false,
    days: 7,
  },
  {
    title: 'Last 30 days',
    selected: true,
    days: 30,
  },
  {
    title: 'Last 90 days',
    selected: false,
    days: 90,
  },
  {
    title: 'Last 12 months',
    selected: false,
    days: 365,
  },
  {
    title: 'Custom range',
    selected: false,
    days: 2,
  },
];

export function getLastDays(days): DataRange {
  const today = new Date();
  const lastDays = new Date(today);

  lastDays.setDate(lastDays.getDate() - days);

  const From = new NgbDate(
    lastDays.getFullYear(),
    lastDays.getMonth() + 1,
    lastDays.getDate()
  );

  const To = new NgbDate(
    today.getFullYear(),
    today.getMonth() + 1,
    today.getDate()
  );

  return { From, To };
}
export function getTodayDate(days) {
  var date = new Date(days);

  // Get year, month, and day part from the date
  var year = date.toLocaleString('default', { year: 'numeric' });
  var month = date.toLocaleString('default', { month: '2-digit' });
  var day = date.toLocaleString('default', { day: '2-digit' });

  // Generate yyyy-mm-dd date string
  var formattedDate = year + '-' + month + '-' + day;
  return formattedDate;
}
export function convertNgbDateToDate(date): Date {
  return new Date(date.year, date.month - 1, date.day);
}
export function convertDateToNgbDate(date, dateNg) {
  const dateComponents = date.split('-');
  const fromYear = parseInt(dateComponents[0], 10);
  const frommMonth = parseInt(dateComponents[1], 10);
  const fromDay = parseInt(dateComponents[2], 10);
  dateNg.year = fromYear;
  dateNg.month = frommMonth;
  dateNg.day = fromDay;
}
