<div class="container">
  @if (this.columns.length > 0 && isActionTable) {
  <app-filter-result
    [columns]="this.columns"
    [header]="header"
    (filterData)="saveChanges($event)"
  ></app-filter-result>
  }
  <div class="tableContainer">
    @if (tablesService.getDynamicTable()?.rows ) {
    <app-table
      [rows]="tablesService.getDynamicTable().rows"
      [columns]="columnsFilter"
      [header]="header"
      [displayColumns]="displayColumns"
      (action)="exportTable()"
      [buttonDetails]="buttonDetails"
      [isHoverAction]="false"
      [totalCount]="tablesService.getTotalCount()"
      (handleChangePage)="handleChangePage($event)"
      [isPagination]="true"
      [isActionTable]="isActionTable && isResultFound()"
      [page]="page"
    ></app-table>
    }
  </div>
</div>
