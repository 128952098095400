@if (editMode) {
<div [formGroup]="form">
  <mat-form-field appearance="outline" class="container">
    <input
      (click)="openDialog()"
      matInput
      [formControlName]="formName"
      readonly
    />
  </mat-form-field>
</div>
}@else {
<div class="truncate">
  {{ value }}
</div>
}
