import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
import { SidebarService } from './services/sidebar.service';
import { SvgIconService } from './services/svg-icon.service';
import { UserAuthService } from './services/userAuth.service';
import { ICONS } from './types/iconType';
import { LoadingService } from './services/loading.service';
import { UserAuth } from './interfaces/users';
import { SYSTEM_ROLES } from './types/constant';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalAuthService: MsalService,
    public authService: AuthService,
    private svgIconService: SvgIconService,
    private router: Router,
    private sidebarService: SidebarService,
    private broadcastService: MsalBroadcastService,
    private userAuthService: UserAuthService,
    public loadingService: LoadingService
  ) {
    this.svgIconService.registerIcons(ICONS);
  }

  ngAfterViewInit(): void {}
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  async ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;
    this.broadcastService.inProgress$
      .pipe(
        filter(status => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.authService.checkAndRefreshToken().then(() => {
          this.setLoginDisplay();
        });
      });
  
    this.router.events.subscribe(() => {
      this.sidebarService.setTitleToolbar(this.router.url);
    });
  }
  login() {
    if (!this.loginDisplay) {
      if (this.msalGuardConfig.authRequest) {
        this.msalAuthService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
        } as RedirectRequest);
      } else {
        this.msalAuthService.loginRedirect();
      }
    }
  }

  setLoginDisplay() {
    const res = this.msalAuthService.instance.getAllAccounts();    
    if (res.length > 0) {
      this.userAuthService.fetchGetUser().subscribe(() => {
        const user: UserAuth = this.userAuthService.getUser();
        const urlArr = this.router.url.split('/');
        if (urlArr[1] === '')
          if (user.roleId === SYSTEM_ROLES.superAdmin) {
            this.router.navigate(['/products']);
          } else if (user.roleId === SYSTEM_ROLES.admin) {
            this.router.navigate(['/products_page']);
          }
        this.loginDisplay = res.length > 0;
      });
    } else {
      this.loginDisplay = false;
    }
  }
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
