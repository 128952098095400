import { Injectable, signal } from '@angular/core';
import { QueriesList, Query } from '../interfaces/queries';
import { mockQueries } from '../mockData/queries';
import { HttpHandlerService } from './http-handler.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { createDynamicForm } from '../utils/table';
import { AlertService } from './alert.service';
import {
  ADDING_SUCCESS,
  DELETED_SUCCESS,
  UPDATED_SUCCESS,
} from '../types/constant';

@Injectable({
  providedIn: 'root',
})
export class QueriesService {
  private queriesList = signal<QueriesList | null>(mockQueries);
  private queryForm: FormGroup;

  constructor(
    private httpHandler: HttpHandlerService,
    private formBuilder: FormBuilder,
    private alertService: AlertService
  ) {}

  getQueriesList() {
    return this.queriesList();
  }
  fetchQueries(productId) {
    return this.httpHandler
      .get(`design/products/${productId}/queries`)
      .subscribe((queriesRes: Query[]) => {
        this.setQueriesToList(queriesRes);
      });
  }

  fetchDeleteQuery(queryId) {
    this.httpHandler.delete(`design/queries/${queryId}`).subscribe(() => {
      this.alertService.successSnackbar(DELETED_SUCCESS);
      this.queriesList.update((prev) => {
        const queries = prev.rows.filter((query) => query.id !== queryId);
        return {
          ...prev,
          rows: queries,
        };
      });
    });
  }
  setQueriesList(queriesList: QueriesList) {
    this.queriesList.set(queriesList);
  }
  setQueriesToList(queriesList: Query[]) {
    this.queriesList.update((prev: QueriesList) => {
      return {
        ...prev,
        rows: [...queriesList],
      };
    });
  }
  fetchAddQuery(query: Query) {
    return this.httpHandler
      .post('design/queries', query)
      .subscribe((queryRes: Query) => {
        this.alertService.successSnackbar(ADDING_SUCCESS);
        this.addQueryList(queryRes);
      });
  }
  addQueryList(query) {
    this.queriesList.update((prev: QueriesList) => {
      return {
        ...prev,
        rows: [...prev.rows, query],
      };
    });
  }
  updateQueryList(query: Query) {
    delete query.editMode;
    this.httpHandler
      .patch(`design/queries/${query.id}`, query)
      .subscribe((queryRes: Query) => {
        this.alertService.successSnackbar(UPDATED_SUCCESS);
        this.queriesList.update((prev: QueriesList) => {
          const newRows = prev.rows.map((item) => {
            if (item.id === queryRes.id) {
              return {
                ...queryRes,
              };
            }
            return {
              ...item,
            };
          });
          return {
            ...prev,
            rows: [...newRows],
          };
        });
      });
  }

  changeRowMode(id: number, mode: boolean) {
    this.queriesList.update((prev: QueriesList) => {
      const temp = prev.rows.find((item) => item.id === id);
      temp.editMode = mode;
      return {
        ...prev,
      };
    });
  }

  getQueryForm() {
    return this.queryForm;
  }

  setQueryForm(columns, query) {
    this.queryForm = this.formBuilder.group(createDynamicForm(columns, query));
  }
}
