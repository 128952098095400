@if(table){
<div class="container">
  <div class="tableContainer">
    @if (tablesService.getDynamicTable()?.rows && dynamicForm) {
    <app-table
      [rows]="tablesService.getDynamicTable().rows"
      [columns]="columns"
      [header]="header"
      [form]="dynamicForm"
      [isAddRow]="isAddRow"
      [isDisabledButton]="isDisabledButton"
      [displayColumns]="displayColumns"
      [buttonDetails]="buttonDetails"
      [totalCount]="tablesService.getTotalCount()"
      (action)="addEmptyRow()"
      (handleActionByName)="handleActionByName($event)"
      [isEditMode]="isEditMode"
      (handleChangePage)="handleChangePage($event)"
      (handleRow)="handleRow($event)"
      [isPagination]="true"
    ></app-table>
    }
  </div>
</div>
}
