<div class="content" id="{{ id }}">
  <div class="header">{{ header }}</div>
  <div class="container">
    <div class="container-1">
      <div class="container-2">
        <mat-label class="title">Email</mat-label>
        <mat-form-field appearance="outline" class="field">
          <input
            [(ngModel)]="email"
            matInput
            (focus)="focus()"
            [appAutofocus]="isFocus()"
          />
          <div matTextSuffix>
            {{ defaultSuffix }}
          </div>
        </mat-form-field>
      </div>
      <div class="btn">
        <app-button
          height="40px"
          [customBtnColor]="customBtnColor"
          paddingInline="1.5vw"
          (action)="addEmail()"
          title="add"
        ></app-button>
      </div>
    </div>
    <div class="emails-group">
      @for (admin of productForm.get('systemAccess').value ; track $index) {
      <app-chip
        (removeEmail)="removeEmail($event)"
        title="{{ admin }}"
      ></app-chip>
      }
    </div>
  </div>
</div>
