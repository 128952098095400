@if (editMode) {
<div class="container-datepicker" [formGroup]="form">
  <app-datepicker
    (valueChange)="valueChange($event)"
    [value]="value"
  ></app-datepicker>
  <mat-error>{{ checkForErrorsIn(form.get(formName), formName) }}</mat-error>
</div>
}@else {

<div class="container">
  @if (dateString) {<mat-icon svgIcon="calendar" />}
  <div class="title">{{ dateString }}</div>
</div>
}
