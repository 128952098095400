import { Component, Input } from '@angular/core';
import { Option } from '../../../interfaces/dropdown';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-button-toggle',
  templateUrl: './button-toggle.component.html',
  styleUrl: './button-toggle.component.scss',
})
export class ButtonToggleComponent {
  @Input() options: Option[];
  @Input() form: FormGroup;
  @Input() formName: string = '';
  @Input() isTitleVal: boolean = false;
}
