import { Option } from '../interfaces/dropdown';
import { dataTypeSelector } from '../utils/table';

export const TABLE_OPTIONS: Option[] = [
  {
    value: '1',
    title: 'Management',
  },
  {
    value: '2',
    title: 'Report',
  },
];

export const COLUMN_TYPE_OPTIONS: Option[] = [
  {
    value: '1',
    title: dataTypeSelector('Integer'),
  },
  {
    value: '2',
    title: dataTypeSelector('DECIMAL'),
  },
  {
    value: '3',
    title: dataTypeSelector('VARCHAR'),
  },
  {
    value: '4',
    title: dataTypeSelector('file'),
  },
  {
    value: '5',
    title: dataTypeSelector('TEXT'),
  },
  {
    value: '6',
    title: dataTypeSelector('bigint'),
  },
  {
    value: '7',
    title: dataTypeSelector('boolean'),
  },
  {
    value: '8',
    title: dataTypeSelector('JSON'),
  },
];

export const region: Option[] = [
  { value: 'us-east-2', title: 'US East (Ohio)' },
  { value: 'us-east-1', title: 'US East (Virginia)' },
  { value: 'us-west-1', title: 'US West (N. California)' },
  { value: 'us-west-2', title: 'US West (Oregon)' },
  { value: 'af-south-1', title: 'Africa (Cape Town)' },
  { value: 'ap-east-1', title: 'Asia Pacific (Hong Kong)' },
  { value: 'ap-south-2', title: 'Asia Pacific (Hyderabad)' },
  { value: 'ap-southeast-3', title: 'Asia Pacific (Jakarta)' },
  { value: 'ap-southeast-4', title: 'Asia Pacific (Melbourne)' },
  { value: 'ap-south-1', title: 'Asia Pacific (Mumbai)' },
  { value: 'ap-northeast-3', title: 'Asia Pacific (Osaka)' },
  { value: 'ap-northeast-2', title: 'Asia Pacific (Seoul)' },
  { value: 'ap-southeast-1', title: 'Asia Pacific (Singapore)' },
  { value: 'ap-southeast-2', title: 'Asia Pacific (Sydney)' },
  { value: 'ap-northeast-1', title: 'Asia Pacific (Tokyo)' },
  { value: 'ca-central-1', title: 'Canada (Central)' },
  { value: 'ca-west-1', title: 'Canada West (Calgary)' },
  { value: 'eu-central-1', title: 'Europe (Frankfurt)' },
  { value: 'eu-west-1', title: 'Europe (Ireland)' },
  { value: 'eu-west-2', title: 'Europe (London)' },
  { value: 'eu-south-1', title: 'Europe (Milan)' },
  { value: 'eu-west-3', title: 'Europe (Paris)' },
  { value: 'eu-south-2', title: 'Europe (Spain)' },
  { value: 'eu-north-1', title: 'Europe (Stockholm)' },
  { value: 'eu-central-2', title: 'Europe (Zurich)' },
  { value: 'il-central-1', title: 'Israel (Tel Aviv)' },
  { value: 'me-south-1', title: 'Middle East (Bahrain)' },
  { value: 'me-central-1', title: 'Middle East (UAE)' },
  { value: 'sa-east-1', title: 'South America (São Paulo)' },
];

export const ACTIONS_SELECTION: Option[] = [
  { title: 'Equal to', value: 'equalTo' },
  { title: 'Bigger than', value: 'biggerThan' },
  { title: 'Smaller than', value: 'smallerThan' },
];
