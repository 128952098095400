import { Component, OnInit } from '@angular/core';
import { SidebarService } from '../../../services/sidebar.service';
import { UsersService } from './users.service';
import { Button } from '../../../interfaces/components';
import { ActionRow, Column, Row } from '../../../interfaces/table';
import { ERROR_ADDING_USER, PAGINATION_SIZE } from '../../../types/constant';
import { addEmptyRow, createOption } from '../../../utils/form';
import { emptyUser } from '../../../mockData/users';
import { ProductsService } from '../../../services/products.service';
import { capitalizeFirstLetterOfEachWord } from '../../../utils/strings';
import { Product } from '../../../interfaces/products';
import { RolesService } from '../roles/roles.service';
import { AlertService } from '../../../services/alert.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrl: './users.component.scss',
})
export class UsersComponent implements OnInit {
  constructor(
    private sidebarService: SidebarService,
    public usersService: UsersService,
    public productsService: ProductsService,
    public rolesService: RolesService,
    private alertService: AlertService
  ) {}
  rows: Row[] = [];
  columns: Column[] = [];
  header: string;
  displayColumns: string[] = [];
  buttonDetails: Button = {
    title: 'Add New User',
  };
  isAddRow = false;
  isEditMode = false;
  rowAddingIndex = 0;
  isDisabledButton = false;
  productId;
  form;
  indexEditRow: number = PAGINATION_SIZE;
  userProduct: Product;

  ngOnInit(): void {
    this.sidebarService.expandedMenu('users');
    this.sidebarService.drawTextMenu('Users', 'users', true);
    this.userProduct = this.usersService.getUserProduct();
    this.productsService
      .fetchProductsRole(this.userProduct.id)
      .subscribe((data) => {
        const options = createOption(data);
        this.usersService
          .fetchGetUsers(this.userProduct.id, options)
          .add(() => {
            const { rows, columns, header } = this.usersService.getUsersTable();
            this.rows = rows;
            this.columns = columns;
            this.header = capitalizeFirstLetterOfEachWord(header);
            this.displayColumns = columns.map((item) => item.name);
            this.usersService.setUsersForm(columns, null);
            this.form = this.usersService.getUsersForm();
          });
      });
  }
  addEmptyUserRow() {
    this.rolesService.fetchRoles(this.userProduct.id).add(() => {
      const { rows } = this.rolesService.getRolesTable();
      if (rows.length > 0) {
        this.rows = this.usersService.getUsersTable().rows;
        addEmptyRow(this.rows, emptyUser, this.indexEditRow);
        this.isAddRow = !this.isAddRow;
        this.rowAddingIndex =
          this.rows.length > this.indexEditRow
            ? this.indexEditRow - 1
            : this.rows.length - 1;
        this.isDisabledButton = true;
      } else {
        this.alertService.errorPopup(ERROR_ADDING_USER);
      }
    });
  }
  handleActionByName(event: ActionRow) {
    if (!this.isAddRow && !this.isEditMode)
      if (event.actionName === 'DELETE_QUERY') {
        this.usersService.fetchDeleteUser(event.row, this.userProduct.id);
      }
  }
  handleRow(event: ActionRow) {
    const form = this.usersService.getUsersForm();
    const data = {
      role_id: form.value.role_id,
      external_user_id: form.value.external_user_id,
    };
    switch (event.actionName) {
      case 'SAVE':
        this.usersService.fetchAddUser(data, this.userProduct.id);
        this.resetEditMode();
        break;
      case 'CANCEL_ADD':
        this.resetEditMode();
        break;
      default:
        break;
    }
    this.usersService.setUsersForm(this.columns, null);
    this.isEditMode = false;
    this.isDisabledButton = false;
    this.form = this.usersService.getUsersForm();
  }
  resetEditMode() {
    const rows = this.usersService.getUsersTable().rows;
    this.isDisabledButton = false;
    this.isAddRow = !this.isAddRow;
    rows.splice(this.rowAddingIndex, 1);
  }
  handleChangePage(pageRes) {
    this.indexEditRow = pageRes.pageSize * (pageRes.pageIndex + 1);
    this.isEditMode = false;
    this.isAddRow = false;
  }
}
