import { Component, Input } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { checkForErrorsIn } from '../../../utils/validations';
import { EditTextDialogComponent } from '../../dialogs/edit-text-dialog/edit-text-dialog.component';

@Component({
  selector: 'app-text-popup',
  templateUrl: './text-popup.component.html',
  styleUrl: './text-popup.component.scss',
})
export class TextPopupComponent {
  constructor(public dialog: MatDialog) {}

  @Input() editMode: boolean;
  @Input() value: string;
  @Input() form: FormGroup;
  @Input() formName: string = '';
  checkForErrorsIn(formControl: AbstractControl, field: string): string {
    return checkForErrorsIn(formControl, field);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(EditTextDialogComponent, {
      data: {
        value: this.form.get(this.formName).value,
        title: `Edit ${this.formName.split('_')[0]}`,
        titleSubmit: 'save',
        titleCancel: 'cancel',
      },
      width: '25%',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.isSave) {
        this.form.patchValue({
          [this.formName]: result.val,
        });
      }
    });
  }
}
