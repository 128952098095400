<div
      class="container"
      [ngStyle]="{
        color: meta.style ? (checked ? '#4AA785' : '#FF6F6F') : 'black'
      }"
    >
      @if (meta.style) {
      <li></li>
      }
      <div class="title">
        {{ displayValue(value) || "" }}
      </div>
    </div>
    
    

    
    
    
