import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { checkForErrorsIn } from '../../../../utils/validations';
import { CustomBtnColor } from '../../../../interfaces/button';
import { USERS_ADMIN } from '../../../../types/constant';
import { SidebarService } from '../../../../services/sidebar.service';

@Component({
  selector: 'app-users-admin',
  templateUrl: './users-admin.component.html',
  styleUrl: './users-admin.component.scss',
})
export class UsersAdminComponent {
  @Input() productForm: FormGroup;
  @Input() header: string = '';
  @Output() handleStepper = new EventEmitter<string>();
  defaultSuffix: string = '@icl-group.com';
  email: string = ``;
  customBtnColor: CustomBtnColor = {
    color: 'black',
    bg: 'white',
    hover: 'gray',
    border: '1px solid #1c1c1c1e',
    disabled: '#D5D9DC',
  };
  id: string = USERS_ADMIN;

  constructor(public sidebarService: SidebarService) {}

  addEmail() {
    if (this.email) {
      const adminsArray = this.productForm.get('systemAccess').value;
      this.productForm.patchValue({
        systemAccess: [...adminsArray, `${this.email}${this.defaultSuffix}`],
      });
      this.email = '';
    }
  }
  removeEmail(email: string) {
    let adminsArray = this.productForm.get('systemAccess').value;
    const indexToRemove = adminsArray.indexOf(email);
    if (indexToRemove !== -1) {
      adminsArray.splice(indexToRemove, 1);
      this.productForm.patchValue({
        systemAccess: adminsArray,
      });
    }
  }
  checkForErrorsIn(formControl: AbstractControl, field: string): string {
    return checkForErrorsIn(formControl, field);
  }
  isFocus() {
    return this.id === this.sidebarService.getActiveStepper();
  }
  focus() {
    this.handleStepper.emit(this.id);
  }
}
