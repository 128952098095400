import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ProductsService } from '../../services/products.service';
import { SidebarService } from '../../services/sidebar.service';
import { TablesService } from '../../services/tables.service';
import { ACTIONS } from '../../types/constant';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss',
})
export class MenuComponent {
  constructor(
    private router: Router,
    private productService: ProductsService,
    public sidebarService: SidebarService,
    private tablesService: TablesService
  ) {}

  expendAction(id, children, name?) {
    const baseUrl = this.sidebarService.sidebarMenu()[0].url;
    const url = this.sidebarService
      .sidebarMenu()
      .find((item) => item.id === id).url;

    if (url === this.sidebarService.sidebarMenu()[0].url) {
      this.router.navigateByUrl(`/${baseUrl}`);
    } else {
      let productName;
      let urlPrefix = baseUrl;
      productName = this.productService.productName();
      if (productName && baseUrl === 'products') {
        urlPrefix = urlPrefix + '/' + productName;
      }
      if (url) {
        if (children.length > 0) {
          this.router.navigateByUrl(`${urlPrefix}/${url}/${children[0].name}`);
          this.sidebarService.drawTextMenuByName(name, children[0].name);
        } else {
          this.router.navigateByUrl(`${urlPrefix}/${url}`);
        }
      } else {
        this.router.navigateByUrl(urlPrefix);
      }
    }

    this.sidebarService.sidebarMenu.update((prev) =>
      prev.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            isExpanded: children.length > 0 ? !item.isExpanded : true,
          };
        }
        return {
          ...item,
          isExpanded: false,
        };
      })
    );
  }
  drawTextMenu(parent, child, action, baseUrl) {
    if (action === ACTIONS.route) {
      this.tablesService.routeTo(parent.url, child.name, baseUrl);
    } else if (action === ACTIONS.ref) {
      document
        .getElementById(child.name)
        .scrollIntoView({ behavior: 'smooth' });
      this.sidebarService.setActiveStepper(child.name);
    }
    this.sidebarService.drawTextMenu(parent.name, child.title);
  }
}
