import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomBtnColor } from '../../../interfaces/button';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
})
export class ButtonComponent {
  @Input() title: string = 'submit';
  @Input() color: string = 'primary';
  @Input() customBtnColor: CustomBtnColor;
  @Input() icon: string = '';
  @Input() type: string = '';
  @Input() disabled: boolean = false;
  @Input() height: string;
  @Input() width: string;
  @Input() paddingInline;
  @Output() action = new EventEmitter<any>();
  onClick() {
    this.action.emit();
  }
}
