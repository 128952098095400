<div [formGroup]="form">
  <div class="field">
    <div>{{ title }}</div>
    <mat-radio-group
      class="radio"
      aria-label="Select an option"
      formControlName="{{ formName }}"
    >
      @for (option of options; track $index) {
      <mat-radio-button [value]="option.value">{{
        option.title
      }}</mat-radio-button>
      }
    </mat-radio-group>
  </div>
</div>
