import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { map } from 'rxjs';
import { UserAuthService } from './services/userAuth.service';
import { UserAuth } from './interfaces/users';
import { SYSTEM_ROLES } from './types/constant';

export const maslSuperAdminGuard: CanActivateFn = (route, state) => {
  const msalService: MsalService = inject(MsalService);
  const userAuthService: UserAuthService = inject(UserAuthService);
  if (msalService.instance.getAllAccounts().length > 0) {
    return userAuthService.fetchGetUser().pipe(
      map((res: any) => {
        const user: UserAuth = userAuthService.getUser();
        if (user.roleId === SYSTEM_ROLES.superAdmin) {
          return true;
        }
        return false;
      })
    );
  }

  return false;
};

export const maslAdminGuard: CanActivateFn = (route, state) => {
  const msalService: MsalService = inject(MsalService);
  if (msalService.instance.getAllAccounts().length > 0) {
    return true;
  }
  return false;
};

export const maslRedirectGuard: CanActivateFn = (route, state) => {
  const router: Router = inject(Router);
  const userAuthService: UserAuthService = inject(UserAuthService);
  const msalService: MsalService = inject(MsalService);

  if (msalService.instance.getAllAccounts().length > 0) {
    return userAuthService.fetchGetUser().pipe(
      map((res: any) => {
        const user: UserAuth = userAuthService.getUser();
        if (user.roleId === SYSTEM_ROLES.superAdmin) {
          router.navigate(['/products']);
          return false;
        } else if (user.roleId === SYSTEM_ROLES.admin) {
          router.navigate(['/products_page']);
          return false;
        } else {
          // Handle other cases or redirect to a default route
          return true;
        }
      })
    );
  }
  return false;
};
