<div class="content" id="{{ id }}">
  <div class="header">{{ header }}</div>
  <div class="container" [formGroup]="productForm">
    <div class="inputs">
      <app-input
        [isFocus]="isFocus()"
        [form]="productForm"
        title="Product Name"
        formName="title"
        (handleStepper)="handleStepper.emit(id)"
      ></app-input>
      <app-input
        [form]="productForm"
        title="Product ID"
        formName="name"
        (handleStepper)="handleStepper.emit(id)"
      ></app-input>
    </div>
    <div class="field">
      <app-textarea
        title="Product Description"
        formName="description"
        [form]="productForm"
        (handleStepper)="handleStepper.emit(id)"
      ></app-textarea>
      <div class="footer-container">
        <app-button-toggle
          title="Status"
          formName="status"
          [form]="productForm"
          [options]="options"
          [isTitleVal]="true"
        ></app-button-toggle>
        @if(productForm.value.id){
        <app-button
          title="Generate Api Key"
          (action)="generateApi()"
        ></app-button>
        }
      </div>
    </div>
  </div>
</div>
