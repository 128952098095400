<div class="container">
  <form
    [formGroup]="form"
    (ngSubmit)="handleFormClick(isAddRow ? 'SAVE' : 'EDIT')"
  >
    <div class="header-table">
      <div class="header">
        <div class="table-name">{{ displayHeader(header) }}</div>
        <div class="sub-header">{{ subHeader }}</div>
      </div>
      @if (isActionTable) {
      <div class="action-header">
        <mat-form-field appearance="outline">
          <input
            (keyup)="applyFilter($event)"
            #input
            class="placeholder"
            matInput
            placeholder="Search"
          />
          <mat-icon matTextPrefix svgIcon="search" />
        </mat-form-field>
        <app-button
          title="{{ buttonDetails.title }}"
          icon="{{ buttonDetails.icon }}"
          [disabled]="isDisabledButton"
          (action)="action.emit()"
        ></app-button>
      </div>
      }
    </div>
    @if (dataSource) {

    <div class="example-container">
      <table
        mat-table
        [dataSource]="dataSource"
        class="mat-elevation-z8"
        tabindex="0"
        class="custom-table"
      >
        @for(col of columns;track $index){

        <ng-container
          [matColumnDef]="col.name"
          stickyEnd="{{ col.type === 'action' }}"
        >
          <th mat-header-cell *matHeaderCellDef class="title">
            {{ col.type != "action" ? col.title : "" }}
          </th>

          @if (col.type !="action") {
          <td mat-cell *matCellDef="let element">
            <app-cells
              [editMode]="element['editMode']"
              [form]="form"
              [type]="col.type"
              [formName]="col.name"
              [meta]="{
                options: col.options,
                style: col.style,
                baseUrl: getPath(col.baseUrl ,col.name,element['id']),
              }"
              [value]="element[col.name]"
            >
            </app-cells>
          </td>

          } @if (col.type ==="action") {
          <td class="action-container" mat-cell *matCellDef="let element">
            @if (element['editMode']) {
            <div class="buttons-footer">
              <app-button
                title="Save"
                color="accent"
                type="submit"
                [disabled]="!form.valid"
                height="27px"
                paddingInline="0.7vw"
              ></app-button>
              <app-button
                [customBtnColor]="customBtnColor"
                height="27px"
                paddingInline="0.7vw"
                title="Cancel"
                (action)="
                  onRowAction(element, isAddRow ? 'CANCEL_ADD' : 'CANCEL_EDIT')
                "
              ></app-button>
            </div>

            }@else if(isHoverAction) {

            <div class="button-flex">
              @for (action of col.quick_actions; track $index) {
              <mat-icon
                [ngClass]="{ isHover: element['id'] === hoveredRowById }"
                class="action icon-button"
                svgIcon="{{ action.icon }}"
                (click)="onClickIcon($event, element, action.name, $index)"
              >
              </mat-icon>
              }
            </div>
            }
          </td>
          }
        </ng-container>

        }
        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
        <tr
          *matRowDef="let row; columns: displayColumns"
          (mouseover)="isHoverAction && onMouseOver(row)"
          (mouseout)="isHoverAction && onMouseOut()"
          (click)="onRowClick(row)"
          mat-row
          class="row"
        ></tr>
      </table>
    </div>
    }
    <mat-paginator
      class="pagination"
      [length]="isPagination && totalCountLocal"
      [pageSize]="pagination"
      hidePageSize="true"
      (page)="handleChangePage.emit($event)"
    >
    </mat-paginator>
  </form>
</div>
